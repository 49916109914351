import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lib-amdocs-popup',
  templateUrl: './amdocs-popup.component.html',
  styleUrls: ['./amdocs-popup.component.scss']
})
export class AmdocsPopupComponent implements OnInit, AfterViewInit {

  @Input() showCloseButton = false;
  @Input() customClass: string;
  @Output() closeCallback: EventEmitter<any> = new EventEmitter();
  @ViewChild(ModalDirective) modal: ModalDirective;
  public config: ModalOptions = {};

  constructor() { }

  ngOnInit(): void {
    this.config = Object.assign({ ignoreBackdropClick: true }, this.config);
  }

  ngAfterViewInit(): void {
    this.modal.show();
  }

  close(): void {
    if (this.modal) {
      this.modal.config = this.config;
      this.modal.hide();
      this.closeCallback.emit();
    }
  }

  /**
   * on popup hide
   */
  onHide(): void {
    if (this.closeCallback.observers.length) {
      this.closeCallback.emit();
    }
  }

}
