import {AfterContentInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'lib-amdocs-table-header',
    templateUrl: './amdocs-table-header.component.html',
    styleUrls: ['./amdocs-table-header.component.scss']
})
export class AmdocsTableHeaderComponent implements AfterContentInit {
    @Input() data: any = {};
    @Output() headerClickCallback: EventEmitter<any> = new EventEmitter();

    @ViewChild('tableHeader') tableHeader;

    constructor() {

    }
    ngAfterContentInit() {
    }

    headerClick($event) {
        if (this.headerClickCallback.observers.length) {
            $event.stopPropagation();
            this.headerClickCallback.emit(this.data);
        }
    }

}
