<div class="amdocs-accordion-wrapper" [class.with-icon]="withCloseOpenIcon">
    <accordion [isAnimated]="isAnimated" [closeOthers]="openOnlyOne">
        <accordion-group [isOpen]="panelIndex == openPanelNumber"
                         *ngFor="let accordionTemplate of accordionTemplates; let panelIndex = index">

            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                <ng-container [ngTemplateOutlet]="findHeaderTemplate(panelIndex)"></ng-container>
            </button>
            <ng-container [ngTemplateOutlet]="accordionTemplate"></ng-container>
        </accordion-group>
    </accordion>
</div>
