import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-amdocs-user-notify-alert',
  templateUrl: './amdocs-user-notify-alert.component.html',
  styleUrls: ['./amdocs-user-notify-alert.component.scss']
})
export class AmdocsUserNotifyAlertComponent {

  @Input() duration = 3000;
  public saveMsgText: string;
  public show = false;
  public displaySaveAlert = false;

  constructor() { }


  public showMessage(text: string): void {
    this.saveMsgText = text;
    this.displaySaveAlert = true;
    setTimeout(() => {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        setTimeout(() => {
          this.displaySaveAlert = false;
        }, 1000);
      }, this.duration);
    }, 10);
  }

}
