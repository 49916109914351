import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CopyPartOfPlanService {

  constructor(
    private apiService: ApiService,
  ) { }


  getLearningTemplates(params): Observable<any> {
    return this.apiService.getLearningTemplates(params).pipe(
      map(response => response.templatesLibrary));
  }
}
