import { Component, OnInit, Input } from '@angular/core';
import { AmdocsControlAbstract } from '../amdocs-control-abstract';

@Component({
  selector: 'lib-amdocs-checkbox',
  templateUrl: './amdocs-checkbox.component.html',
  styleUrls: ['./amdocs-checkbox.component.scss']
})
export class AmdocsCheckboxComponent extends AmdocsControlAbstract implements OnInit {
  @Input() disabled: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
  }

  public isDisabled(): boolean {
    const controlDisabled = this.control ? this.control.disabled : null;
    return controlDisabled || this.disabled ? true : null;
  }
}
