import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-amdocs-autocomplete',
  templateUrl: './amdocs-autocomplete.component.html',
  styleUrls: ['./amdocs-autocomplete.component.scss']
})
export class AmdocsAutocompleteComponent implements OnChanges {

  @Input() label: string;
  @Input() items: IAutocompleteItem[];
  @Input() selectedItemCode: any;
  @Input() placeholder = 'select';
  @Input() virtualScroll = true;
  @Input() readOnly = false;
  @Input() highlightResults = true;
  @Input() clearable = true;
  @Input() hideLabel: boolean = false;
  @Input() hasHierarchy = true;
  @Input() customClass: string;
  @Output() itemChangedCallback: EventEmitter<IAutocompleteItem> = new EventEmitter();
  @Output() selectedItemCleared: EventEmitter<any> = new EventEmitter();
  @Output() selectOpen: EventEmitter<any> = new EventEmitter();
  @Input() errorsMessage: string;
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() noDropdown = false;
  @Input() isNotOpen = false;
  @Input() isRounded = false;
  @Input() isMultiple = false;
  @Input() selectIfOneItem = true;
  @Input() hasTooltip = false;
  @Input() tooltipContent: string;
  @Input() hasSearchBox = false;
  searchPlanFC = new UntypedFormControl();
  @ContentChild('autocompleteOptionTemplate') autocompleteOptionTemplate: TemplateRef<ElementRef>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      if (this.items?.length === 1 && this.selectIfOneItem) {
        // disabled because MHL-3079;
        // this.selectedItemCode = this.items[0].code;
        // this.itemChangedEvent();
      } else {
        if (this.selectedItemCode?.length) {
          const tmpItems: any = changes.items.currentValue;
          if (tmpItems && (tmpItems as any[]).find) {
            if (!(tmpItems as any[]).find(i => i.code === this.selectedItemCode)) {
              this.itemChangedEvent();
            }
          }
        }
      }
    }
  }

  onSelectOpen(): void {
    if(this.noDropdown) {
      this.isNotOpen = true
      this.selectOpen.emit();
    }

    setTimeout(() => {
      this.isNotOpen = null
    }, 0)
  }

  onSelectClose(): void {
    this.searchPlanFC.reset();
  }

  onSearch(term: string, item) {
    const formattedTerm = term.toLowerCase();
    const formattedName = item.name.replace(',', '').toLowerCase();
    return formattedName.includes(formattedTerm);
  }

  public itemChangedEvent(): void {
    let selectedItem;
    if (this.isMultiple) {
      selectedItem = this.selectedItemCode?.every(elem => this.items.find(i => i.code === elem)) ? this.selectedItemCode : [];
    } else {
      selectedItem = this.items.find(i => i.code === this.selectedItemCode);
    }

    this.itemChangedCallback.emit(selectedItem);
  }
}

export class IAutocompleteItem {
  code: any;
  name: any;
  isGroup?: boolean;
  disabled?: boolean;
  selected?: boolean;
}
