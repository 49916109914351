<div
  class="autocomplete-wrapper {{ customClass }}"
  [ngClass]="{ 'has-hierarchy': hasHierarchy, rounded: isRounded }"
>
  <div
    *ngIf="!hideLabel"
    class="custom-input-label text-color-secondary autocomplete-label d-flex"
  >
    {{ label }}
    <span *ngIf="isRequired" class="required mr-1 align-middle">&nbsp;*</span>
    <ng-container *ngIf="hasTooltip && tooltipContent">
      <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
        <img popoverOpener class="info-icon" src="assets/icons/info.svg" />
        <div popoverContent>{{ tooltipContent }}</div>
      </lib-amdocs-popover>
    </ng-container>
  </div>
  <ng-select
    #select
    bindLabel="name"
    bindValue="code"
    class="select-wrapper"
    [items]="items"
    [multiple]="isMultiple"
    [readonly]="readOnly"
    [(ngModel)]="selectedItemCode"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [class.invalid]="errorsMessage"
    [closeOnSelect]="!isMultiple"
    (clear)="selectedItemCleared.emit()"
    (open)="onSelectOpen()"
    (close)="onSelectClose()"
    [disabled]="disabled"
    [searchFn]="onSearch"
    [isOpen]="isNotOpen ? false : null"
    (change)="itemChangedEvent()"
    [virtualScroll]="virtualScroll"
  >
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-search="searchTerm"
      let-index="index"
    >
      <div
        [ngClass]="{
          'is-group': item.isGroup,
          'highlight-result': highlightResults
        }"
        class="item"
      >
        <div
          *ngIf="!autocompleteOptionTemplate && isMultiple"
          class="text-small-normal"
          [ngClass]="{
            'd-flex align-items-center justify-content-start': isMultiple
          }"
        >
          <ng-container>
            <input
              [ngModel]="item$.selected"
              type="checkbox"
              class="custom-control-input"
              [id]="'drop-down-multiple-item-' + index"
            />
            <span
              class="
                custom-control-indicator
                align-items-center
                d-flex
                justify-content-center
              "
            ></span>
          </ng-container>
          {{ item.name }}
        </div>

        <div
          *ngIf="!autocompleteOptionTemplate && !isMultiple"
          class="text-small-normal"
          [ngOptionHighlight]="search"
          [ngClass]="{
            'd-flex align-items-center justify-content-start': isMultiple
          }"
        >
          {{ item.name }}
        </div>

        <ng-container *ngIf="autocompleteOptionTemplate">
          <ng-template
            ngFor
            let-item
            [ngForOf]="[item]"
            [ngForTemplate]="autocompleteOptionTemplate"
          ></ng-template>
        </ng-container>
      </div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div
        class="ng-value"
        *ngFor="let item of items | slice: 0:1"
        [ngClass]="{ 'multi-label': items.length > 1 }"
      >
        <span *ngIf="items.length <= 1" class="multi-count-content">
          <span
            class="ng-value-icon left"
            (click)="clear(item)"
            aria-hidden="true"
            >×</span
          >
          <span class="ng-value-label">{{ item.name }}</span>
        </span>
        <span *ngIf="items.length > 1" class="ng-value-label options"
          >{{ items.length }} {{ "selected" }}</span
        >
      </div>
    </ng-template>

    <ng-template ng-header-tmp *ngIf="hasSearchBox">
      <div class="search-input-wrapper">
        <lib-amdocs-input-field
          [elementId]="'searchPlan'"
          [placeHolder]="'homePage.search' | amdocsTranslate"
          [control]="searchPlanFC"
          [hideLabel]="true"
          [errorsMessages]="{
            required: 'errorsMessages.required' | amdocsTranslate
          }"
          (input)="select.filter($event.target.value)"
        >
        </lib-amdocs-input-field>
        <img
          class="find-an-item-icon"
          lib-amdocs-image-version
          src="/assets/icons/find-an-item.svg"
        />
      </div>
    </ng-template>
  </ng-select>
  <div *ngIf="errorsMessage" class="custom-input-field-error">
    <img src="/assets/icons/error.svg" />
    <span class="text-small-normal">{{ errorsMessage }} </span>
  </div>
</div>
