import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IAmdocsUser, LANGUAGE } from '../amdocs-models';
import { AmdocsAppsyncClientService } from '../amdocs-appsync-client/amdocs-appsync-client.service';

@Injectable({
  providedIn: 'root'
})
export class AmdocsUserService {

  public user: IAmdocsUser;

  constructor(private appSyncClient: AmdocsAppsyncClientService) {

  }



  init(initQuery: string = null, params: any = null): Observable<IAmdocsUser> {
    if (!initQuery) {
      return of(this.user);
    }
    return this.appSyncClient.call(initQuery, this.appSyncClient.getConfig().initAppOperationName, params).pipe(
      mergeMap((response) => {
        this.setUserData(response);
        return of(this.user);
      })
    );
  }

  public isLang(lang: LANGUAGE): boolean {
    return this.user.lang === lang;
  }

  private setUserData(initResponse): void {
    this.user = {
      appSettings: initResponse.applicationInit.appSettings,
      userProfile: initResponse.applicationInit.userProfile,
      userSettings: initResponse.applicationInit.userSettings,
      employeeSearchHistory: initResponse.applicationInit.employeeSearchHistory,
      applicationShortcuts: initResponse.applicationInit.applicationShortcuts,
      applicationCards: initResponse.applicationInit.applicationCards,
      isPOCUser: initResponse.applicationInit.isPOCUser
    };
  }

  getUserShortName(userName: string = null): string {
    const name = userName || this.user?.userProfile?.name;
    if (!name) {
      return '';
    }
    const firstLetters = name.split(' ').map(value => value[0]);
    return firstLetters ? `${firstLetters[0]}${firstLetters[1] || ''}` : '';
  }
}

