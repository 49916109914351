import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { AmdocsControlAbstract } from "../amdocs-control-abstract";

@Component({
  selector: "lib-amdocs-dropdown",
  templateUrl: "./amdocs-dropdown.component.html",
  styleUrls: ["./amdocs-dropdown.component.scss"],
})
export class AmdocsDropdownComponent
  extends AmdocsControlAbstract
  implements OnInit
{
  @Input() fullWith: boolean = true;
  @Input() items: DropdownItem[] = [];
  @Input() autoClose: boolean = true;
  @Input() placeHolder: string = "select";
  @Input() selectIfOneItem = false;
  @Input() newDesign = false;
  @Input() isRequired = false;
  @Input() isDropup = false;
  @Input() clearable = false;

  @ContentChild("dropDownItem") dropDownItem: TemplateRef<ElementRef>;
  @ContentChild("dropDownSelectedItem")
  dropDownSelectedItem: TemplateRef<ElementRef>;

  ngOnInit(): void {
    super.ngOnInit();

    if (this.items?.length === 1 && this.selectIfOneItem) {
      this.control.setValue(this.items[0].key);
      this.onChange();
    }
  }

  get selectedValue(): string {
    if (this.control && this.control?.value) {
      const itemFound = this.items.find((i) => i.key === this.control.value);
      if (itemFound) {
        return itemFound.value;
      } else {
        for (let i = 0; i <= this.items.length; i++) {
          if (this.items[i]?.subDropDownItems) {
            return this.items[i].subDropDownItems.find(
              (subItem) => subItem.key === this.control.value
            ).value;
          }
        }
      }
    }
    return this.placeHolder;
  }

  public itemClick(event, item: DropdownItem): void {
    if (item.disabled) {
      event.stopPropagation();
      return;
    }
    this.control?.setValue(item.key);
    this.onChange();
  }

  public clearSelectedValue() {
    this.control?.reset();
  }
}

export class DropdownItem {
  constructor(
    public key: any,
    public value: string,
    public subDropDownItems?: DropdownItem[],
    public disabled: boolean = false
  ) {}
}
