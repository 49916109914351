import { Component } from '@angular/core';
import { AmdocsEventBusService } from '../../../amdocs-utils/amdocs-event-bus/amdocs-event-bus.service';
import { AMDOCS_CONSTANTS } from '../../../amdocs-utils/amdocs-constants';

@Component({
  selector: 'lib-amdocs-version-alert',
  templateUrl: './amdocs-version-update.component.html',
  styleUrls: ['./amdocs-version-update.component.scss']
})
export class AmdocsVersionUpdateComponent {

  public showVersionUpdate = false;

  constructor(private eventBus: AmdocsEventBusService) {
    this.eventBus.on(AMDOCS_CONSTANTS.SHOW_NEW_VERSION_ALERT_EVENT, () => {
      this.showVersionUpdate = true;
    }, true);
  }

  public reload(): void {
    window.location.reload();
  }
}
