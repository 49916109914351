import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'lib-amdocs-mimic',
  templateUrl: './amdocs-mimic.component.html',
  styleUrls: ['./amdocs-mimic.component.scss']
})
export class AmdocsMimicComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  public fileNumber: string = '';

  ngOnInit(): void {
  }

  toggleMimic() {
    const m = localStorage.getItem('mimic');
    if (!m) {
      localStorage.setItem('mimic', '1');
      window.location = window.location;
    } else {
      localStorage.removeItem('mimic');
      window.location = window.location;
    }
  }

  switchFile(): void {
    this.httpClient.get(`https://mimics.stage-rc.com/amdocs/${this.fileNumber}.json`).subscribe(
      (response) => {
        localStorage.setItem('mimic', '1');
        localStorage.setItem('_mimic_', JSON.stringify(response));
        setTimeout(() => {
          window.location.reload();
        }, 200);
      },
      (error) => {
        alert(`${error.status} - ${error.message}`);
      }
    );
  }

  go(): void {
    window.location.href = this.fileNumber;
  }

}
