import {AfterContentInit, Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'lib-amdocs-table-cell',
    templateUrl: './amdocs-table-cell.component.html',
    styleUrls: ['./amdocs-table-cell.component.scss']
})
export class AmdocsTableCellComponent{
    @Input() col: any = {};

    @ViewChild('tableCell') tableCell;

  colClicked(event) {
    event.rcTableCol = this.col;
  }
}
