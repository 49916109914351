<div class="custom-input-field">
  <label *ngIf="!hideLabel" class="custom-input-label">
    {{ label }}
    <span *ngIf="isRequired" class="required mr-1 align-middle">*</span>
    <ng-container *ngIf="hasTooltip && tooltipContent">
      <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
        <img popoverOpener class="info-icon" src="assets/icons/info.svg" />
        <div popoverContent>{{ tooltipContent }}</div>
      </lib-amdocs-popover>
    </ng-container>
  </label>
  <input
    class="custom-input-field-control full-width"
    [ngClass]="{ counter: inputMaxLength || suffixValue }"
    #inputRef
    [id]="elementId"
    [placeholder]="placeHolder"
    [disabled]="isDisabled()"
    [class.active]="control.value"
    [class.invalid]="isInvalid()"
    [class.text-center]="textCenter"
    [class.readonly]="readOnly"
    [type]="inputType"
    [(ngModel)]="value"
    [maxlength]="inputMaxLength"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    attr.inputmode="{{ inputMode }}"
    (paste)="handlePaste($event)"
  />
  <span *ngIf="inputMaxLength && showMaxLength" class="remaining"
    >{{ value ? value.length : 0 }}/{{ inputMaxLength }}</span
  >
  <span *ngIf="suffixValue" class="remaining suffix">{{ suffixValue }}</span>
  <div
    *ngIf="isInvalid() && showValidationErrorText"
    class="custom-input-field-error"
  >
    <img src="/assets/icons/error.svg" />
    <span class="text-small-normal">{{ getErrorMsg() }} </span>
  </div>
</div>
