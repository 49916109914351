import { Component, Input, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

/**
 * Rc popover component - render content and open another content in popover on click.
 * Currently uses ngx-bootstrap popover
 *
 * @example
 * <lib-rc-popover [trigger]="click" [placement]="bottom">
 *  <div popoverOpener>I am the opener</div>
 *  <div popoverContent>Some content, can be another component</div>
 * </lib-rc-popover>
 * Example of usage:
 * <example-url>http://documentation.stage-rc.com/demo/?showAll=false&componentName=RcPopoverComponent</example-url>
 */
@Component({
  selector: 'lib-amdocs-popover',
  templateUrl: './amdocs-popover.component.html',
  styleUrls: ['./amdocs-popover.component.scss']
})
export class AmdocsPopoverComponent {

  /**
   * Popover trigger
   */
  @Input() trigger: string = 'click';

  /**
   * Set if click out side will close the popover
   */
  @Input() outsideClick: boolean = true;

  /**
   * Set popover location (top, bottom, left, right)
   */
  @Input() placement: string = 'bottom';
  @Input() customClass: string = '';

  @ViewChild(PopoverDirective) pop: PopoverDirective;

  constructor() {

  }

  /**
   * check if popup is open
   * return boolean
   */
  get isOpen(): boolean {
    return this.pop && this.pop.isOpen;
  }

  /**
   *hide popup
   * return void
   */
  public hide(): void {
    this.pop.hide();
  }

  /**
   * show popup
   * return void
   */
  public open(): void {
    this.pop.show();
  }
}
