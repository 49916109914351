import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsDatepickerInlineDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AmdocsTimeService } from '../../../amdocs-utils/amdocs-time/amdocs-time.service';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Moment } from 'moment';

@Component({
  selector: 'lib-amdocs-datepicker',
  templateUrl: './amdocs-datepicker.component.html',
  styleUrls: ['./amdocs-datepicker.component.scss']
})
export class AmdocsDatepickerComponent implements OnInit, AfterViewInit {

  @ViewChild('datepickerComp') datepickerComp: BsDatepickerDirective;
  @Input() config: BsDatepickerConfig;
  @Input() initialValue: string; // YYYY-MM-DD
  @Input() inlineView: boolean = false;
  @Input() withIcon: boolean = false;
  @Input() placeholder: string = 'Select Date';
  @Input('minDate') set minDate(value: string) { // YYYY-MM-DD
    this.minimumDate = new Date(value);
  }
  @Input('maxDate') set maxDate(value: string) { // YYYY-MM-DD
    this.maximumDate = new Date(value);
  }
  @Input() disabled = false;
  @Output() dateChange: EventEmitter<string> = new EventEmitter();
  public minimumDate: Date;
  public maximumDate: Date;
  public containerId = 'datepicker-container-' + Math.floor(Math.random() * 100000);
  public bsInlineValue;
  public show = false;
  public currentMonthView: Moment;
  public changeCnt = 0;
  public bsConfig: any = {
    showWeekNumbers: false,
    customTodayClass: 'today'
  };

  constructor(private timeService: AmdocsTimeService, private localeService: BsLocaleService) {
    this.currentMonthView = this.timeService.moment(this.initialValue);
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
  }

  ngOnInit(): void {
    this.config = Object.assign({}, this.bsConfig, this.config);
    if (this.initialValue) {
      this.bsInlineValue = this.timeService.moment(this.initialValue).toDate();
    }
  }

  onDateChange(): void {
    setTimeout(() => {
      this.dateChange.emit(this.timeService.moment(this.bsInlineValue).format('YYYY-MM-DD'));
    });
  }

  next(): void {
    this.currentMonthView.add('1', 'M');
    if (this.maximumDate && this.maximumDate < this.currentMonthView.toDate()) {
      this.currentMonthView.subtract('1', 'M');
    } else {
      (document.querySelector('.bs-datepicker-head button.next') as any).click();
    }
  }

  prev(): void {
    this.currentMonthView.subtract('1', 'M');
    if (this.minimumDate && this.minimumDate > this.currentMonthView.toDate()) {
      this.currentMonthView.add('1', 'M');
    } else {
      (document.querySelector('.bs-datepicker-head button.previous') as any).click();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.show = true;
    });
  }
}
