<div *ngIf="showProgressiveWebAppNotification" class="ProgressiveWebApp-notification"
     lib-amdocs-click-outside (clickOutside)="closeProgressiveWebAppNotification()">
  <div class="box">
    <button class=" btn close-icon" (click)="closeButtonClicked()"><img image-src src="/assets/icons/close-purple.svg"/></button>
    <div class="d-flex">
      <div class="add-icon">
        <img image-src src="/assets/icons/add.svg"/>
      </div>
      <div class="content">
        <div><span>{{text1}}</span></div>
        <div>
          <span>tap</span>
          <span><img alt="" class="export-icon" src="/assets/icons/send-activity.svg" /></span>
          <span>{{text2}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
