<div class="amdocs-switcher-wrapper">
  <div class="amdocs-switcher-control-wrapper d-flex">
    <div class="amdocs-switcher-control">
      <input
        class="switcher-checkbox"
        type="checkbox"
        id="{{elementId}}"
        attr.aria-label="{{ariaLabel}}"
        attr.aria-labeledby="{{ariaLabeledBy}}"
        attr.aria-describedby="{{getAriaDescribedBy()}}"
        [formControl]="control"
        (change)="onChange()"
        [disabled]="isDisabled()"
      >
      <label class="switcher-indicator" [for]="elementId"></label>
    </div>
    <div *ngIf="!hideLabel" class="switcher-label text-color-primary d-flex">
      <span *ngIf="labelText">{{labelText}}</span>
      <img *ngIf="isRequired" class="autocomplete-img" src="assets/icons/asterisk.svg">
    </div>
<!--    <ng-container>-->
<!--      <ng-content></ng-content>-->
<!--    </ng-container>-->
  </div>
</div>
