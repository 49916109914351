<div
  class="amdocs-dropdown btn-group {{ customClass }}"
  dropdown
  [dropup]="isDropup"
  [autoClose]="autoClose"
  [isDisabled]="isDisabled()"
>
  <label class="custom-input-label">
    {{ label }}
    <img
      *ngIf="isRequired"
      class="amdocs-dropdown-img"
      src="assets/icons/asterisk.svg"
    />
  </label>
  <button
    dropdownToggle
    type="button"
    class="
      custom-input-field-control
      dropdown-toggle
      d-flex
      align-items-center
      justify-content-between
    "
    aria-controls="dropdown-basic"
    [class.active]="control?.value"
    [class.invalid]="isInvalid()"
    [class.readonly]="readOnly"
    [attr.disabled]="isDisabled()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [id]="elementId"
  >
    <span class="selected-value" [ngClass]="{ placeholder: !control.value }">{{
      selectedValue
    }}</span>
    <img *ngIf="!newDesign" class="caret" src="/assets/icons/arrow-down.svg" />
    <img
      *ngIf="newDesign"
      class="caret"
      src="/assets/icons/arrow-down-new.svg"
    />
  </button>
  <span
    *ngIf="clearable && control.value"
    (click)="clearSelectedValue()"
    class="clear"
  >
    <img *ngIf="newDesign" class="caret" src="/assets/icons/close-black.svg" />
  </span>
  <div
    *ngIf="isInvalid() && showValidationErrorText"
    class="custom-input-field-error"
  >
    <img src="/assets/icons/error.svg" />
    <span class="text-small-normal">{{ getErrorMsg() }}</span>
  </div>
  <ul
    *dropdownMenu
    class="dropdown-menu"
    [ngClass]="{ 'full-width': fullWith }"
    role="menu"
    aria-labelledby="button-basic"
  >
    <ng-container *ngFor="let item of items; let i = index">
      <li class="menu-item" role="menuitem" (click)="itemClick($event, item)">
        <a
          class="dropdown-item"
          [ngClass]="{
            disabled: item.disabled,
            selected: item.key == control.value
          }"
          href="javascript:void(0)"
        >
          <ng-container *ngIf="dropDownItem">
            <ng-template
              ngFor
              let-item
              [ngForOf]="[item]"
              [ngForTemplate]="dropDownItem"
            ></ng-template>
          </ng-container>
          <ng-container *ngIf="!dropDownItem">
            {{ item.value }}
          </ng-container>
        </a>
      </li>
      <li
        *ngFor="let item of item.subDropDownItems; let i = index"
        class="menu-item"
        role="menuitem"
        (click)="itemClick($event, item)"
      >
        <a
          class="dropdown-item dropdown-sub-item"
          [ngClass]="{
            disabled: item.disabled,
            selected: item.key == control.value
          }"
          href="javascript:void(0)"
        >
          <ng-container *ngIf="dropDownItem">
            <ng-template
              ngFor
              let-item
              [ngForOf]="[item]"
              [ngForTemplate]="dropDownItem"
            ></ng-template>
          </ng-container>
          <ng-container *ngIf="!dropDownItem">
            {{ item.value }}
          </ng-container>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
