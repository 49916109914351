import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../../core/utils.service';
import { ApiService } from '../../../core/api.service';
import { AmdocsEventBusService } from 'projects/amdocs-core-package/src/public-api';
import { CONSTANTS } from '../../../constants';
import { PreviewPlanService } from '../../services/preview-plan.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-copy-part-of-plan-planList',
  templateUrl: './copy-part-of-plan-planList.component.html',
  styleUrls: ['./copy-part-of-plan-planList.component.scss']
})
export class CopyPartPlanListComponent implements OnInit {

  @Input() learningPlans;

  @Output() selectedPartsCallBackToHigherArray = new EventEmitter;
  @Output() callBackIsCollapsed = new EventEmitter;


  public isLoading = false;
  public isCollapsed = true;

  public learningTypesList = [];
  public parts = [];
  public planId: string;
  public planInfo;
  public partTracking = (index, item) => item.order;


  private unsubscribe$ = new Subject();
  public get showPlanDetailsTooltip() {
    const planInfo = this.learningPlans;
    if (!planInfo) {
      return false;
    }

    return (
      planInfo.description ||
      planInfo.expectedTime ||
      planInfo.seniority ||
      planInfo.role ||
      planInfo.locationLabel ||
      planInfo.account ||
      planInfo.org2 ||
      planInfo.org3 ||
      planInfo.org4 ||
      planInfo.org5 ||
      planInfo.customProductIDs?.length ||
      planInfo.productIDs?.length
    );
  }

  constructor(
    private utils: UtilsService,
    private apiService: ApiService,
    private eventBus: AmdocsEventBusService,
    private previewPlanService: PreviewPlanService,
  ){

  }

  ngOnInit(){
    this.planInfo = this.utils.getValuesOfItem(this.learningPlans);
    const partChecked = this.learningPlans?.parts?.some((item) => item.isChecked);
    if(partChecked){
      this.isCollapsed = false;
    }
  }

  rowIsCollapsed(planId){
    this.isCollapsed = !this.isCollapsed;
    if(!this.isCollapsed && !this.learningPlans.parts){
      this.getLearningTemplateByCode(planId);
    }
  }
  showPlanDetails(item) {
    if (this.utils.dropdownData.org2Units && item.org2ID) {
      const selectedOrg2Item = this.utils.dropdownData.org2Units.find(
        (org2Item) => org2Item.code === item.org2ID
      );
      if (
        selectedOrg2Item &&
        selectedOrg2Item.subUnits &&
        selectedOrg2Item.subUnits.length
      ) {
        this.utils.dropdownData.org3Units = selectedOrg2Item.subUnits.map(
          (o) => ({ code: o.code, name: o.name })
        );
        item.org3 = this.utils.getStringValueOfSelect(
          item.org3ID,
          this.utils.dropdownData.org3Units
        );
      }
    }

    if (this.utils.dropdownData.org3Units && item.org3ID && item.org4ID) {
      this.apiService.getOrg3Units(item.org3ID).subscribe(
        (org3Units) => {
          if (
            org3Units.length &&
            org3Units[0].subUnits &&
            org3Units[0].subUnits.length
          ) {
            this.utils.dropdownData.org4Units = org3Units[0].subUnits.map(
              (o) => ({ code: o.code, name: o.name })
            );
            item.org4 = this.utils.getStringValueOfSelect(
              item.org4ID,
              this.utils.dropdownData.org4Units
            );
          }
          if (this.utils.dropdownData.org4Units && item.org4ID) {
            this.setOrg5Value(item);
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
    }
  }

  setOrg5Value(item) {
    this.apiService.getOrg4Units(item.org4ID).subscribe(
      (org4Units) => {
        if (
          org4Units.length &&
          org4Units[0].subUnits &&
          org4Units[0].subUnits.length
        ) {
          this.utils.dropdownData.org5Units = org4Units[0].subUnits.map(
            (o) => ({ code: o.code, name: o.name })
          );
          item.org5 = this.utils.getStringValueOfSelect(
            item.org5ID,
            this.utils.dropdownData.org5Units
          );
        }
      },
      (error) => {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
          CONSTANTS.Toaster.ERROR,
          error.message,
        ]);
      }
    );
  }

  getLearningTemplateByCode(planCode): void {
    this.isLoading = true;
    this.previewPlanService.getLearningTemplate(planCode, true).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      
      this.learningTypesList = Object.assign({}, ...this.utils.dropdownData.learningTypes.map((l) => ({ [l.code]: l.name })));
      this.learningPlans.parts = res.draft?.templatePlanContent ?? res.latest.templatePlanContent;
    
      this.planId = res.code;
      this.isLoading = false;
      this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
    }, (error) => {
      this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [CONSTANTS.Toaster.ERROR, error.message]);
    });
  }

  selectedPartsCallBack(){
    const currentPlanWithSelectedParts = this.learningPlans;
    this.selectedPartsCallBackToHigherArray.emit({currentPlanWithSelectedParts})
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
