import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-amdocs-popup-message',
  templateUrl: './amdocs-popup-message.component.html',
  styleUrls: ['./amdocs-popup-message.component.scss']
})
export class AmdocsPopupMessageComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() reloadButtonText: string = 'RELOAD';
  @Input() homeButtonText: string = 'Back home';
  @Input() showHomeButton: boolean = true;
  @Input() homeNavigation: string;
  @Output() actionClicked: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public reload() {
    this.actionClicked.emit();
    window.location.reload();
  }

  public home() {
    this.actionClicked.emit();
    if (this.homeNavigation) {
      this.router.navigate([this.homeNavigation]);
    } else {
      this.router.navigate(['']);
    }
  }
}
