<div class="amdocs-date-picker-wrapper" id="{{containerId}}">
  <bs-datepicker-inline
    *ngIf="inlineView"
    #datepickerComp
    (bsValueChange)="onDateChange()"
    [bsConfig]="config"
    [(bsValue)]="bsInlineValue"
    [minDate]="minimumDate"
    [maxDate]="maximumDate"
  ></bs-datepicker-inline>
  <input type="text" *ngIf="show && !inlineView"
         [id]="'datepicker'+containerId"
         [container]="'#'+containerId"
         [placeholder]="placeholder"
         class="form-control"
         (bsValueChange)="onDateChange()"
         [bsConfig]="config"
         [(bsValue)]="bsInlineValue"
         [minDate]="minimumDate"
         [maxDate]="maximumDate"
         [disabled]="disabled"
         bsDatepicker />
  <label class="add-on" [for]="'datepicker'+containerId" *ngIf="withIcon">
    <i class="calendar-icon"></i>
  </label>
</div>
