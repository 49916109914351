<div class="modal-header">
  <span class="label-h1">{{ title }}</span>
  <div
    class="close-popup-button"
    role="button"
    (click)="bsModalRef.hide()"
  >
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>
<div class="modal-body">
  <div class="content">
    <h3>{{header}}</h3>
    <img
      *ngIf="icon"
      lib-amdocs-image-version
      class="button-icon icon-before mb-4"
      [src]="'../../../../assets/' + icon"
    />
    <p>{{text}}</p>
  </div>
  <div class="buttons-wrapper">

    <button
      *ngIf="cancelBtnText"
      [ngClass]="{'large-button': isLargeButton, 'dark': isDarkStyleButton, 'secondary': isSecondaryStyleButton}"
      class="btn btn-medium"
      (click)="bsModalRef.hide()"
    >
      <div class="text">{{ cancelBtnText }}</div>
    </button>

    <button
      *ngIf="actionBtnText"
      class="btn btn-medium dark ml-4"
      (click)="onAction(actionValue)"
    >
      <div class="text">{{ actionBtnText }}</div>
    </button>
  </div>
</div>