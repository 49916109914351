<div class="custom-input-field">
  <label *ngIf="!hideLabel" class="custom-input-label">
    {{label}}
    <span *ngIf="isRequired" class="required mr-1 align-middle">*</span>
  </label>
  <textarea class="custom-input-field-control large-input" [ngClass]="maxContent"
            [id]="elementId"
            [attr.maxLength]="inputMaxLength"
            [formControl]="control"
            [placeholder]="placeHolder"
            [class.active]="control.value"
            [class.invalid]="isInvalid()"
            [value]="control.value"
            [attr.disabled]="isDisabled() ? isDisabled() : null"
            [class.readonly]="readOnly"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (keydown)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            attr.inputmode="{{inputMode}}">

</textarea>
  <div *ngIf="isInvalid() && showValidationErrorText"
       class="custom-input-field-error">
    <img src="/assets/icons/error.svg">
    <span class="text-small-normal">{{getErrorMsg()}} </span>
  </div>
</div>
