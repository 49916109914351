import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EToasterType } from '../../../amdocs-utils/amdocs-models';

@Component({
  selector: 'lib-amdocs-toastr',
  templateUrl: './amdocs-toastr.component.html',
  styleUrls: ['./amdocs-toastr.component.scss']
})
export class AmdocsToastrComponent implements OnInit {

  @Input() title: string;
  @Input() msg: string;
  @Input() toastType: EToasterType;
  @Input() timeOut = 3000;
  @Output() toasterClose: EventEmitter<any> = new EventEmitter();

  constructor(private toastr: ToastrService) {
  }

  ngOnInit(): void {
    const options = {
      closeButton: true,
      timeOut: this.timeOut,
    };

    if (this.toastType === EToasterType.info) {
      this.toastr.info(this.msg, this.title, options);
    }
    if (this.toastType === EToasterType.success) {
      this.toastr.success(this.msg, this.title, options);
    }
    if (this.toastType === EToasterType.error) {
      this.toastr.error(this.msg, this.title, options);
    }
    if (this.toastType === EToasterType.warning) {
      this.toastr.warning(this.msg, this.title, options);
    }

    setTimeout(() => {
      this.toasterClose.emit();
    }, this.timeOut);
  }
}
