<div class="learning-plan-wrapper">
  <div class="header-wrapper">
    <div
      *ngIf="!isPreviewMode"
      class="d-flex flex-column"
    >
      <div class="label-h1">
        {{ "createPlan.learningPlan.title" | amdocsTranslate }}
      </div>
    </div>
    <div
      class="total-part-duration"
      *ngIf="!getPlantTotalDuration()"
    >
      <span *ngIf="this.parts?.length">Total 0 hr</span>
    </div>
    <div
      class="total-part-duration"
      *ngIf="getPlantTotalDuration()"
    >
      <span>Total {{ getPlantTotalDuration() | amdocsMinutesToHours : true }}</span>
    </div>
  </div>

  <div
    class="col-sm-12 pl-0 pr-0 mt-3 d-flex buttons-copy-wrapper"
    *ngIf="!isPreviewMode"
  >
    <div class="copy-part-buttons d-flex">
      <button
        type="button"
        class="btn btn-outline mr-3 d-flex align-items-center"
        (click)="openCopyPartsFromOtherPlan()"
      >
        <img
          class="mr-2"
          src="../../../../../assets/icons/file-copy.svg"
          [alt]="'learningPlan.copyPartsFromOtherPlan' | amdocsTranslate"
        />
        <span>{{
          "learningPlan.copyPartsFromOtherPlan" | amdocsTranslate
          }}</span>
      </button>
  
      <button
        *ngIf="this.parts?.length"
        type="button"
        class="btn btn-outline mr-3 d-flex align-items-center"
        (click)="addPart(true)"
      >
        <span>+ {{ "library.addAPart" | amdocsTranslate }}</span>
      </button>
    </div>
    <button
      class="collapse-part-button btn"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
    >
      <img
        lib-amdocs-image-version
        class="collapse-btn"
        [ngClass]="{ open: !isCollapsed }"
        src="/assets/icons/arrow-down.svg"
      />
    </button>
  </div>

  <div
    class="learning-plan-content"
    [sortablejs]="parts"
    [sortablejsOptions]="sortablejsOptions"
    #learningPlanContent
  >
    <div *ngFor="let part of parts; index as i; trackBy: partTracking">
      <app-learning-plan-part
        [learningTypesList]="learningTypesList"
        [part]="part"
        [isPreviewMode]="isPreviewMode"
        [isCollapsed]="isCollapsed"
        (openLibraryCallback)="openLibrary(part.order)"
        (removeItemCallback)="removeItem($event, part.order)"
        (removeCallback)="openModalRemovePart(i, part)"
        (renameCallback)="onPartRename()"
        (editItemCallback)="onEditItem()"
        (editInLibraryCallback)="editInLibrary($event)"
        (restoreItemCallback)="restoreItem($event, part.order)"
      ></app-learning-plan-part>
    </div>
  </div>

  <button
    class="add-part-button mt-2"
    *ngIf="!isPreviewMode"
    (click)="addPart()"
  >
    <div class="text">+ {{ "library.addAPart" | amdocsTranslate }}</div>
  </button>

  <app-library
    [showLibrary]="showLibrary"
    [partId]="partId"
    [parts]="parts"
    [selectedPlan]="selectedPlan"
    [isEditLibraryMode]="isEditLibraryMode"
    (closeCallback)="closeLibrary()"
    (addItemsToLearningPlanCallback)="addItemsToLearningPlan($event)"
    (itemEditedCallback)="itemEdited($event)"
    (itemActivationChangedCallback)="itemActivationChanged($event)"
  ></app-library>

  <app-copy-part-of-plan
    [showCopyPartOfPlan]="showCopyPartOfPlan"
    [learningTypesList]="learningTypesList"
    (closeCallback)="closeCopyPart()"
    (callBackAddCopyPart)="addCopyPartsToPlan($event)"
  >
  </app-copy-part-of-plan>
</div>