import { Component, Input } from "@angular/core";

@Component({
  selector: "lib-amdocs-button",
  templateUrl: "./amdocs-button.component.html",
  styleUrls: ["./amdocs-button.component.scss"],
})
export class AmdocsButtonComponent {
  @Input() btnText: string;
  @Input() btnHeight: string | number;
  @Input() isDisabled = false;
  @Input() width: string;
}
