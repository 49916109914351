<div class="rc-table-wrapper" role="grid" *ngIf="renderTable">
  <div class="amdocs-table-headers-wrapper d-flex align-items-center" [ngClass]="{'with-drawer': gridConfig.haveDrawer}" role="row" #amdocsTableHeaderWrapper>
    <div class="amdocs-table-headers-container">
      <ng-container *ngFor="let header of headers.toArray(); let i = index" class="amdocs-table-headers-container">
        <ng-container *ngIf="!gridConfig.cols[i].hidden">
          <div [ngClass]="{'sortable': gridConfig.cols[i].sortable}"
               class="rc-table-header-wrapper {{getSortClasses(header)}}"
               [ngStyle]="{width: gridConfig.cols[i].width}"
               (click)="headerClick(header)"
          >
            <div class="rc-table-header-cell-content-wrapper">
              <ng-container [ngTemplateOutlet]="header.tableHeader"
                            [ngTemplateOutletContext]="{data: header.data, headerClickCallback: header.headerClickCallback}"></ng-container>
            </div>
            <span *ngIf="isSorted(header)" class="sort-indicator "></span>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <button *ngIf="gridConfig.haveDrawer" class="collapse-part-button"
      [tooltip]="allGridOpened ? ('dashboard.empList.tableHeaders.unfoldAll' | amdocsTranslate) : ('dashboard.empList.tableHeaders.foldAll' | amdocsTranslate)"
      (click)="toggleAllDrawers()">
      <img lib-amdocs-image-version src="/assets/icons/arrow-down.svg" [ngClass]="{'drawer-opened': allGridOpened}" />
    </button>
  </div>
  <div class="rc-table-body-wrapper" #amdocsTableBodyWrapper>
    <ng-container *ngFor="let row of rows.toArray(); let i = index">
      <ng-container *ngIf="showRowByPage(i)">
        <div
          class="rc-table-row-wrapper {{gridRows[i].rowCustomClass}}"
          role="row"
          (click)="rowClick(row, i, $event)"
          (keydown)="onKeyDown($event, row, i)"
          [ngClass]="{'open': gridRows[i].drawerOpened, 'disabled': row.rowDisabled, 'with-drawer': gridConfig.haveDrawer && !row.drawerDisabled}"
          [attr.aria-expanded]="gridRows[i].drawerOpened"
        >
          <div class="rc-table-row-container d-flex align-items-center w-100">
            <ng-container
            [ngTemplateOutlet]="row.tableRow"
            [ngTemplateOutletContext]="{colsConfig: gridConfig, rowData: row.rowData}">
          </ng-container>
          <button
            *ngIf="row.rowData.plans.length > 1"
            class="collapse-part-button"
            (click)="toggleDrawer(i)"
            [attr.aria-expanded]="gridRows[i].drawerOpened"
          >
            <img
              class="drawer-opened"
              *ngIf="gridRows[i].drawerOpened"
              lib-amdocs-image-version
              src="/assets/icons/arrow-down.svg"
            />
            <img
              *ngIf="!gridRows[i].drawerOpened"
              lib-amdocs-image-version
              src="/assets/icons/arrow-down.svg"
            />
          </button>
          </div>

          <div
            class="rc-table-drawer-wrapper d-flex align-items-center w-100"
            *ngIf="gridConfig.haveDrawer && gridRows[i].drawerOpened"
          >
            <ng-container
              [ngTemplateOutlet]="drawers.toArray()[i].tableDrawer"
              [ngTemplateOutletContext]="{rowData: row.rowData}"
            ></ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
