import { Injectable } from '@angular/core';
import { AmdocsAuthService } from '../amdocs-auth/amdocs-auth.service';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AmdocsUserService } from '../amdocs-user/amdocs-user.service';
import { ApiError, IAmdocsUser } from '../amdocs-models';
import { AmdocsTranslateService } from '../amdocs-translate/amdocs-translate.service';
import { AmdocsDeviceService } from '../amdocs-device/amdocs-device.service';
import { Router } from '@angular/router';
import { AmdocsCookieService } from '../amdocs-cookie/amdocs-cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AmdocsAppInitService {

  public appInitError: ApiError;
  public appFinishedLoaded: boolean = false;

  constructor(
    private deviceService: AmdocsDeviceService,
    private cookieService: AmdocsCookieService,
    private translateService: AmdocsTranslateService,
    private authService: AmdocsAuthService,
    private router: Router,
    private userService: AmdocsUserService) { }

  public initApp(initQuery: string = null, setFixedHeightInMobile = true, redirectHomePostLogin = false): Observable<any> {
    if (setFixedHeightInMobile) {
      if (this.deviceService.isMobile()) {
        this.setAppHeights();
        window.addEventListener('resize', () => {
          this.setAppHeights();
        });
      }
    }
    return this.initAppFlow(initQuery).pipe(
      finalize(() => {
        if (redirectHomePostLogin && this.isLoginCallback()) {
          this.router.navigate(['']);
        }
      })
    );
  }

  private initAppFlow(initQuery: string = null): Observable<AmdocsAppInitResponse> {
    if (this.isLoginCallback() && this.authService.isPkceValid()) {
      console.log('login callback');
      this.authService.removePkceExp();
      return this.authService.login(this.getLoginCallbackCode()).pipe(
        mergeMap(() => {
          console.log('login success');
          return this.initPostLogin(initQuery, true);
        }),
        catchError((error: ApiError) => {
          console.log('login error');
          this.appInitError = error;
          return of(new AmdocsAppInitResponse(false, error));
        })
      );
    } else if (this.authService.isLoggedIn()) {
      return this.initPostLogin(initQuery);
    } else {
      if (this.authService.hasRefreshTokenCookies()) {
        console.log('refresh token exists');
        return this.authService.callRefreshUserToken().pipe(
          mergeMap(() => {
            console.log('refresh token success');
            return this.initPostLogin(initQuery);
          }),
          catchError(() => {
            console.log('refresh token failed');
            return this.initGetLoginUrlFlow();
          })
        );
      } else {
        console.log('refresh token does not exists');
        return this.initGetLoginUrlFlow();
      }
    }
  }

  private initGetLoginUrlFlow(): Observable<AmdocsAppInitResponse> {
    return this.authService.initGetLoginUrlFlow().pipe(
      catchError((error: ApiError) => {
        this.appInitError = error;
        return of(new AmdocsAppInitResponse(false, error));
      })
    );
  }

  private setAppHeights(): void {
    if (!this.deviceService.screenHeight || window.innerHeight > this.deviceService.screenHeight) {
      this.deviceService.screenHeight = window.innerHeight;
    }
    // let h: number;
    // let w: number;
    // if (this.deviceService.isIos()) {
    //   h = window.innerHeight;
    //   w = window.innerWidth;
    // } else {
    //   h = window.screen.availHeight;
    //   w = window.screen.availWidth;
    // }
    // this.deviceService.portraitMode = h >= w;
    // if (!this.deviceService.portraitMode) {
    //   this.deviceService.screenHeight = window.innerHeight;
    // }
    document.body.style.height = this.deviceService.screenHeight + 'px';
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  // is route a callback from login
  private isLoginCallback(): boolean {
    return window.location.href.indexOf('_callback') > -1;
  }

  // get login callback code
  private getLoginCallbackCode(): string {
    return new URLSearchParams(window.location.search).get('code');
  }

  private initPostLogin(initQuery: string = null, isPostLogin: boolean = false): Observable<AmdocsAppInitResponse> {
    // use token expiration is less the 40 minutes
    if (this.authService.idTokenIsAboutToExpired()) {
      // make a call to refresh token and start the interval
      this.authService.refreshUserToken();
    } else {
      // only start the interval
      this.authService.setRefreshTokenInterval();
    }

    // init user details
    if (initQuery) {
      return this.userService.init(initQuery).pipe(
        mergeMap((user: IAmdocsUser) => {
          return this.translateService.loadTranslate(user.lang).pipe(
            mergeMap(() => {
              return of(new AmdocsAppInitResponse(true, null, isPostLogin));
            })
          );
        }),
        catchError((error: ApiError) => {
          this.appInitError = error;
          return of(new AmdocsAppInitResponse(false, error, isPostLogin));
        })
      );
    }
    return of(new AmdocsAppInitResponse(true, null, isPostLogin));
  }
}

export class AmdocsAppInitResponse {
  constructor(
    public success: boolean,
    public error: ApiError = null,
    public isPostLogin: boolean = false
  ) { }
}
