import { AfterContentInit, Directive, ElementRef, Inject } from '@angular/core';


/**
 * @description
 * Amdocs amdocs-image-version directive - add version to source image
 *
 * @example
 * <img amdocs-image-version >Some content</div>
 *
 */
@Directive({
  selector: '[lib-amdocs-image-version]'
})
export class AmdocsImageVersionDirective implements AfterContentInit {

  public imageVersion;

  constructor(private el: ElementRef, @Inject('IImageVersion') imageVersion: string) {
    this.imageVersion = imageVersion;
  }


  ngAfterContentInit(): void {
    this.el.nativeElement.src = `${this.el.nativeElement.src}?${this.imageVersion}`;
  }

}
