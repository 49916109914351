<div class="amdocs-form-checkbox" [class.disabled]="disabled">
  <label class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      [(ngModel)]="value"
      [disabled]="disabled"
    />
    <span
      class="
        custom-control-indicator
        d-flex
        align-items-center
        justify-content-center
      "
    ></span>
    <span
      class="
        custom-control-description
        d-flex
        align-items-center
        justify-content-center
      "
      [ngClass]="{
        disabled: disabled
      }"
    >
    </span>
  </label>
  <div class="amdocs-form-checkbox__label">
    {{ label }}
  </div>
</div>
