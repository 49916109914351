import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiError } from '../amdocs-models';

@Injectable({
  providedIn: 'root'
})
export class AmdocsApiUtilsService {

  constructor(

  ) { }

  public handleGatewayResponse(responseObs: Observable<any>): Observable<any> {
    return responseObs.pipe(
      mergeMap((response: any) => {
        // TODO:: check for errors
        return of(response.data);
      }),
      catchError((err) => {
        let errorMsg = err.toString();
        let code = 0;
        if (err.status !== 200) {
          errorMsg = err.data?.message;
          code = err.status;
        }
        return this.handleError(errorMsg, code);
      })
    );
  }

  public handleError(errorMessage: string, code: number = 0): Observable<ApiError> {
    const apiError = new ApiError(code, errorMessage);
    return throwError(apiError);
  }
}


