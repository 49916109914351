import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmdocsEventBusService } from 'projects/amdocs-core-package/src/public-api';
import { CONSTANTS } from '../../../constants';
import { UserService } from '../../../core/user.service';
import { UtilsService } from '../../../core/utils.service';
import { CreateLearningPlanService } from '../../../feature/create-learning-plan/create-learning-plan.service';
import { SortableTemplatesStatuses } from '../../../models/learningTemplate';

@Component({
  selector: 'app-learning-templates-list',
  templateUrl: './learning-templates-list.component.html',
  styleUrls: ['./learning-templates-list.component.scss']
})
export class LearningTemplatesListComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() searchText: string;
  @Input() templates: any;
  @Input() loadAdditionalInfo = true;
  @Input() errorType = 'plansEmpty';

  @Output() refreshTemplates = new EventEmitter();
  @Output() getSortByValue = new EventEmitter();
  @Output() removeTemplate: EventEmitter<any> = new EventEmitter<any>()

  sortFC = new UntypedFormControl();
  errorTypes = {
    plansEmpty: 'plansEmpty',
    emptyResults: 'emptyResults'
  };
  public sortList = [
    {
      key: 'All',
      value: SortableTemplatesStatuses.All
    },
    {
      key: 'You',
      value: SortableTemplatesStatuses.CurrentUser
    },
    {
      key: 'Other',
      value: SortableTemplatesStatuses.OtherUsers
    }
  ];

  public data: any;
  public seniorityList = [];
  public org2List = [];
  public rolesList = [];
  public productsList = [];
  public locationsList = [];
  public accountsList = [];
  public plansInfo: any;
  public isLoading = true;
  public sortedTemplates: any;
  public SortableTemplatesStatuses = SortableTemplatesStatuses;

  constructor(
    private createPlanService: CreateLearningPlanService,
    private utils: UtilsService,
    private eventBus: AmdocsEventBusService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.sortFC.setValue(SortableTemplatesStatuses.All);
    this.initForm(this.loadAdditionalInfo);

    const currUser = this.userService.getName();
    this.sortFC.valueChanges.subscribe(value => {
      if (!this.loadAdditionalInfo) {
        const key = Object.keys(SortableTemplatesStatuses).find(key => SortableTemplatesStatuses[key] === value);
        this.getSortByValue.emit(key);
        return;
      }

      switch (value) {
        case SortableTemplatesStatuses.CurrentUser:
          this.sortedTemplates = this.templates.filter(item => item.createdBy === currUser);
          break;
        case SortableTemplatesStatuses.OtherUsers:
          this.sortedTemplates = this.templates.filter(item => item.createdBy !== currUser);
          break;
        default:
          this.sortedTemplates = this.templates;
          break;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates && changes.templates.currentValue) {
      this.sortedTemplates = changes.templates.currentValue;
    }
  }

  initForm(loadAdditionalInfo): void {
    if (!loadAdditionalInfo) {
      this.isLoading = false;
      return;
    }

    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.createPlanService.initDropDowns().subscribe((res) => {
      this.data = res;
      this.initDropDownData();
    });
  }

  initDropDownData(): void {
    this.productsList.concat(this.data.managedProducts);
    this.productsList.concat(this.data.customProducts);

    this.data.accounts.forEach((a) => {
      const accountItem = { code: a.code, name: a.name };
      this.accountsList.push(accountItem);
    });

    this.data.locations.forEach((l) => {
      const locationItem = { code: l.code, name: l.name };
      this.locationsList.push(locationItem);
    });

    this.data.roles.forEach((r) => {
      const roleItem = { code: r.role, name: r.name };
      this.rolesList.push(roleItem);
    });

    this.data.seniorityLevels.forEach((s) => {
      const seniorityItem = { key: s.code, value: s.name };
      this.seniorityList.push(seniorityItem);
    });

    this.data.org2Units.forEach((o) => {
      this.org2List.push({ code: o.code, name: o.name, subUnits: o.subUnits });
    });

    this.utils.dropdownData = { ...this.utils.dropdownData, ...this.data };
    this.utils.dropdownData.roles = this.rolesList;
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
    this.isLoading = false;
  }

  isRefreshTemplates(): void {
    this.refreshTemplates.emit();
  }

  removeTemplateHandler($event): void {
    this.sortedTemplates = this.sortedTemplates.filter(item => item.code !== $event.templateDetails.code);
    this.removeTemplate.emit($event);
  }
}
