import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
    QueryList, SimpleChanges,
    ViewChild
} from '@angular/core';
import { AmdocsTableHeaderComponent } from './amdocs-table-header.component';
import { AmdocsTableRowComponent } from './amdocs-table-row.component';
import { AmdocsTableDrawerComponent } from './amdocs-table-drawer.component';
import { IGridConfig } from '../../../amdocs-utils/amdocs-models';

@Component({
    selector: 'lib-amdocs-table',
    templateUrl: './amdocs-table.component.html',
    styleUrls: ['./amdocs-table.component.scss']
})

export class AmdocsTableComponent implements OnInit, AfterViewInit, OnChanges {

    @ContentChildren(AmdocsTableHeaderComponent) headers: QueryList<AmdocsTableHeaderComponent>;
    @ContentChildren(AmdocsTableRowComponent) rows: QueryList<AmdocsTableRowComponent>;
    @ContentChildren(AmdocsTableDrawerComponent) drawers: QueryList<AmdocsTableDrawerComponent>;
    @Input() gridConfig: IGridConfig;
    @Input() gridRows: any[] = [];
    @Input() pageNumber = 0;
    @Input() footerRows: any[] = [];
    @Input() serverSideSorting = false;

    @Output() rowClickCallback: EventEmitter<any> = new EventEmitter();
    @Output() headerClickCallback: EventEmitter<any> = new EventEmitter();

    @ViewChild('amdocsTableHeaderWrapper') amdocsTableHeaderWrapper: ElementRef;
    @ViewChild('amdocsTableBodyWrapper') amdocsTableBodyWrapper: ElementRef;

    public asc = true;
    public sortedHeader: any = {};
    public renderTable = false;
    public allGridOpened = false;

    ngOnInit(): void {
        this.gridConfig.cols.map((c) => {
            c.initialWidth = parseInt(c.width.replace('%', ''), 10);
        });
    }

    ngAfterViewInit(): void {
        this.calculateHeaderPadding();
        this.recalculateCols();
        this.renderTable = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.calculateHeaderPadding();
    }

    calculateHeaderPadding(): void {
        if (!this.amdocsTableBodyWrapper || !this.amdocsTableHeaderWrapper) {
            return;
        }
        const style = window.getComputedStyle(this.amdocsTableBodyWrapper.nativeElement);
        const bodyClientWidth = this.amdocsTableBodyWrapper.nativeElement.clientWidth;
        const bodyOffsetWidth = this.amdocsTableBodyWrapper.nativeElement.offsetWidth;
        const padding = bodyOffsetWidth - bodyClientWidth;
        if (padding) {
            if (style.direction === 'ltr') {
                this.amdocsTableHeaderWrapper.nativeElement.style.paddingRight = padding + 'px';
            } else {
                this.amdocsTableHeaderWrapper.nativeElement.style.paddingLeft = padding + 'px';
            }
        }
    }

    headerClick(header): void {
        if (header.data.sortable) {
            if (!this.serverSideSorting) {
                this.gridRows = this.sortArrayByKey(this.gridRows, header.data.field, this.asc);
            }
            this.asc = !this.asc;
            this.sortedHeader = header.data;
        }
        if (this.headerClickCallback.observers.length) {
            const sortObj = {
                col: header.data,
                direction: this.asc ? 'asc' : 'desc'
            };
            this.headerClickCallback.emit(sortObj);
        }
    }

    rowClick(row, i, event): void {
        if (event.rcTableCol && event.rcTableCol.preventDrawerToggle) {
            return;
        }

        if (this.rowClickCallback.observers.length) {
            this.rowClickCallback.emit(row.rowData);
        }
    }

    sortArrayByKey(arr: any[], key: string, asc = true): any[] {
        if (asc) {
            arr.sort((a, b) => {
                const aKey = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
                const bKey = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
                return (aKey > bKey ? -1 : 1);
            });
        } else {
            arr.sort((a, b) => {
                const aKey = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
                const bKey = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
                return (aKey < bKey ? -1 : 1);
            });
        }
        return arr;
    }

    getSortClasses(header): string {
        const classes = [];
        if (this.isSorted(header)) {
            classes.push('sorted');
            classes.push(this.asc ? 'asc' : 'desc');
        }
        return classes.join(' ');
    }
    
    isSorted(header): boolean {
        return header.data.field === this.sortedHeader.field;
    }

    toggleDrawer(i): void {
        this.gridRows[i].drawerOpened = !this.gridRows[i].drawerOpened;
    }

    toggleAllDrawers(): void {
        this.allGridOpened = !this.allGridOpened;
        this.gridRows.forEach(item => item.drawerOpened = this.allGridOpened);
    }

    onKeyDown($event, row, i): void {
        if ($event.which === 13) {
            if (this.gridConfig.haveDrawer) {
                this.rowClick(row, i, $event);
            }
        }
    }

    showRowByPage(i): boolean {
        if (this.gridConfig.hasClientPaging) {
            return i >= this.pageNumber * this.gridConfig.pageSize && i < (this.pageNumber + 1) * this.gridConfig.pageSize;
        }
        return true;
    }

    public recalculateCols(): void {
        const tmpConfig: any = JSON.parse(JSON.stringify(this.gridConfig));
        const total = tmpConfig.cols.filter(c => !c.hidden).reduce((prev, curr) => {
            return prev + curr.initialWidth;
        }, 0);
        let totalAfter = 0;
        tmpConfig.cols.filter(c => !c.hidden).map((col) => {
            col.width = Math.floor(((100 / total) * (col.initialWidth)) * 100) / 100;
            totalAfter += col.width;
        });

        tmpConfig.cols.filter(c => !c.hidden)[0].width += (100 - totalAfter);
        tmpConfig.cols.filter(c => !c.hidden).map(c => c.width = c.width + '%');
        tmpConfig.cols.forEach((tmp, i) => {
            this.gridConfig.cols[i].width = tmp.width;
        });
    }
}
