import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AmdocsEventBusService,
  AmdocsPopoverComponent,
  AmdocsPopupComponent,
  AmdocsTranslateService,
} from 'projects/amdocs-core-package/src/public-api';
import { CreateLearningPlanService } from '../../../feature/create-learning-plan/create-learning-plan.service';
import { finalize } from 'rxjs/operators';
import { CONSTANTS } from '../../../constants';
import { ApiService } from '../../../core/api.service';
import { UtilsService } from '../../../core/utils.service';
import { LearningTemplateStatus } from '../../../models/learningTemplate';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { PreviewPlanComponent } from '../preview-plan-popup/preview-plan-popup.component';
import { AmdocsActionPopupComponent } from 'projects/amdocs-core-package/src/lib/amdocs-ui/components';
import { PdfGeneratorService } from '../../services/pdf-generator.service';

@Component({
  selector: 'app-learning-template',
  templateUrl: './learning-template.component.html',
  styleUrls: ['./learning-template.component.scss'],
})
export class LearningTemplateComponent implements OnInit {
  @Input() template;
  @Input() showAdditionalBlock = true;
  @Input() isCopy = false;
  @Input() isPreviewMode = true;
  @Input() truncateRequired = true;

  public bsModalRef?: BsModalRef;
  public bsPreviewModalRef?: BsModalRef;
  public bsPublishModalRef?: BsModalRef;
  public LearningTemplateStatus = LearningTemplateStatus;
  public showDeleteModal = false;
  public isDeleted = false;
  public planInfo: any;
  public get showPlanDetailsTooltip() {
    const planInfo = this.planInfo;
    if (!planInfo) {
      return false;
    }

    return (
      planInfo.description ||
      planInfo.expectedTime ||
      planInfo.seniority ||
      planInfo.role ||
      planInfo.locationLabel ||
      planInfo.account ||
      planInfo.org2 ||
      planInfo.org3 ||
      planInfo.org4 ||
      planInfo.org5 ||
      planInfo.customProductIDs?.length ||
      planInfo.productIDs?.length
    );
  }
  private isActionBtnDisabled = false;

  @ViewChild('deletePlanModal') deletePlanModal: AmdocsPopupComponent;
  @ViewChild('popOver') popOver: AmdocsPopoverComponent;
  @Output() removeTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshTemplates = new EventEmitter();
  @Output() getCopyCode = new EventEmitter();

  constructor(
    private createPlanService: CreateLearningPlanService,
    private apiService: ApiService,
    private eventBus: AmdocsEventBusService,
    private utils: UtilsService,
    private translateService: AmdocsTranslateService,
    private modalService: BsModalService,
    private pdfGeneratorService: PdfGeneratorService,
    private router: Router
  ) {
    this.removeTemplateCallback = this.removeTemplateCallback.bind(this);
  }

  ngOnInit(): void {
    this.planInfo = this.utils.getValuesOfItem(this.template);
  }

  openDeleteModal(template): void {
    if (
      !template ||
      this.template.status === LearningTemplateStatus.Published
    ) {
      return;
    }
    this.hidePopOver();
    this.showDeleteModal = true;
  }

  close(): void {
    this.showAdditionalBlock = false;
  }

  closeDeletePlanModal(): void {
    this.deletePlanModal.close();
    this.showDeleteModal = false;
  }

  editPlan(template): void {
    if (template.isLocked) {
      this.openLockedModal();
      return;
    }
    this.router.navigate(['/learning-plan/edit/' + template.code]);
    this.hidePopOver();
  }

  deletePlan(templateCode): void {
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.closeDeletePlanModal();
    this.createPlanService.deleteLearningTemplate(templateCode).subscribe(
      () => {
        this.isDeleted = true;
        this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        this.removeTemplate.emit({
          templateDetails: {
            code: templateCode,
            title: this.template.title,
          },
        });
      },
      (error) => {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
          CONSTANTS.Toaster.ERROR,
          error.message,
        ]);
      }
    );
  }

  makeCopy(code) {
    this.getCopyCode.emit(code);
  }

  publishPlan(plan): void {
    this.hidePopOver();
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.createPlanService
      .publishLearningTemplate(plan.code)
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      )
      .subscribe(
        () => {
          this.refreshTemplates.emit();
          this.template.status = LearningTemplateStatus.Published;
          this.template.draftWaiting = false;
          this.bsPublishModalRef.hide();
        },
        (error) => {
          if (error.errorType === 'LOCKED') {
            this.openLockedModal();
          } else if (error.errorType === `DUPLICATION`) {
            this.openModalDuplication();
          } else if (error.errorType === `INVALID_TEMPLATE_ITEMS_COUNT`) {
            this.openModalAtLeast3items();
          } else if (error.errorType === `INVALID_TEMPLATE_PHASE`) {
            this.openModalEmptyPart();
          } else {
            this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
              CONSTANTS.Toaster.ERROR,
              error.message,
            ]);
          }
        }
      );
  }

  hidePopOver() {
    this.popOver.hide();
  }

  openLockedModal() {
    this.bsModalRef = this.modalService.show(AmdocsActionPopupComponent, { id: 2 });
    this.bsModalRef.content.title = this.translateService.getText(
      'templateLearning.lockedPopup.title'
    );
    this.bsModalRef.content.text = this.translateService.getText(
      'templateLearning.lockedPopup.text'
    );
    this.bsModalRef.content.icon = 'icons/locked.svg';
    this.bsModalRef.content.isLargeButton = true;
  }

  openModalDuplication() {
    this.bsModalRef = this.modalService.show(AmdocsActionPopupComponent, { id: 2 });
    this.bsModalRef.content.title = this.translateService.getText(
      'templateLearning.duplicationPopup.title'
    );
    this.bsModalRef.content.text = this.translateService.getText(
      'templateLearning.duplicationPopup.text'
    );
    this.bsModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.duplicationPopup.closeBtnName'
    );
    this.bsModalRef.content.cancelBtnText = this.translateService.getText(
      'templateLearning.duplicationPopup.cancelBtnText'
    );
    this.bsModalRef.content.isLargeButton = true;
  }

  openModalEmptyPart() {
    this.bsModalRef = this.modalService.show(AmdocsActionPopupComponent, { id: 2 });
    this.bsModalRef.content.title = this.translateService.getText(
      'templateLearning.emptyPartPopup.title'
    );
    this.bsModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.emptyPartPopup.closeBtnName'
    );
    this.bsModalRef.content.cancelBtnText = this.translateService.getText(
      'templateLearning.emptyPartPopup.cancelBtnText'
    );
    this.bsModalRef.content.header = `${this.translateService.getText(
      'templateLearning.emptyPartPopup.headerWithoutPartsInformation'
    )}`;
    this.bsModalRef.content.text = this.translateService.getText(
      'templateLearning.emptyPartPopup.text'
    );
    this.bsModalRef.content.isLargeButton = true;
  }

  openModalAtLeast3items() {
    this.bsModalRef = this.modalService.show(AmdocsActionPopupComponent, { id: 2 });
    this.bsModalRef.content.title = this.translateService.getText(
      'templateLearning.lessThen3items.title'
    );
    this.bsModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.lessThen3items.closeBtnName'
    );
    this.bsModalRef.content.cancelBtnText = this.translateService.getText(
      'templateLearning.lessThen3items.cancelBtnText'
    );
    this.bsModalRef.content.header = this.translateService.getText(
      'templateLearning.lessThen3items.header'
    );
    this.bsModalRef.content.text = this.translateService.getText(
      'templateLearning.lessThen3items.text'
    );
    this.bsModalRef.content.isLargeButton = true;
  }

  publishModalConfirm(plan) {
    this.bsPublishModalRef = this.modalService.show(AmdocsActionPopupComponent, {
      initialState: {
        onAction: (result) => {
          if (this.isActionBtnDisabled) {
            return;
          }
          this.isActionBtnDisabled = true;
          if (result) {
            this.publishPlan(plan);
          }

          setTimeout(() => {
            this.isActionBtnDisabled = false;
          }, 2000);
        },
      },
    });
    this.bsPublishModalRef.content.title = this.translateService.getText(
      'templateLearning.publishPlanPopup.title'
    );
    this.bsPublishModalRef.content.header = `${this.translateService.getText(
      'templateLearning.publishPlanPopup.header'
    )} "${plan.title}"?`;
    this.bsPublishModalRef.content.text = this.translateService.getText(
      'templateLearning.publishPlanPopup.text'
    );
    this.bsPublishModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.publishPlanPopup.closeBtnName'
    );
    this.bsPublishModalRef.content.cancelBtnText =
      this.translateService.getText(
        'templateLearning.publishPlanPopup.cancelBtnText'
      );
    this.bsPublishModalRef.content.actionBtnText =
      this.translateService.getText(
        'templateLearning.publishPlanPopup.actionBtnText'
      );
    this.bsPublishModalRef.content.actionValue = true;
    this.bsPublishModalRef.content.isLargeButton = false;
  }

  switchToDraft(code): void {
    this.hidePopOver();
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.createPlanService
      .moveTemplateToDraft(code)
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      )
      .subscribe(
        () => {
          this.refreshTemplates.emit();
          this.template.status = LearningTemplateStatus.Draft;
          this.template.draftWaiting = false;
        },
        (error) => {
          if (error.errorType === 'LOCKED') {
            this.openLockedModal();
          } else {
            this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
              CONSTANTS.Toaster.ERROR,
              error.message,
            ]);
          }
        }
      );
  }

  showPlanDetails(item) {
    if (this.utils.dropdownData.org2Units && item.org2ID) {
      const selectedOrg2Item = this.utils.dropdownData.org2Units.find(
        (org2Item) => org2Item.code === item.org2ID
      );
      if (
        selectedOrg2Item &&
        selectedOrg2Item.subUnits &&
        selectedOrg2Item.subUnits.length
      ) {
        this.utils.dropdownData.org3Units = selectedOrg2Item.subUnits.map(
          (o) => ({ code: o.code, name: o.name })
        );
        item.org3 = this.utils.getStringValueOfSelect(
          item.org3ID,
          this.utils.dropdownData.org3Units
        );
      }
    }

    if (this.utils.dropdownData.org3Units && item.org3ID && item.org4ID) {
      this.apiService.getOrg3Units(item.org3ID).subscribe(
        (org3Units) => {
          if (
            org3Units.length &&
            org3Units[0].subUnits &&
            org3Units[0].subUnits.length
          ) {
            this.utils.dropdownData.org4Units = org3Units[0].subUnits.map(
              (o) => ({ code: o.code, name: o.name })
            );
            item.org4 = this.utils.getStringValueOfSelect(
              item.org4ID,
              this.utils.dropdownData.org4Units
            );
          }
          if (this.utils.dropdownData.org4Units && item.org4ID) {
            this.setOrg5Value(item);
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
    }
  }

  setOrg5Value(item) {
    this.apiService.getOrg4Units(item.org4ID).subscribe(
      (org4Units) => {
        if (
          org4Units.length &&
          org4Units[0].subUnits &&
          org4Units[0].subUnits.length
        ) {
          this.utils.dropdownData.org5Units = org4Units[0].subUnits.map(
            (o) => ({ code: o.code, name: o.name })
          );
          item.org5 = this.utils.getStringValueOfSelect(
            item.org5ID,
            this.utils.dropdownData.org5Units
          );
        }
      },
      (error) => {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
          CONSTANTS.Toaster.ERROR,
          error.message,
        ]);
      }
    );
  }

  showPreview(code: string): void {
    if (!this.isPreviewMode) {
      return;
    }

    this.bsPreviewModalRef = this.modalService.show(PreviewPlanComponent, {
      backdrop: 'static',
      initialState: {
        planCode: code,
        removeTemplateCallback: this.removeTemplateCallback,
      },
      class: 'preview-modal',
    });
    this.bsPreviewModalRef.content.actionValue = 'refresh';
  }

  removeTemplateCallback() {
    this.removeTemplate.emit({
      templateDetails: {
        code: this.template.code,
        title: this.template.title,
      },
    });
  }

  exportPdf(code, title) {
    this.hidePopOver();
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.apiService.exportPdf(code).subscribe(
      res => {
        if (res.data) {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
          this.pdfGeneratorService.generatePdf(res.data, title);
        }
      },
      error => {
        this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      }
    );
  }
}
