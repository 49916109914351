import { Component, OnInit } from '@angular/core';
import {
  AmdocsAppInitService,
  AmdocsEventBusService,
  EToasterType,
} from 'projects/amdocs-core-package/src/public-api';
import { CONSTANTS } from './constants';
import { Toaster } from './models';
import { environment } from '../environments/environment';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public toaster: Toaster = { show: false, text: '', type: '' };
  public showLoaderCount = 0;
  public allowMimic = environment.allowMimic;
  public pageTitle: any = null;
  public CONSTANTS = CONSTANTS;

  constructor(private appInitService: AmdocsAppInitService,
    private eventBusService: AmdocsEventBusService,
    private router: Router,
    private toasterService: ToastrService
  ) { }

  toasterClose(): void {
    this.toaster.show = false;
  }

  ngOnInit(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.pageTitle = null;
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
    this.eventBusService.on(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, (showLoader) => {
      if (showLoader) {
        setTimeout(() => {
          this.showLoaderCount++;
        });
      } else {
        if (this.showLoaderCount > 0) {
          this.showLoaderCount--;
        }
      }
    }, true);


    this.eventBusService.on(CONSTANTS.EVENTS.SHOW_TOASTER, (toasterObj) => {
      this.toaster.show = true;
      this.toaster.type = toasterObj[0];
      this.toaster.text = toasterObj[1];

      const options = {
        closeButton: true
      };

      if (this.toaster.type === EToasterType.info) {
        this.toasterService.info(this.toaster.text, this.toaster.type, options);
      }
      if (this.toaster.type === EToasterType.success) {
        this.toasterService.success(this.toaster.text, this.toaster.type, options);
      }
      if (this.toaster.type === EToasterType.error) {
        this.toasterService.error(this.toaster.text, this.toaster.type, options);
      }
      if (this.toaster.type === EToasterType.warning) {
        this.toasterService.warning(this.toaster.text, this.toaster.type, options);
      }
    }, true);

    this.eventBusService.on(CONSTANTS.EVENTS.SET_PAGE_TILE, (pageTitle) => {
      this.pageTitle = pageTitle;
    }, true);
  }

  get appFinishedLoaded(): boolean {

    return true;
  }

  get appError(): boolean {
    return this.appInitService.appInitError !== null && this.appInitService.appInitError !== undefined;
  }
}
