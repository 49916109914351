<div
  class="library-overlay"
  *ngIf="showCopyPartOfPlan"
></div>
<div
  class="library-component"
  *ngIf="showCopyPartOfPlan"
>
  <div class="title-wrapper">
    <div class="label-h1">
      {{ "learningPlan.copyPartsFromOtherPlan" | amdocsTranslate }}
    </div>
    <img
      lib-amdocs-image-version
      class="pointer"
      (click)="closeCopyPart()"
      src="/assets/icons/close-overlay.svg"
    />
  </div>
  <div class="actions-wrapper">
    <div class="right-side-wrapper">
      <div class="search-wrapper">
        <div class="search-input-wrapper">
          <lib-amdocs-input-field
            [elementId]="'library-search'"
            [placeHolder]="'learningPlan.searchPlaceholder' | amdocsTranslate"
            [control]="searchControl"
            [hideLabel]="true"
          ></lib-amdocs-input-field>
          <img
            class="find-an-item-icon"
            lib-amdocs-image-version
            src="/assets/icons/find-an-item.svg"
          />
          <img
            class="clear-input-icon"
            *ngIf="searchControl.value"
            (click)="clearSearchInput()"
            lib-amdocs-image-version
            src="/assets/icons/clear-input.svg"
          />
        </div>
      </div>
      <div class="sort-by-wrapper">
        <div class="sort-by-title">
          {{ "library.sortBy.label" | amdocsTranslate }}
        </div>
        <div class="sort-by-dd">
          <lib-amdocs-dropdown
            [elementId]="'library-sort'"
            [newDesign]="true"
            [items]="sortList"
            [control]="sortControl"
            [hideLabel]="true"
          ></lib-amdocs-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="results-wrapper" 
  [ngClass]="{'with-selection': totalCountPartsIsChecked}"
  >
    <div class="library-items-wrapper">
      <div *ngFor="let plans of learningTemplates; index as i">
        <div *ngFor="let items of plans; index as i; trackBy: planTracking">
          <ng-container *ngIf="startOffset === plans.itemsStart">
            <app-copy-part-of-plan-planList 
            [learningPlans]="items"
            [learningTypesList]="learningTypesList"
            (selectedPartsCallBackToHigherArray)="selectedPartsCallBack($event)"
           >
           </app-copy-part-of-plan-planList>
          </ng-container>
        </div>
        
      </div>

      <amdocs-list-paginator
        *ngIf="totalRows > pageSize  && !isLoading"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [totalRows]="totalRows"
        (onPageChange)="onPageChange($event)"
      >
      </amdocs-list-paginator>

      <div
        class="alternative-actions-wrapper"
        *ngIf="
        !defaultResponse.length &&
          !isLoading
        "
      >
        <div class="empty-search-wrapper">
          <img
            lib-amdocs-image-version
            class="pointer"
            src="/assets/icons/items-search/no_results.svg"
          />
          <div class="empty-search">
            {{ "library.emptyResults" | amdocsTranslate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FOOTER ADDED PART TO PLAN -->
  <div class="footer-wrapper">
    <div>
      <span
        class="items-count"
        (click)="openModalSelectedItems(modalTemplate)"
      >
        <ng-container
          *ngIf="totalCountPartsIsChecked > 1; then plural; else singular"
        ></ng-container>
        <ng-template #singular>{{
          "learningPlan.partSelected" | amdocsTranslate : [totalCountPartsIsChecked]
        }}</ng-template>
        <ng-template #plural>{{
          "learningPlan.partsSelected" | amdocsTranslate : [totalCountPartsIsChecked]
        }}</ng-template>
      </span>
      <button
        class="add-to-plan-button"
        (click)="addPartsToLearningPlan()"
      >
        <div class="text">{{ "library.addToPlan" | amdocsTranslate }}</div>
      </button>
    </div>
  </div>

  <ng-template #modalTemplate>
    <div class="modal-header">
      <span class="label-h1">{{ "selectedItemsPopup.title" | amdocsTranslate }} ({{totalCountPartsIsChecked}})</span>
      <div class="close-popup-button" role="button" (click)="bsModalRef.hide()" >
        <img src="/assets/icons/popup-close.svg" />
      </div>
    </div>
    <div class="modal-body">
      <div class="item-row" *ngFor="let item of selectedPartsArray">
          <div class="d-flex align-items-center w-100" *ngFor="let part of item.parts">
            <ng-container *ngIf="part.isChecked">
              <app-copy-part-of-plan-part-list
                [part]="part"
                [planTitle]="item.title"
                [learningTypesList]="learningTypesList"
                [isModalSelectedParts]="true"
              >
              </app-copy-part-of-plan-part-list>
              <lib-amdocs-popover
              class="remove-item-wrapper"
              [trigger]="'hover'"
              [placement]="'top'"
            >
              <div popoverOpener>
                <div (click)="removeItemAtModal(part)">
                  <img lib-amdocs-image-version src="/assets/icons/popup-close.svg" />
                </div>
              </div>
              <div
                popoverContent
                class="text-white"
              >
                {{ "learningPlan.modalCopyPartButtonRemove" | amdocsTranslate }}
              </div>
            </lib-amdocs-popover>
            </ng-container>
          </div>
      </div>
      
  </div>
 </ng-template>

</div>
