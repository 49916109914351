import { Component, OnInit, Input } from '@angular/core';
import { AmdocsControlAbstract } from '../amdocs-control-abstract';

@Component({
  selector: 'lib-amdocs-large-text-input',
  templateUrl: './amdocs-large-text-input.component.html',
  styleUrls: ['./amdocs-large-text-input.component.scss']
})
export class AmdocsLargeTextInputComponent extends AmdocsControlAbstract implements OnInit {
  @Input() isRequired: boolean = false;

  public maxContent = null;

  ngOnInit(): void {
    super.ngOnInit();
    this.checkMaxWidth();
  }

  public checkMaxWidth(): void {
    if (!this.inputMaxLength) {
      this.maxContent = 'fit-content';
    }
  }
}
