import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { UserService } from './user.service';

import { environment } from '../../environments/environment';
import {
  AmdocsAppsyncClientService,
  AmdocsAuthService,
  AmdocsGatewayClientService
} from 'projects/amdocs-core-package/src/public-api';

import {
  LearningItem,
  OrgUnit,
  SearchLearningItems,
} from '../models/learningPlan';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public promptEvent: any;

  constructor(private appGw: AmdocsGatewayClientService,
    private userService: UserService,
    private authService: AmdocsAuthService,
    private appSyncClient: AmdocsAppsyncClientService) {
  }


  public buildPayload(obj: any): any {
    if (environment.allowMimic) {
      return {};
    }
    const base: any = {
      TransactionId: '10'/* this.utils.createGuid()*/,
      AgentType: 'Mobile',
      IdToken: 'token', /* this.authService.getToken()*/
      ScreenID: 'M'
    };


    return Object.assign({}, base, obj);
  }

  public getSeniorityLevels(): Observable<any> {
    const query = `
      query GetSeniorityLevels {
        seniorityLevels {
        code
        name
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetSeniorityLevels').pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getProducts(): Observable<any> {
    const query = `
    query GetProducts {
      products {
        statusCode
        managed {
          code
          name
        }
        custom {
          code
          name
        }
      }
    }
    `;

    return this.appSyncClient.call(query, 'GetProducts').pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public addCustomProduct(params): Observable<any> {
    const query = `
      mutation AddCustomProduct($name: String!) {
        addCustomProduct(name: $name) {
            itemCode
            statusCode
        }
      }
    `

    return this.appSyncClient.call(query, 'AddCustomProduct', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    )
  }

  public getAccounts(): Observable<any> {
    const query = `
      query GetAccounts {
        accounts {
        code
        name
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetAccounts').pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getLocations(): Observable<any> {
    const query = `
      query GetLocations {
        locations {
        code
        name
      }
      }
    `;

    return this.appSyncClient.call(query, 'GetLocations').pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getRoles(): Observable<any> {
    const query = `
      query GetRoles {
        roles {
          role
          name
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetRoles').pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public createLearningTemplate(params): Observable<any> {

    const query = `
      mutation UpsertLearningTemplate($input: CreateUserTemplateInput!) {
        createUserTemplate(input: $input){
          code
          success
        }
      }
    `;

    return this.appSyncClient.call(query, 'UpsertLearningTemplate', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public updateLearningTemplate(params): Observable<any> {
    const query = `
      mutation UpdateLearningTemplate($input: UpdateUserTemplateInput!) {
        updateUserTemplate(input: $input){
          code
          success
          publishedAt
          status
          updatedAt
          draftWaiting,
          statusCode
        }
      }
    `;

    return this.appSyncClient.call(query, 'UpdateLearningTemplate', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public createLearningItem(params): Observable<any> {
    const query = `
     mutation CreateLearningItem($input: CreateLearningItemInput!) {
       createLearningItem(input: $input){
        code
        }
      }`;

    return this.appSyncClient.call(query, 'CreateLearningItem', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public upsertLearningItem(params): Observable<any> {
    const query = `
     mutation UpsertLearningItem($input: UpsertLearningItemInput!) {
       upsertLearningItem(input: $input){
        code
        }
      }`;

    return this.appSyncClient.call(query, 'UpsertLearningItem', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public updateLearningItemActivation(params): Observable<any> {
    const query = `
     mutation UpdateLearningItemActivation($input: LearningItemActivationInput!) {
      updateLearningItemActivation(input: $input)
      }`;

    return this.appSyncClient.call(query, 'UpdateLearningItemActivation', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public searchLearningItems(params): Observable<SearchLearningItems> {
    const query = `
      query SearchLearningItems($input: SearchLearningItemsInput!) {
      searchLearningItems(input: $input) {
        items {
          activated
          code
          title
          typeID
          typeName
          domainID
          description
          resourceURL
          duration
          location
          technology
          productIDs
          customProductIDs
          accountIDs
          updatedAt
          updatedByUser
          createdAt
          createdByUser
        }
        found
        statusCode
      }
     }
    `;
    // Note: roleIDs removed by 3081

    return this.appSyncClient.call(query, 'SearchLearningItems', params).pipe(
      mergeMap((response): Observable<SearchLearningItems> => {
        return of(response.searchLearningItems);
      }),
      catchError(err => throwError(err))
    );
  }

  public getOrg2Units(unitCode = ''): Observable<OrgUnit[]> {
    const params = { unitCode };
    const query = `
    query GetOrg2Units($unitCode: String) {
        org2Units(unitCode: $unitCode) {
          code
          name
          parentUnitCode
          subUnits {
              code
              name
          }
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetOrg2Units', params).pipe(
      mergeMap((response: { org2Units: OrgUnit[] }): Observable<OrgUnit[]> => {
        return of(response.org2Units);
      }),
      catchError(err => throwError(err))
    );
  }

  public getOrg3Units(unitCode = ''): Observable<OrgUnit[]> {
    const params = { unitCode };
    const query = `
    query GetOrg3Units($unitCode: String) {
        org3Units(unitCode: $unitCode) {
          code
          name
          subUnits {
              code
              name
          }
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetOrg3Units', params).pipe(
      mergeMap((response: { org3Units: OrgUnit[] }): Observable<OrgUnit[]> => {
        return of(response.org3Units);
      }),
      catchError(err => throwError(err))
    );
  }

  public getOrg4Units(unitCode = ''): Observable<OrgUnit[]> {
    const params = { unitCode };
    const query = `
    query GetOrg4Units($unitCode: String) {
        org4Units(unitCode: $unitCode) {
          code
          name
          parentUnitCode
          subUnits {
              code
              name
          }
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetOrg4Units', params).pipe(
      mergeMap((response: { org4Units: OrgUnit[] }): Observable<OrgUnit[]> => {
        return of(response.org4Units);
      }),
      catchError(err => throwError(err))
    );
  }

  public searchCourses(params): Observable<LearningItem[]> {
    const query = `
    query SearchCourses($qf: String!) {
        searchCourses(qf: $qf) {
          title
          typeID
          domainID
          description
          URL
          duration
        }
      }
    `;

    return this.appSyncClient.call(query, 'SearchCourses', params).pipe(
      mergeMap((response): Observable<LearningItem[]> => {
        return of(response.searchCourses);
      }),
      catchError(err => throwError(err))
    );
  }


  public getLearningDomains(): Observable<any> {
    const query = `
     query GetLearningDomains {
        learningDomains {
          code
          name
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetLearningDomains', {}).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public unlockDraftTemplate(params): Observable<any> {
    const query = `
    mutation UnlockDraftTemplate($code: String!) {
      unlockDraftTemplate(code: $code){
          code
          success
          statusCode
      }
    }
    `;

    return this.appSyncClient.call(query, 'UnlockDraftTemplate', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getLearningItemsByURL(params): Observable<any> {
    const query = `
     query GetLearningItemsByURL($resourceURL: String!) {
        learningItemsByURL(resourceURL: $resourceURL) {
          activated
          code
          title
          typeID
          typeName
          domainID
          description
          resourceURL
          duration
          location
          productIDs
          accountIDs
          customProductIDs
          technology
          expectedTime
          creationDate
          updatedAt
          updatedByUser
          createdAt
          createdByUser
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetLearningItemsByURL', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public examineURLForNewItem(params): Observable<any> {
    const query = `
      query ExamineURLForNewItem($resourceURL: String!) {
        examineURLForNewItem(resourceURL: $resourceURL) {
          canCreate
          learningItems {
            activated
            code
            title
            typeID
            typeName
            domainID
            description
            resourceURL
            duration
            location
            roleIDs
            productIDs
            customProductIDs
            accountIDs
            expectedTime
            technology
            durationType
            creationDate
            createdByUser
            updatedByUser
            createdAt
            updatedAt
          }
        }
      }`;

    return this.appSyncClient.call(query, 'ExamineURLForNewItem', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getLearningTypes(): Observable<any> {
    const query = `
     query GetLearningTypes {
        learningTypes {
          code
          name
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetLearningTypes', {}).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getUserLearningTemplates(params): Observable<any> {
    const query = `
      query GetUserLearningTemplates {
        userTemplates {
          templates {
              code
              title
              empSeniorityID
              locationIDs
              roleIDs
              productIDs
              customProductIDs
              accountIDs
              org2ID
              org3ID
              org4ID
              org5ID
              expectedTime
              itemsCount
              itemsDuration
              draftWaiting
              status
              prerequisites
              description
              editedBy
              editedAt
              isLocked
              createdBy
              createdAt
              publishedBy
              publishedAt
          }
          success
          statusCode
        }
      }
    `;

    return this.appSyncClient.call(query, 'GetUserLearningTemplates', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    )
  }
  public getLearningTemplates(params): Observable<any> {
    const query = `
      query TemplatesLibrary($input: TemplatesLibraryInput!) {
        templatesLibrary(input: $input) {
          templates {
              title
              code
              empSeniority
              locations
              roles
              customProducts
              products
              accounts
              expectedTime
              org2
              org3
              org4
              org5
              itemsCount
              itemsDuration
              prerequisites
              description
              status
              version
              draftWaiting
              editedBy
              createdBy
              createdById
              publishedBy
              isLocked
              editedAt
              createdAt
              publishedAt
          }
          statusCode,
          found
        }
      }
    `;

    return this.appSyncClient.call(query, 'TemplatesLibrary', params).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    )
  }

  public getLearningTemplate(code, editView = false): Observable<any> {
    const query = `
    query GetLearningTemplate($code: String, $editView: Boolean) {
      learningTemplate(code: $code, editView: $editView) {
        code
        statusCode
        isLocked
        draft {
          templatePlanContent {
            name
            order
            items {
              activated
              code
              title
              typeID
              typeName
              domainID
              description
              resourceURL
              duration
              location
              technology
              productIDs
              customProductIDs
              accountIDs
              updatedAt
              updatedByUser
              createdAt
              createdByUser
            }
          }
        templatePlanDetails {
            title
            empSeniorityID
            locationIDs
            roleIDs
            productIDs
            customProductIDs
            accountIDs
            expectedTime
            itemsCount
            itemsDuration
            org2ID
            org3ID
            org4ID
            org5ID
            description
            prerequisites
          }
        templatePlanMetadata {
            latest
            status
            draftWaiting
            isForceUnlocked
            createdAt
            updatedAt
            publishedAt
            createdBy
            createdById
            lastEditedById
            lastEditedBy
            publishedById
            publishedBy
          }
        }
      latest {
          templatePlanContent {
            name
            order
            items {
              activated
              code
              title
              typeID
              domainID
              description
              resourceURL
              duration
              location
              technology
              productIDs
              customProductIDs
              accountIDs
              updatedAt
              updatedByUser
              createdAt
              createdByUser
            }
          }
        templatePlanDetails {
            title
            empSeniorityID
            locationIDs
            roleIDs
            productIDs
            customProductIDs
            accountIDs
            expectedTime
            itemsCount
            itemsDuration
            org2ID
            org3ID
            org4ID
            org5ID
            description
            prerequisites
          }
        templatePlanMetadata {
            latest
            status
            draftWaiting
            isForceUnlocked
            createdAt
            updatedAt
            publishedAt
            createdBy
            createdById
            lastEditedById
            lastEditedBy
            publishedById
            publishedBy
          }
        }
      }
    }
    `;

    return this.appSyncClient.call(query, 'GetLearningTemplate', { code, editView }).pipe(
      mergeMap((response): Observable<any> => {
        return of(response.learningTemplate);
      }),
      catchError(err => throwError(err))
    );
  }

  public moveTemplateToDraft(code): Observable<any> {
    const query = `
      mutation MoveTemplateToDraft($code: String!) {
        moveToDraftTemplate(code: $code){
            code
            success
            publishedAt
            status
            updatedAt
            draftWaiting,
            statusCode
          }
        }
      `;

    return this.appSyncClient.call(query, 'MoveTemplateToDraft', { code }).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public publishLearningTemplate(code): Observable<any> {
    const query = `
      mutation PublishLearningTemplate($code: String!) {
        publishUserTemplate(code: $code){
          code
          success
          publishedAt
          status
          updatedAt
          draftWaiting,
          statusCode
        }
      }
    `;

    return this.appSyncClient.call(query, 'PublishLearningTemplate', { code }).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public deleteLearningTemplate(code): Observable<any> {
    const query = `
      mutation DeleteLearningTemplate($code: String!) {
        deleteUserTemplate(code: $code){
          code
          statusCode
        }
      }
      `;

    return this.appSyncClient.call(query, 'DeleteLearningTemplate', { code }).pipe(
      mergeMap((response): Observable<any> => {
        return of(response);
      }),
      catchError(err => throwError(err))
    );
  }

  public getLearningItemUsage(itemCode): Observable<any> {
    const query = `
        query LearningItemUsage($itemCode: String!){
          learningItemUsage(itemCode: $itemCode){
              itemCode
              templateIds
          }
      }
    `;

    return this.appSyncClient.call(query, 'LearningItemUsage', { itemCode }).pipe(
      mergeMap((response): Observable<any> => {
        return of(response.learningItemUsage);
      }),
      catchError(err => throwError(err))
    );
  }
  
  public exportPdf(code): Observable<any> {
    const query = `
    query TemplatePdfExport($code: String!) {
     templatePdfExport (code: $code) {
        data
        error
        }
      }
    `;

    return this.appSyncClient.call(query, 'TemplatePdfExport', { code }).pipe(
      mergeMap((response): Observable<any> => {
        return of(response.templatePdfExport);
      }),
      catchError(err => throwError(err))
    );
  }

  public GetTemplates(params): Observable<any> {
    const query = `
    query GetTemplates($input: TemplatesGetInput!) {
      getTemplates(input: $input) {
        statusCode
        templates {
          code
          latest {
            templatePlanContent {
              name
              order
              items {
                code
                title
                typeID
                typeName
                domainID
                productIDs
                accountIDs
                customProductIDs
                expectedTime
                creationDate
                updatedAt
                updatedByUser
                createdAt
                createdByUser
                activated
                description
                duration
                resourceURL
                location
                roleIDs
                technology
              }
            }
            templatePlanDetails {
              title
              empSeniorityID
              locationIDs
              roleIDs
              productIDs
              accountIDs
              expectedTime
              itemsCount
              itemsDuration
              org2ID
              org3ID
              org4ID
              org5ID
              description
              prerequisites
            }
            templatePlanMetadata {
              latest
              status
              draftWaiting
              isForceUnlocked
              createdAt
              updatedAt
              publishedAt
              createdBy
              createdById
              lastEditedById
              lastEditedBy
              publishedById
              publishedBy
            }
          }
        }
      }
    }
     `;
    return this.appSyncClient.call(query, "GetTemplates", params).pipe(
      mergeMap((response: any): Observable<any> => {
        return of(response.getTemplates);
      }),
      catchError((err) => throwError(err))
    );
  }
}
