<div class="message-popup-content">
  <div class="top text-center">
    <div class="title label-h4">{{title}}</div>
    <div class="message text-small-normal">{{message}}</div>
  </div>
  <div class="buttons-wrapper d-flex flex-column align-items-center justify-content-start">
    <button [ngClass]="{'with-margin': showHomeButton}"
            class="btn btn-tiny primary" (click)="reload()">{{reloadButtonText}}</button>
    <div role="button" *ngIf="showHomeButton" class="text-small-normal back-button" (click)="home()">{{homeButtonText}}</div>
  </div>
</div>
