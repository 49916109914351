import { Component, Input } from '@angular/core';

@Component({
  selector: 'amdocs-top-banner',
  templateUrl: './amdocs-top-banner.component.html',
  styleUrls: ['./amdocs-top-banner.component.scss']
})
export class AmdocsTopBannerComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() img: string;
  @Input() textParams: string;
  @Input() isSmallTextWidth = true;
  @Input() userName: string;
  @Input() topSmallText: boolean;
  @Input() customClass = "";
  @Input() showLogo = true;
  
  constructor() { }
}
