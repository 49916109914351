import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgSelectModule } from "@ng-select/ng-select";
import {
  AmdocsMimicComponent,
  AmdocsCheckboxComponent,
  AmdocsSwitcherComponent,
  AmdocsDropdownComponent,
  AmdocsPopupComponent,
  AmdocsAppErrorComponent,
  AmdocsTopHeaderComponent,
  AmdocsPopupMessageComponent,
  AmdocsAppLoaderComponent,
  AmdocsUserNotifyAlertComponent,
  AmdocsLandscapeViewComponent,
  AmdocsPwaIosNotificationComponent,
  AmdocsVersionUpdateComponent,
  AmdocsAutocompleteComponent,
  AmdocsTableComponent,
  AmdocsTableDrawerComponent,
  AmdocsTableHeaderComponent,
  AmdocsDatepickerComponent,
  AmdocsPopoverComponent,
  AmdocsTableCellComponent,
  AmdocsTableRowComponent,
  AmdocsToastrComponent,
  AmdocsAccordionComponent,
  AmdocsPopupDesktopWrapperComponentComponent,
  AmdocsUploadFileComponent,
  AmdocsPaginatorComponent,
  AmdocsButtonComponent,
  AmdocsLabeledCheckboxComponent,
  AmdocsInputFieldComponent,
  AmdocsRadioComponent,
  AmdocsLargeTextInputComponent,
  AmdocsTopBannerComponent,
} from "./components";
import {
  AmdocsClickOutsideDirective,
  AmdocsImageFallbackDirective,
  AmdocsImageVersionDirective,
} from "./directives";
import {
  AmdocsDatePipe,
  AmdocsDynamicPipe,
  AmdocsHighlightTextPipe,
  AmdocsInitialsPipe,
  AmdocsMinutesToHoursPipe,
  AmdocsSafeUrlPipe,
  AmdocsSearchPipe,
  AmdocsStrongSpanPipe,
  AmdocsTimeColonFormatPipe,
  AmdocsTranslatePipe,
  AmdocsTruncatePipe,
} from "./pipes";
import { AmdocsDateRangePipe } from "./pipes/amdocs-days-pipe/amdocs-days.pipe";
import { AmdocsActionPopupComponent } from "./components/amdocs-action-popup/amdocs-action-popup.component";
import { AmdocsListPaginatorComponent } from "./components/amdocs-list-paginator/amdocs-list-paginator.component";
import { AmdocsPageTitleComponent } from "./components/amdocs-page-title/amdocs-page-title.component";
import { AmdocsFooterComponent } from "./components/amdocs-footer/amdocs-footer.component";

const components: any[] = [
  AmdocsAppErrorComponent,
  AmdocsCheckboxComponent,
  AmdocsDropdownComponent,
  AmdocsRadioComponent,
  AmdocsMimicComponent,
  AmdocsPopupComponent,
  AmdocsSwitcherComponent,
  AmdocsTopHeaderComponent,
  AmdocsTopBannerComponent,
  AmdocsTranslatePipe,
  AmdocsTruncatePipe,
  AmdocsDynamicPipe,
  AmdocsTimeColonFormatPipe,
  AmdocsPopupMessageComponent,
  AmdocsInputFieldComponent,
  AmdocsLargeTextInputComponent,
  AmdocsAppLoaderComponent,
  AmdocsClickOutsideDirective,
  AmdocsImageFallbackDirective,
  AmdocsUserNotifyAlertComponent,
  AmdocsLandscapeViewComponent,
  AmdocsPwaIosNotificationComponent,
  AmdocsVersionUpdateComponent,
  AmdocsDatePipe,
  AmdocsSafeUrlPipe,
  AmdocsInitialsPipe,
  AmdocsStrongSpanPipe,
  AmdocsMinutesToHoursPipe,
  AmdocsAutocompleteComponent,
  AmdocsTableComponent,
  AmdocsTableHeaderComponent,
  AmdocsTableRowComponent,
  AmdocsTableCellComponent,
  AmdocsTableDrawerComponent,
  AmdocsPopoverComponent,
  AmdocsDatepickerComponent,
  AmdocsToastrComponent,
  AmdocsAccordionComponent,
  AmdocsPopupDesktopWrapperComponentComponent,
  AmdocsUploadFileComponent,
  AmdocsPaginatorComponent,
  AmdocsImageVersionDirective,
  AmdocsSearchPipe,
  AmdocsHighlightTextPipe,
  AmdocsButtonComponent,
  AmdocsLabeledCheckboxComponent,
  AmdocsDateRangePipe,
  AmdocsFooterComponent,
  AmdocsPageTitleComponent,
  AmdocsListPaginatorComponent,
  AmdocsActionPopupComponent,
];

@NgModule({
  imports: [
    BsDropdownModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  providers: [],
  declarations: [...components],
  exports: [...components],
})
export class AmdocsUiModule {}
