import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "lib-amdocs-labeled-checkbox",
  templateUrl: "./amdocs-labeled-checkbox.component.html",
  styleUrls: ["./amdocs-labeled-checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmdocsLabeledCheckboxComponent),
      multi: true,
    },
  ],
})
export class AmdocsLabeledCheckboxComponent {
  @Input() label: string;
  @Input() disabled = false;

  private innerValue: any;

  public onChange: (val: any) => void;
  public onTouched: () => void;

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }
}
