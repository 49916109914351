<a
  href="javascript:void(0)"
  #pop="bs-popover"
  [popover]="popoverContent"
  triggers="{{ trigger }}"
  [outsideClick]="outsideClick"
  placement="{{ placement }}"
  [containerClass]="placement == 'bottom right' ? 'bottomRight' : ''"
  [attr.aria-expanded]="isOpen"
>
  <ng-content select="[popoverOpener]"></ng-content>
</a>

<ng-template #popoverContent>
  <ng-content select="[popoverContent]"></ng-content>
</ng-template>
