import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { AmdocsTableCellComponent } from './amdocs-table-cell.component';

@Component({
    selector: 'lib-amdocs-table-row',
    templateUrl: './amdocs-table-row.component.html',
    styleUrls: ['./amdocs-table-row.component.scss']
})
export class AmdocsTableRowComponent implements AfterContentInit{
    @Input() cols: any[] = [];
    @Input() rowData: any[] = [];
    @Input() colsConfig: any[]  = [];
    @Input() rowDisabled = false;
    @Input() drawerDisabled = false;
    @ContentChildren(AmdocsTableCellComponent) cells: QueryList<AmdocsTableCellComponent>;
    @ViewChild('tableRow', { read: TemplateRef, static: true }) tableRow;
    @Output() rowClick: EventEmitter<any> = new EventEmitter();

    ngAfterContentInit(): void {
    }

    onRowClick() {
        this.rowClick.emit(this.rowData);
    }
}
