import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AmdocsCookieService } from '../amdocs-cookie/amdocs-cookie.service';
import { AmdocsApiUtilsService } from '../amdocs-api/amdocs-api-utils.service';
import { ApiError, IAppSyncConfig } from '../amdocs-models';
import { AMDOCS_CONSTANTS } from '../amdocs-constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { AmdocsEventBusService } from '../amdocs-event-bus/amdocs-event-bus.service';

/**
 * Amdocs Appsync Service
 * In App Module add to provider
 *
 * @example
 * const appSyncConfig: IAppSyncConfig = {
 *  url: 'the url',
 *  region: 'the region',
 * }
 * add to app module
 * {
 *   provide: 'IAppSyncConfig',
 *   useValue: appSyncConfig
 * }
 *
 */

@Injectable({
  providedIn: 'root'
})
export class AmdocsAppsyncClientService {

  private config: IAppSyncConfig;
  constructor(private amdocsCookieService: AmdocsCookieService,
    private amdocsApiService: AmdocsApiUtilsService,
    private httpClient: HttpClient,
    private eventBus: AmdocsEventBusService,
    @Inject('IAppSyncConfig') appSyncConfig: IAppSyncConfig
  ) {
    this.config = appSyncConfig;
  }

  public getConfig(): IAppSyncConfig {
    return this.config;
  }

  /**
   * get id_token
   */
  private getToken(): string {
    return this.amdocsCookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY);
  }

  public callCNS(query: string, operationName: string, variables: any = null, ignoreStatus200Errors: boolean = false): Observable<any> {
    return this.call(query, operationName, variables, ignoreStatus200Errors, true);
  }

  /**
   * @param query
   * @param operationName
   * @param variables
   * @param ignoreStatus200Errors
   * @param cnsCall
   * @param firstCall
   */
  public call(query: string, operationName: string, variables: any = null, ignoreStatus200Errors: boolean = false, cnsCall: boolean = false, firstCall: boolean = true): Observable<any> {

    const payload = {
      operationName: operationName,
      variables: variables,
      query: query
    };
    let url = cnsCall ? this.config.cnsUrl : this.config.url;
    url += `?operationName=${operationName}`;
    return this.httpClient.post(
      url,
      payload,
      {
        headers: {
          authorization: this.getToken(),
          cache: 'no-store'
        }
      }
    ).pipe(
      mergeMap((response: any) => {
        if (response?.errors) {
          if (ignoreStatus200Errors) {
            return of(response.data);
          }
          return throwError({
            status: 200,
            error: {
              errors: response.errors
            }
          });
        }
        return of(response.data);
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          return throwError(new ApiError(error.status, error.statusText, 'http'));
        }
        if (error.status) {
          const errorStr = error.error.errors.map(e => e.message).join(', ');
          const errorTypeStr = error.error.errors.map(e => e.errorType).join(', ');
          return throwError(new ApiError(error.status, errorStr, errorTypeStr));
        }
        return throwError(new ApiError(error.status, error.toString(), 'unknown'));
      }),
      catchError((error: ApiError) => {
        if (error.code === 0 && firstCall) {
          return this.call(query, operationName, variables, ignoreStatus200Errors, cnsCall, false);
        }
        this.eventBus.emit(AMDOCS_CONSTANTS.API_ERROR_EVENT, error);
        return throwError(error);
      }),
    );
  }
}


