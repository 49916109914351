import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-amdocs-popup-desktop-wrapper-component',
  templateUrl: './amdocs-popup-desktop-wrapper-component.component.html',
  styleUrls: ['./amdocs-popup-desktop-wrapper-component.component.scss']
})
export class AmdocsPopupDesktopWrapperComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
