import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { LearningItem, LearningPlanType } from '../../../models/learningPlan'
import { UtilsService } from '../../../core/utils.service'
import { CONSTANTS } from '../../../constants';
import { UntypedFormControl } from '@angular/forms'
import { ApiService } from '../../../core/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AmdocsEventBusService, AmdocsTranslateService } from 'projects/amdocs-core-package/src/public-api';
import { IncludedItemsPopupComponent } from '../included-items-popup/included-items-popup.component';
import { IncludedPlanItemsService } from '../../services/includedPlanItems.service';

@Component({
  selector: 'app-learning-plan-item',
  templateUrl: './learning-plan-item.component.html',
  styleUrls: ['./learning-plan-item.component.scss'],
})
export class LearningPlanItemComponent implements OnInit, OnChanges {

  @Input() item: LearningItem;
  @Input() learningPlanType: number;
  @Input() isChecked = false;
  @Input() selectable = true;
  @Input() editSelectedPlan: boolean;
  @Input() isPreviewMode: boolean = false;
  @Input() learningTypesList = [];
  @Input() isCopyPartOfPlan;
  @Output() removeCallback: EventEmitter<any> = new EventEmitter();
  @Output() restoreItemCallback: EventEmitter<any> = new EventEmitter();
  @Output() checkCallback: EventEmitter<any> = new EventEmitter();
  @Output() updateItemAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateItemActivieStateAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() editItemCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() editInLibraryCallback: EventEmitter<LearningItem> = new EventEmitter();

  public LearningPlanType = LearningPlanType;
  public checkboxCtrl: UntypedFormControl;
  public editMode = false;
  public bsModalRef?: BsModalRef;
  public includedItemsIds?: any;
  public selectedItems: any = [];

  constructor(
    private utils: UtilsService,
    private apiService: ApiService,
    private modalService: BsModalService,
    private includedPlanItemsService: IncludedPlanItemsService,
    private translateService: AmdocsTranslateService,
    private eventBus: AmdocsEventBusService
    
    ) {
  }

  ngOnInit(): void {
    this.item = this.utils.getValuesOfItem(this.item);
    this.item.moreDetailsFlag = false;

    if (this.showAddItem) {
      this.initCheckbox();
    }

    if (this.editSelectedPlan) {
      this.editMode = true;
    }
  }

  initCheckbox(): void {
    this.checkboxCtrl = new UntypedFormControl()

    this.checkboxCtrl.setValue(this.isChecked)

    this.checkboxCtrl.valueChanges.subscribe((value) => {
      if (this.checkCallback.observers.length) {
        this.checkCallback.emit({item: this.item, value});
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showAddItem && this.checkboxCtrl && changes.isChecked !== undefined) {
      this.checkboxCtrl.setValue(this.isChecked)
    }
  }

  // ToDo: LearningPlanType is outdated design legacy and needs to be substituted with better logic.

  get imageView(): boolean {
    return this.learningPlanType === LearningPlanType.Create;
  }

  get noImage(): boolean {
    return this.learningPlanType === LearningPlanType.CreateLearningItem || this.learningPlanType === LearningPlanType.EditLearningItem;
  }

  get noActions(): boolean {
    return this.learningPlanType === LearningPlanType.CreateLearningItem
    || this.learningPlanType === LearningPlanType.EditLearningItem
    || this.learningPlanType === LearningPlanType.SelectionListItem
  }

  get compactTitle(): boolean {
    return this.learningPlanType === LearningPlanType.SelectionListItem
  }

  get showRemoveItem(): boolean {
    return this.learningPlanType === LearningPlanType.Create;
  }

  get showAddItem(): boolean {
    return this.selectable && (
      this.learningPlanType === LearningPlanType.Library
      || this.learningPlanType === LearningPlanType.LibrarySync
      || this.learningPlanType === LearningPlanType.CreateLearningItem
    );
  }

  get showOptimmisticSync(): boolean {
    return this.learningPlanType === LearningPlanType.LibrarySync;
  }

  get showHandle(): boolean {
    return this.learningPlanType === LearningPlanType.Create;
  }

  removeItem(item, forceRemove: boolean): void {
    item.removed = true;
    this.removeCallback.emit({ item, forceRemove });
  }

  restoreItem(item): void {
    item.removed = false;
    this.restoreItemCallback.emit(item);
  }

  updateItem($event): void {
    this.finishEditing($event)
    if (this.updateItemAction.observers.length) {
      this.updateItemAction.emit($event);
    }
  }

  getItemImage(item): string {
    switch (item.typeID) {
      case '12590c3e-be8c-473c-8b5b-2779f82f38f1': {
        return '/assets/icons/learning-plan-images/Webpage.png';
      }
      case '1803a9b9-7db7-4def-a42c-60c967e42836': {
        return '/assets/icons/learning-plan-images/LiveSession.png';
      }
      case '6966d560-aca7-4904-af2a-755873d5f08e': {
        return '/assets/icons/learning-plan-images/Survey.png';
      }
      case '82e6197f-4561-482b-8042-094c93003784': {
        return '/assets/icons/learning-plan-images/Document.png';
      }
      case '9b444c7f-961c-4976-823c-a42b03fb744b': {
        return '/assets/icons/learning-plan-images/Video.png';
      }
      case 'a638f7ee-4f7a-4931-91e3-99b24c19f9ae': {
        return '/assets/icons/learning-plan-images/LearningPath-Course.png';
      }
      case 'b8cc459f-b903-40cf-a9e1-0582e91ddf71': {
        return '/assets/icons/learning-plan-images/Meeting.png';
      }
      case 'bbcc8c8b-71f5-4a3a-a14c-fc436a60123f': {
        return '/assets/icons/learning-plan-images/Lab-Exercise-Practice-Environment.png';
      }
      case 'c5fd3f87-5627-4e0a-a5e7-0b9e00948484': {
        return '/assets/icons/learning-plan-images/WorkTask.png';
      }
      case 'e1e50a49-619b-4d13-a3f9-af5968948c88': {
        return '/assets/icons/learning-plan-images/Assessment.png';
      }
      case 'e2c89b40-ccee-4264-bb69-4a16c9aa7854': {
        return '/assets/icons/learning-plan-images/Courseware(eLearning).png';
      }
      default: {
        return '/assets/icons/learning-plan-images/Assessment.png';
      }
    }
  }

  public editDetails(): void {
    this.editMode = true
  }

  public finishEditing($event?): void {
    if ($event) {
      this.item = $event.input;
      this.item = this.utils.getValuesOfItem(this.item);
      this.editItemCallback.emit(this.item);
    }

    this.editItemCallback.emit();
    this.editMode = false;
  }

  public deactivate(): void {
    this.itemActiveSwitch(this.item.code, false)
  }

  public reactivate(): void {
    this.itemActiveSwitch(this.item.code, true)
  }

  public toggleMoreDetailsFlag(item: any): void {
    item.moreDetailsFlag = !item.moreDetailsFlag;

    if (item.moreDetailsFlag) {
      this.apiService.getLearningItemUsage(item.code).subscribe((res: any) => {
        item.usageCount = res.templateIds.length;
        this.includedItemsIds = res.templateIds;
      });
    }
  }

  private itemActiveSwitch(itemCode: string, activated: boolean): void {
    this.item.activated = activated
    const payload = {
      input: {
        itemCode,
        activated,
      }
    }
    if (this.updateItemActivieStateAction.observers.length) {
      this.updateItemActivieStateAction.emit(payload);
    }
  }

  public getLearningTypeName(code: string): string {
    return this.learningTypesList.find(type => type.code === code)?.name
  }

  public editInLibrary(item: LearningItem): void {
    this.editInLibraryCallback.emit(item);
  }


  public openIncludedItemsModal(): void {
    this.bsModalRef = this.modalService.show(IncludedItemsPopupComponent, {
      initialState: {
        includedItemsIds: this.includedItemsIds
      }
    });

    this.bsModalRef.content.title = 'Item included in plans';
    this.bsModalRef.content.closeBtnName = this.translateService.getText('selectedItemsPopup.closeBtnName');
  }
}
