import { Component, Input, OnInit } from '@angular/core';
import { AmdocsAppInitService } from '../../../amdocs-utils/amdocs-app-init/amdocs-app-init.service';

@Component({
  selector: 'lib-amdocs-app-error',
  templateUrl: './amdocs-app-error.component.html',
  styleUrls: ['./amdocs-app-error.component.scss']
})
export class AmdocsAppErrorComponent implements OnInit {

  @Input() errorMessage: string;
  public msg: string;

  constructor(private appInitService: AmdocsAppInitService) { }
  ngOnInit(): void {
    if (this.errorMessage) {
      this.msg = this.errorMessage;
    } else if (this.appInitService.appInitError) {
      this.msg = this.appInitService.appInitError.message;
    } else {
      this.msg = "Something went wrong";
    }
  }

  public reload(): void {
    window.location.reload();
  }
}
