import { Inject, Injectable } from '@angular/core';
import { AmdocsApiUtilsService } from '../amdocs-api/amdocs-api-utils.service';
import { from } from 'rxjs';
import { IApiGatewayClient, IAppGatewayConfig, IGatewayConfig, ILoginGatewayConfig } from '../amdocs-models';
import { AmdocsCookieService } from '../amdocs-cookie/amdocs-cookie.service';
import { AMDOCS_CONSTANTS } from '../amdocs-constants';

declare var apiGateway: any;
declare var uritemplate: any;

@Injectable({
  providedIn: 'root'
})
export class AmdocsGatewayClientService {

  private appGatewayConfig: IAppGatewayConfig;
  private loginGatewayConfig: ILoginGatewayConfig;

  constructor(
    @Inject('IAppGatewayConfig') appGatewayConfig: IAppGatewayConfig,
    @Inject('ILoginGatewayConfig') loginGatewayConfig: ILoginGatewayConfig,
    private amdocsApiService: AmdocsApiUtilsService,
    private amdocsCookieService: AmdocsCookieService
  ) {
    this.appGatewayConfig = appGatewayConfig;
    this.loginGatewayConfig = loginGatewayConfig;
  }

  public getAppApiGatewayClient(useAws4AuthType: boolean = false): IApiGatewayClient {
    return this.getApiGwClient(this.appGatewayConfig, useAws4AuthType);
  }

  public getLoginApiGatewayClient(useAws4AuthType: boolean = false): IApiGatewayClient {
    return this.getApiGwClient(this.loginGatewayConfig, useAws4AuthType);
  }

  private getApiGwClient(gwConfig: IGatewayConfig, useAws4AuthType = false, withCredentials: boolean = true): IApiGatewayClient {
    const config: any = {
      invokeUrl: gwConfig.url,
      region: gwConfig.region,
      accessKey: '',
      secretKey: '',
      sessionToken: '',
      apiKey: '',
      defaultContentType: 'application/json',
      defaultAcceptType: 'application/json',
      appid: gwConfig.appid
    };
    if (withCredentials) {
      config.accessKey = decodeURIComponent(this.amdocsCookieService.get(AMDOCS_CONSTANTS.ACCESS_KEY_ID));
      config.secretKey = decodeURIComponent(this.amdocsCookieService.get(AMDOCS_CONSTANTS.SECRET_ACCESS_KEY));
      config.sessionToken = decodeURIComponent(this.amdocsCookieService.get(AMDOCS_CONSTANTS.SESSION_TOKEN));
    }


    const apigClient: IApiGatewayClient = {};

    const invokeUrl = config.invokeUrl;
    const part = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl);
    const endpoint = part ? part[1] : '';
    const pathComponent = invokeUrl.substring(endpoint.length);

    const sigV4ClientConfig = {
      accessKey: config.accessKey,
      secretKey: config.secretKey,
      sessionToken: config.sessionToken,
      serviceName: 'execute-api',
      region: config.region,
      endpoint: endpoint,
      defaultContentType: config.defaultContentType,
      defaultAcceptType: config.defaultAcceptType
    };

    let authType = 'NONE'; // for login gateway
    if (useAws4AuthType && (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '')) {
      authType = 'AWS_IAM'; // For application gateway
    }

    const simpleHttpClientConfig = {
      endpoint: endpoint,
      defaultContentType: config.defaultContentType,
      defaultAcceptType: config.defaultAcceptType
    };

    const apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);

    apigClient.call = (url, method, params: any = {}, body = {}, additionalParams: any = {}) => {
      if (!params) { params = {}; }
      apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
      params.appid = config.appid;
      const reqHeaders = apiGateway.core.utils.parseParametersToObject(params, []);

      if (this.amdocsCookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY)) {
        reqHeaders['x-id-token'] = this.amdocsCookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY);
      }

      const request = {
        verb: method.toUpperCase(),
        path: pathComponent + uritemplate(url).expand(apiGateway.core.utils.parseParametersToObject(params, Object.keys(params))),
        headers: reqHeaders,
        queryParams: apiGateway.core.utils.parseParametersToObject(params, Object.keys(params)),
        body: body
      };

      return this.amdocsApiService.handleGatewayResponse(
        from(apiGatewayClient.makeRequest(request, authType, additionalParams, config.apiKey))
      );
    };

    return apigClient;
  }
}
