<div
  class="card-body template d-flex align-items-center justify-content-between"
>
  <div class="template-left d-flex flex-column">
    <div class="d-flex mb-1" [ngClass]="{'preview': isPreviewMode}" (click)="showPreview(template.code)">
      <div class="template-left-name" *ngIf="template.title.length < 40 || !truncateRequired">
        {{ template.title }}
      </div>
      <lib-amdocs-popover *ngIf="template.title.length > 40 && truncateRequired" [trigger]="'hover'" [placement]="'right'" class="ml-1 name-popup">
        <div popoverOpener class="template-left-name">
          {{ template.title | amdocsTruncate : 40 : false }}
        </div>
        <div popoverContent>
          {{ template.title }}
        </div>
      </lib-amdocs-popover>
    </div>

    <div class="template-left-info d-flex flex-row align-items-center">
      <div class="d-flex">
        <img lib-amdocs-image-version src="/assets/icons/course-time.svg" />
        <div class="details-text" *ngIf="template.itemsDuration !== null">
          {{ template.itemsDuration | amdocsMinutesToHours }}
        </div>
        <div class="details-text" *ngIf="template.itemsDuration === null || template.itemsDuration === undefined">
          {{ "templateLearning.noData" | amdocsTranslate }}
        </div>
      </div>
      <div class="d-flex">
        <img lib-amdocs-image-version src="/assets/icons/course-clock.svg" />
        <div class="details-text" *ngIf="template.itemsCount">
          <ng-container *ngIf="template.itemsCount === 1">
            {{
              "templateLearning.item" | amdocsTranslate : [template.itemsCount]
             }}
          </ng-container>
          <ng-container *ngIf="template.itemsCount > 1">
            {{
              "templateLearning.items" | amdocsTranslate : [template.itemsCount]
            }}
          </ng-container>
        </div>
        <div class="details-text" *ngIf="!template.itemsCount">
          {{ "templateLearning.noData" | amdocsTranslate }}
        </div>
      </div>
      <lib-amdocs-popover
        [trigger]="'hover'"
        [placement]="'right'"
        class="ml-1 plan-details"
        (mouseenter)="showPlanDetails(planInfo)"
        *ngIf="showPlanDetailsTooltip"
      >
        <div popoverOpener>
          {{ "templateLearning.moreDetails" | amdocsTranslate }}
        </div>
        <div popoverContent>
          <div class="plan-details-wrapper d-flex flex-wrap">
            <div class="plan-item-zone d-flex flex-column w-100">
              <div class="plan-info-line d-flex flex-column mb-2" *ngIf="planInfo?.description">
                <span class="plan-label">{{
                  "templateLearning.descriptionLabel" | amdocsTranslate
                }}</span>
                <span class="plan-value">{{ planInfo.description }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.prerequisites">
                <span class="plan-label">{{
                  "templateLearning.prerequisites" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{
                  planInfo.prerequisites
                }}</span>
              </div>
            </div>
            <div class="plan-item-zone two-column">
              <div class="plan-info-line mb-2" *ngIf="planInfo?.expectedTime">
                <span class="plan-label">{{
                  "templateLearning.expectedToFinish" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">
                  {{
                    "templateLearning.expectedToFinishMonth"
                      | amdocsTranslate : [planInfo.expectedTime]
                  }}
                </span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.seniority">
                <span class="plan-label">{{
                  "templateLearning.seniority" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo.seniority }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.role">
                <span class="plan-label">{{
                  "templateLearning.role" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo?.role }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.product">
                <span class="plan-label">{{
                  "templateLearning.product" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo?.product }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.locationLabel">
                <span class="plan-label">{{
                  "templateLearning.location" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{
                  planInfo.locationLabel
                }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.account">
                <span class="plan-label">{{
                  "templateLearning.account" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo.account }}</span>
              </div>

            </div>
            <div class="plan-item-zone two-column">
              <div class="plan-info-line mb-2" *ngIf="planInfo?.org2">
                <span class="plan-label">{{
                  "templateLearning.org2" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo.org2 }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.org3">
                <span class="plan-label">{{
                  "templateLearning.org3" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo?.org3 }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.org4">
                <span class="plan-label">{{
                  "templateLearning.org4" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo?.org4 }}</span>
              </div>
              <div class="plan-info-line mb-2" *ngIf="planInfo?.org5">
                <span class="plan-label">{{
                  "templateLearning.org5" | amdocsTranslate
                }}</span>
                <span class="ml-1 plan-value">{{ planInfo.org5 }}</span>
              </div>
            </div>
          </div>
        </div>
      </lib-amdocs-popover>
    </div>
  </div>
  <div
    *ngIf="showAdditionalBlock"
    class="template-right d-flex flex-row justify-content-between align-items-center"
  >
    <div class="template-right-createdBy">
      {{ template.createdBy }}
    </div>
    <div class="template-right-editDate d-flex flex-column">
      <span>{{ template.editedAt | date : "d MMM yyyy | H:mm" }}</span>
      <span class="details-text m-0">{{ template.editedBy }}</span>
    </div>
    <div
      class="template-right-status d-flex flex-row row-wrap align-items-center"
    >
      <div
        *ngIf="
          LearningTemplateStatus.Published === template.status &&
          !template.draftWaiting
        "
        class="published d-flex align-items-center"
      >
        <img
          lib-amdocs-image-version
          src="/assets/icons/published-plan-status.svg"
        />
        <span class="ml-2">
          {{ "templateLearning.publishedStatusLabel" | amdocsTranslate }}
        </span>
      </div>

      <div
        *ngIf="LearningTemplateStatus.Draft === template.status"
        class="d-flex align-items-center"
      >
        <img
          lib-amdocs-image-version
          src="/assets/icons/draft-plan-status.svg"
        />
        <span class="ml-2">
          {{ "templateLearning.draftStatusLabel" | amdocsTranslate }}
        </span>
      </div>

      <div
        *ngIf="
          LearningTemplateStatus.Published === template.status &&
          template.draftWaiting
        "
        class="d-flex align-items-center draft-waiting"
      >
        <img
          lib-amdocs-image-version
          src="/assets/icons/publish-d-plan-status.svg"
        />
        <span class="ml-2">
          {{ "templateLearning.publishedStatusLabel" | amdocsTranslate }}
          <span class="draft-waiting-label">
            {{ "templateLearning.draftWaiting" | amdocsTranslate }}</span
          >
        </span>
      </div>
    </div>

    <div class="template-right-additional d-flex">
      <lib-amdocs-popover
        #popOver
        [trigger]="'click'"
        [placement]="'bottom right'"
        class="ml-1"
      >
        <div popoverOpener>
          <img
            lib-amdocs-image-version
            class="dots"
            src="/assets/icons/triple-dots.svg"
          />
        </div>
        <div popoverContent>
          <div class="name-wrapper label-h3">
            <a
              class="action d-flex align-items-center"
              (click)="editPlan(template)"
            >
              <img
                class="mr-3"
                lib-amdocs-image-version
                src="/assets/icons/edit.svg"
                [alt]="'templateLearning.editPlan' | amdocsTranslate"
              />
              <span>{{ "templateLearning.editPlan" | amdocsTranslate }}</span>
            </a>
            <a
              class="action d-flex align-items-center"
              [routerLink]="['/learning-plan/copy/', template.code]"
              (click)="hidePopOver()"
            >
              <img
                class="mr-3"
                lib-amdocs-image-version
                src="/assets/icons/file-copy.svg"
                [alt]="'templateLearning.makeCopy' | amdocsTranslate"
              />
              <span>{{ "templateLearning.makeCopy" | amdocsTranslate }}</span>
            </a>
            <a
              class="action d-flex align-items-center"
              *ngIf="template.status !== LearningTemplateStatus.Draft"
              (click)="switchToDraft(template.code)"
            >
              <img
                class="mr-3"
                lib-amdocs-image-version
                src="/assets/icons/draft.svg"
                [alt]="'templateLearning.switchToDraft' | amdocsTranslate"
              />
              <span>{{
                "templateLearning.switchToDraft" | amdocsTranslate
              }}</span>
            </a>
            <a
              class="action d-flex align-items-center"
              *ngIf="
                template.status !== LearningTemplateStatus.Published ||
                (template.status === LearningTemplateStatus.Published &&
                  template.draftWaiting)
              "
              (click)="publishModalConfirm(template)"
            >
              <img
                class="mr-3"
                lib-amdocs-image-version
                src="/assets/icons/publish.svg"
                [alt]="'templateLearning.publishPlan' | amdocsTranslate"
              />
              <span>{{
                "templateLearning.publishPlan" | amdocsTranslate
              }}</span>
            </a>
            
            <a
              class="action d-flex align-items-center"
              (click)="exportPdf(template.code, template.title)"
            >
              <img
                class="mr-3"
                lib-amdocs-image-version
                src="/assets/icons/export-pdf.svg"
                alt="Export to PDF"
              />
              <span>Export to PDF</span>
            </a>

            <a class="action d-flex align-items-center"
              [ngClass]="{
                disabled: template.status === LearningTemplateStatus.Published
              }"
              (click)="openDeleteModal(template)"
            >
              <img
                class="mr-3"
                lib-amdocs-image-version
                src="/assets/icons/delete.svg"
                [alt]="'templateLearning.deletePlan' | amdocsTranslate"
              />
              <span>{{ "templateLearning.deletePlan" | amdocsTranslate }}</span>
            </a>
          </div>
        </div>
      </lib-amdocs-popover>
    </div>
  </div>
  <button
    *ngIf="!showAdditionalBlock && isCopy"
    type="button"
    class="btn btn-medium tertiary"
    (click)="makeCopy(template.code)"
  >
  {{
    "templateLearning.makeCopyClear" | amdocsTranslate
  }}
  </button>
</div>

<lib-amdocs-popup
  *ngIf="showDeleteModal"
  [showCloseButton]="true"
  #deletePlanModal
  [customClass]="'delete-plan-modal'"
>
  <div class="mh-popup-header">
    <span class="label-h1" *ngIf="!isDeleted">{{
      "templateLearning.deletePlan" | amdocsTranslate
    }}</span>

    <span class="label-h1" *ngIf="isDeleted">{{
      "templateLearning.deletePlan" | amdocsTranslate
    }}</span>
  </div>

  <div class="modal-body d-flex flex-column justify-content-center">
    <div class="title" *ngIf="!isDeleted">
      {{
        "templateLearning.deletePlanTitle" | amdocsTranslate : [template.title]
      }}
    </div>
    <div class="title" *ngIf="isDeleted">
      {{
        "templateLearning.deletePlanTitleSuccess"
          | amdocsTranslate : [template.title]
      }}
    </div>

    <div class="mt-5 sub-title" *ngIf="!isDeleted">
      {{ "templateLearning.deletePlanModalSubtitle" | amdocsTranslate }}
    </div>

    <div class="mt-5" *ngIf="!isDeleted">
      <button
        type="button"
        class="btn btn-medium btn-outline mr-4"
        (click)="closeDeletePlanModal()"
      >
        {{ "templateLearning.modalCancel" | amdocsTranslate }}
      </button>
      <button
        type="button"
        class="btn btn-medium dark"
        (click)="deletePlan(template.code)"
      >
        {{ "templateLearning.modalConfirm" | amdocsTranslate }}
      </button>
    </div>

    <div class="mt-5" *ngIf="isDeleted">
      <button
        type="button"
        class="btn btn-medium btn-outline"
        (click)="closeDeletePlanModal()"
      >
        {{ "templateLearning.backToHome" | amdocsTranslate }}
      </button>
    </div>
  </div>
</lib-amdocs-popup>
