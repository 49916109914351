import { Component, EventEmitter, Input, OnInit, Output, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmdocsFunctionsService } from '../../amdocs-utils/amdocs-functions/amdocs-functions.service';
import { IFormControlErrors } from '../../amdocs-utils/amdocs-models';

@Component({
  template: ''
})
export abstract class AmdocsControlAbstract implements OnInit {
  /**
   * Form control - optional
   */
  @Input() control: UntypedFormControl;

  @Input() errorsMessages: IFormControlErrors;
  /**
   * Wrapper custom class - optional
   */
  @Input() customClass: string = '';

  @Input() getValueFormatted: Function;
  /**
   * Will be insert into the DOM element as id
   */
  @Input() elementId?: string;
  @Input() inputPipe: PipeTransform = null;
  @Input() label: string;
  @Input() placeHolder: string = '';
  @Input() readOnly: boolean = false;
  @Input() inputMaxLength: number = null;
  @Input() inputMode: string = 'text';
  @Input() textCenter: boolean = false;
  @Input() hideLabel: boolean = false;
  @Input() showValidationErrorText: boolean = true;
  @Output() onChangedCallback: EventEmitter<UntypedFormControl> = new EventEmitter();
  @Output() focusCallback: EventEmitter<any> = new EventEmitter();
  @Output() blurCallback: EventEmitter<any> = new EventEmitter();
  @Output() keyDownCallback: EventEmitter<any> = new EventEmitter();
  @Output() keyUpCallback: EventEmitter<any> = new EventEmitter();

  /**
   * Will change on blur
   */
  public handleErrorValidation: boolean = true;
  protected isFocused: boolean = false;

  /**
   * Instantiate a form control instance if not provided
   */
  constructor(protected amdocsFunctionsService: AmdocsFunctionsService) { }

  ngOnInit(): void {
    if (!this.control) {
      throw new Error('Attribute \'control\' is required');
    }
    // if (!this.elementId) {
    //   throw new Error('Attribute \'elementId\' is required');
    // }
  }

  /**
   * return true if the form control is disabled
   */
  public isDisabled(): boolean {
    return this.control ? this.control.disabled : null;
  }


  /**
   * emit an Output function on change
   */
  public onChange(): void {
    if (this.onChangedCallback.observers.length) {
      this.onChangedCallback.emit(this.control);
    }
  }

  /**
   * emit an Output function on change
   */
  public onFocus($event = null): void {
    this.isFocused = true;
    if (this.focusCallback.observers.length) {
      this.focusCallback.emit($event);
    }
  }

  /**
   * emit an Output function on change
   */
  public onBlur($event = null): void {
    this.isFocused = false;
    if (this.blurCallback.observers.length) {
      this.blurCallback.emit($event);
    }
  }

  public onKeyDown(event): void {
    if (this.keyDownCallback.observers.length) {
      this.keyDownCallback.emit(event);
    }
  }

  public onKeyUp(event): void {
    if (this.keyUpCallback.observers.length) {
      this.keyUpCallback.emit(event);
    }
  }

  public getErrorMsg(): string {
    return this.handleErrorValidation ? this.amdocsFunctionsService.getErrorMsg(this.control, this.errorsMessages) : null;
  }

  public isInvalid(): boolean {
    return this.control.invalid && this.control.dirty && !this.isFocused;
  }

  public getInputValue(): any {
    if (this.getValueFormatted) {
      return this.getValueFormatted(this.control.value);
    }
    return this.control.value + '$';
  }
}
