import { Pipe, PipeTransform } from '@angular/core';
import { AmdocsTranslateService } from '../../../amdocs-utils/amdocs-translate/amdocs-translate.service';

/**
 * example
 * {{'testTranslation' | amdocsTranslate:['p1', 'p2']}}
 */
@Pipe({
  name: 'amdocsTranslate'
})
export class AmdocsTranslatePipe implements PipeTransform {

  constructor(private translate: AmdocsTranslateService) {

  }

  transform(value: any, args?: any): any {
    return this.translate.getText(value, args);
  }

}
