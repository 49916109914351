import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {AMDOCS_CONSTANTS, AmdocsEventBusService} from 'projects/amdocs-core-package/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  public promptEvent: any;

  constructor(private swUpdate: SwUpdate, private eventBus: AmdocsEventBusService) {
    swUpdate.available.subscribe(event => {
      console.log('new version exists');
      this.eventBus.emit(AMDOCS_CONSTANTS.SHOW_NEW_VERSION_ALERT_EVENT);
    });

    /**
     * save the beforeinstall event in order to prompt it once the user clicked the add to device
     */
    window.addEventListener('beforeinstallprompt', event => {
      console.log('inside event beforeinstallprompt');
      this.promptEvent = event;
    });
    window.addEventListener('appinstalled', (event) => {
      console.log('app installed');
    });
  }
}
