import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmdocsEventBusService, AmdocsTranslateService } from 'projects/amdocs-core-package/src/public-api';
import { CopyPartOfPlanService } from './copy-part-of-plan.service';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CONSTANTS } from '../../../constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CopyPartSelectionsService } from '../../services/copy-part-selections-service';

enum EnumSortBy {
  Relevant = 'Relevant',
  Lexicographic = 'Lexicographic',
  LatestEdited = 'LatestEdited',
}


@Component({
  selector: 'app-copy-part-of-plan',
  templateUrl: './copy-part-of-plan.component.html',
  styleUrls: ['./copy-part-of-plan.component.scss']
})
export class CopyPartOfPlanComponent implements OnChanges {

  @Input() showCopyPartOfPlan: boolean;
  @Input() learningTypesList;

  @Output() closeCallback: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() callBackAddCopyPart = new EventEmitter();


  private startOffset: number = 0;

  public totalCountPartsIsChecked;
  public totalRows: number;
  public pageSize = 10;
  public pageNumber = 1;
  public sortBy: EnumSortBy = EnumSortBy.Relevant
  public learningTemplates = [];
  public isLoading: boolean = false;
  public createdOrUpdatedByYou = true
  public bsModalRef?: BsModalRef;
  public planTracking = (index, item) => item.code;

  public selectedPartsArray = [];
  public defaultResponse = [];

  public searchControl: UntypedFormControl;
  public sortControl: UntypedFormControl;
  public sortList = [
    { key: EnumSortBy.Relevant, value: this.translate.getText('library.sortBy.relevant') },
    { key: EnumSortBy.LatestEdited, value: this.translate.getText('library.sortBy.recently') },
    { key: EnumSortBy.Lexicographic, value: this.translate.getText('library.sortBy.lexicog') }
  ];

  private unsubscribe$ = new Subject();

  init(): void {
    this.sortControl = new UntypedFormControl(EnumSortBy.Relevant);
    this.searchControl = new UntypedFormControl();
    this.learningTemplates = [];

    this.searchControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(searchText => this.search(searchText, this.startOffset = 0, true));

    this.sortControl.valueChanges.subscribe((searchOrder) => {
      this.sortBy = searchOrder
      this.pageNumber = 1
      this.search()
    });
    this.search();
  }

  constructor(
    private translate: AmdocsTranslateService,
    private eventBus: AmdocsEventBusService,
    public copyPartService: CopyPartOfPlanService,
    private modalService: BsModalService,
    private copyPartSelection: CopyPartSelectionsService
  ) {
  }

  public selectedPartsCallBack($event) {
    //the logic of saving selected parts, with the corresponding plan
    const index = this.selectedPartsArray.findIndex(item => item.code === $event.currentPlanWithSelectedParts.code);

    if (index !== -1) {
      this.selectedPartsArray[index] = $event.currentPlanWithSelectedParts;
    } else {
      this.selectedPartsArray.push($event.currentPlanWithSelectedParts);
    }

    this.calculatingSelectedParts(this.learningTemplates, this.selectedPartsArray);
  }

  private calculatingSelectedParts(...arrays) {
    this.totalCountPartsIsChecked = arrays.reduce((accumulator, data) => {
      const checkedPartsLength = data.reduce((total, array) => {
        if (Array.isArray(array.parts)) {
          return total + array.parts.filter(part => part.isChecked === true).length;
        }
        return total;
      }, 0);
  
      return accumulator + checkedPartsLength;
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showCopyPartOfPlan && !changes.showCopyPartOfPlan.previousValue && changes.showCopyPartOfPlan.currentValue) {
      this.init();
    }
  }

  search(queryPhrase?, startOffset?, isSetFirstPage = false): void {
    const params = {
      input: {
        queryPhrase: queryPhrase || '',
        createdBy: 'all',
        size: this.pageSize,
        start: startOffset ?? this.startOffset,
      }
    };

    this.getUserLearningTemplates(params, isSetFirstPage);
  }

  getUserLearningTemplates(params, isSetFirstPage): void {
    this.isLoading = true;
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.copyPartService.getLearningTemplates(params).pipe(finalize(() => {
      this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
    }), takeUntil(this.unsubscribe$))
      .subscribe((templatesLibrary) => {
        this.defaultResponse = templatesLibrary.templates;
        templatesLibrary.templates.itemsStart = this.startOffset;
        this.totalRows = templatesLibrary.found;
        this.isLoading = false;

        if (isSetFirstPage) {
          this.pageNumber = 1;
          this.learningTemplates = [];
          this.totalCountPartsIsChecked = 0;
        }

        const hasStartOffset = this.learningTemplates.some(
          (template) => template.itemsStart === this.startOffset
        );

        if (Array.isArray(templatesLibrary.templates) && !hasStartOffset) {
          this.foundSamePlanAndReplace(templatesLibrary);
        
          this.learningTemplates.push(templatesLibrary.templates);
          if(this.selectedPartsArray.length){
            this.calculatingSelectedParts(this.learningTemplates , this.selectedPartsArray);
          }
        }
      }, (error) => {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [CONSTANTS.Toaster.ERROR, error.message]);
      });
  }


  private foundSamePlanAndReplace (templatesLibrary){
    for (let i = 0; i < templatesLibrary.templates.length; i++) {
      const template = templatesLibrary.templates[i];
      const indexInSpecialArray = this.selectedPartsArray.findIndex(item => item.code === template.code);
  
      if (indexInSpecialArray !== -1) {
        templatesLibrary.templates[i] = this.selectedPartsArray[indexInSpecialArray];
      }
    }
  }
  onPageChange(page) {
    if (page === 1) {
      this.startOffset = 0;
    }
    this.startOffset = (page - 1) * this.pageSize;
    this.pageNumber = page;
    this.search();
  }

  clearSearchInput(): void {
    if (!this.searchControl.disabled) {
      this.searchControl.setValue('');
      this.startOffset = 0;
    }
  }


  closeCopyPart(): void {
    this.showCopyPartOfPlan = false;
    this.totalCountPartsIsChecked = 0;
    this.pageNumber = 1;
    this.startOffset = 0;
    this.selectedPartsArray = [];
    this.closeCallback.emit();
  }


  openModalSelectedItems(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template);
  }

  removeItemAtModal(partToRemove): void {
    partToRemove.isChecked = false;

    this.calculatingSelectedParts(this.selectedPartsArray);
    this.copyPartSelection.currentPartSelectionSubject$.next(partToRemove);
    if (!this.totalCountPartsIsChecked) {
      this.bsModalRef.hide()
    }
  }

  addPartsToLearningPlan(): void {

    const checkedPartsArray = this.selectedPartsArray
    .map(item => (item.parts || []).filter(part => part.isChecked))
    .reduce((acc, parts) => acc.concat(parts), [])
    .map(({ isChecked, ...rest }) => rest);

    this.callBackAddCopyPart.emit(checkedPartsArray);
    this.totalCountPartsIsChecked = 0;
    this.selectedPartsArray = [];
    this.pageNumber = 1;
    this.startOffset = 0;
    this.closeCallback.emit();
  }

}
