import { Pipe, PipeTransform } from '@angular/core';
/**
 * example
 * {{'testTranslation' | AmdocsTruncatePipe: LIMIT : COMPLELTE : ELLIPSIS}}
 */
@Pipe({
  name: 'amdocsTruncate'
})
export class AmdocsTruncatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
