import { Injectable } from '@angular/core';
import checkIsPwa from 'check-is-pwa';

@Injectable()
export class AmdocsPwaService {

  public promptEvent: any;

  constructor() {
  }

  public isPwa(): boolean {
    return checkIsPwa();
  }
}
