import { Inject, Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { AmdocsTimeService } from "../amdocs-time/amdocs-time.service";
import { AMDOCS_CONSTANTS } from "../amdocs-constants";
import { Observable, of, throwError } from "rxjs";
import { AmdocsApiService } from "../amdocs-api/amdocs-api.service";
import {
  ApiError,
  ILoginCredentials,
  ILoginGatewayConfig,
  IRedirectResponse,
  IStsCredentials,
} from "../amdocs-models";
import { catchError, mergeMap } from "rxjs/operators";
import { AmdocsAppInitResponse } from "../amdocs-app-init/amdocs-app-init.service";

@Injectable({
  providedIn: "root",
})
export class AmdocsAuthService {
  private refreshTokenThread: any;
  private loginConfig: ILoginGatewayConfig;

  constructor(
    @Inject("ILoginGatewayConfig") loginGatewayConfig: ILoginGatewayConfig,
    private apiService: AmdocsApiService,
    private timeService: AmdocsTimeService,
    private cookieService: CookieService
  ) {
    this.loginConfig = loginGatewayConfig;
  }

  getToken(): string {
    return this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY);
  }

  public setRefreshTokenInterval(): void {
    this.clearRefreshTokenInterval();
    this.refreshTokenThread = setInterval(() => {
      this.refreshUserToken();
    }, this.loginConfig.refreshTokenInterval);
  }

  public clearRefreshTokenInterval(): void {
    if (this.refreshTokenThread) {
      clearInterval(this.refreshTokenThread);
    }
  }

  public getLoginURL(): Observable<IRedirectResponse> {
    return this.apiService.getLoginUrl().pipe(
      mergeMap((response: IRedirectResponse) => {
        return of(response);
      })
    );
  }

  public setpkceExp(pkceExp: number): void {
    const options = {
      path: "/",
      secure: true,
    };
    this.cookieService.set(
      AMDOCS_CONSTANTS.PKCE_EXPIRATION,
      `${pkceExp}`,
      options
    );
  }

  public refreshUserToken(): void {
    this.clearRefreshTokenInterval();
    this.callRefreshUserToken()
      .pipe(
        mergeMap((response: ILoginCredentials) => {
          this.setRefreshTokenInterval();
          return of(response);
        })
      )
      .subscribe();
  }

  public callRefreshUserToken(): Observable<ILoginCredentials> {
    return this.apiService.refreshToken().pipe(
      mergeMap((response: ILoginCredentials) => {
        this.setLoginCredentials(response);
        return of(response);
      }),
      catchError((error) => {
        this.initGetLoginUrlFlow().subscribe();
        return throwError(error);
      })
    );
  }

  public initGetLoginUrlFlow(): Observable<AmdocsAppInitResponse> {
    sessionStorage.removeItem("orig-path");
    return this.getLoginURL().pipe(
      mergeMap((response: IRedirectResponse) => {
        sessionStorage.setItem(
          "orig-path",
          window.location.pathname + window.location.search
        );
        if (response.redirectUri) {
          this.setpkceExp(response.pkceExpiration);
          (window as any).location = response.redirectUri;
          // window.open(response.redirectUri);
          return of(new AmdocsAppInitResponse(true));
        } else {
          return throwError(new ApiError(0, "could not get login url"));
        }
      })
    );
  }

  public removePkceExp(): void {
    this.cookieService.delete(AMDOCS_CONSTANTS.PKCE_EXPIRATION);
  }

  public isPkceValid(): boolean {
    const now = this.timeService.moment().unix() * 1000;
    const exp = this.cookieService.get(AMDOCS_CONSTANTS.PKCE_EXPIRATION);
    if (exp) {
      return parseInt(exp, 10) > now;
    }
    return false;
  }

  public hasRefreshTokenCookies(): boolean {
    const now = this.timeService.moment().unix() * 1000;
    const exp = this.cookieService.get(
      AMDOCS_CONSTANTS.CAS_TOKEN_EXPIRATION_KEY
    );
    if (exp) {
      return parseInt(exp, 10) > now;
    }
    return false;
  }

  public logout(): Observable<IRedirectResponse> {
    return this.apiService.getLogoutUrl().pipe(
      mergeMap((response: IRedirectResponse) => {
        this.cookieService.deleteAll();
        (<any>window).location = response.redirectUri;
        return of(response);
      })
    );
  }

  public login(code: string): Observable<ILoginCredentials> {
    return this.apiService.login(code).pipe(
      mergeMap((response: ILoginCredentials): Observable<ILoginCredentials> => {
        this.setLoginCredentials(response);
        return of(response);
      })
    );
  }

  /**
   * return if user is logged in
   */
  public isLoggedIn(): boolean {
    const token = this.getToken();
    if (token) {
      const exp = this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY);
      if (exp) {
        return this.isExpired(parseInt(exp, 10)) ? false : true;
      } else {
        return true;
      }
    }
    return false;
  }

  public isExpired(exp): boolean {
    const now = this.timeService.moment().unix() * 1000;
    return now > exp;
  }

  public idTokenIsAboutToExpired(): boolean {
    const now = this.timeService.moment().unix() * 1000;
    const exp = this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY);
    if (exp) {
      return parseInt(exp, 10) - now < 2400000;
    }
    return true;
  }

  private setLoginCredentials(loginCredentials: ILoginCredentials): void {
    const options = {
      path: "/",
      secure: true,
    };
    const stsCreds = loginCredentials.stsCredentials;
    this.cookieService.set(
      AMDOCS_CONSTANTS.ACCESS_KEY_ID,
      stsCreds.accessKeyId,
      options
    );
    this.cookieService.set(
      AMDOCS_CONSTANTS.SECRET_ACCESS_KEY,
      stsCreds.secretAccessKey,
      options
    );
    this.cookieService.set(
      AMDOCS_CONSTANTS.SESSION_TOKEN,
      stsCreds.sessionToken,
      options
    );
    this.cookieService.set(
      AMDOCS_CONSTANTS.EXPIRATION,
      stsCreds.expiration,
      options
    );

    this.cookieService.set(
      AMDOCS_CONSTANTS.TOKEN_KEY,
      loginCredentials.idToken,
      options
    );
    this.cookieService.set(
      AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY,
      loginCredentials.idTokenExpiration.toString(),
      options
    );
    this.cookieService.set(
      AMDOCS_CONSTANTS.CAS_TOKEN_EXPIRATION_KEY,
      loginCredentials.casTokenExpiration.toString(),
      options
    );
  }
}
