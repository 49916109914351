import {AfterContentInit, Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'lib-amdocs-table-drawer',
    templateUrl: './amdocs-table-drawer.component.html',
    styleUrls: ['./amdocs-table-drawer.component.scss']
})
export class AmdocsTableDrawerComponent implements AfterContentInit{
    @Input() rowData: any = {};

    @ViewChild('tableDrawer') tableDrawer;

    ngAfterContentInit() {}
}
