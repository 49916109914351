import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LearningPlanPart } from '../../models/learningPlan';

@Injectable({
  providedIn: 'root'
})
export class CopyPartSelectionsService {
  public currentPartSelectionSubject$= new BehaviorSubject<LearningPlanPart>(null);

  public currentPartSelection$ = this.currentPartSelectionSubject$.asObservable();


  constructor() { }

  public setCurrentPartSelection(part: LearningPlanPart): void {
    this.currentPartSelectionSubject$.next(part);
  }
}
