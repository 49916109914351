import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LearningItem, LearningPlanPart, LearningPlanType } from '../../../models/learningPlan';

import { v4 as uuidv4 } from 'uuid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AmdocsTranslateService } from 'projects/amdocs-core-package/src/public-api';
import { UtilsService } from '../../../core/utils.service';
import { AmdocsActionPopupComponent } from 'projects/amdocs-core-package/src/lib/amdocs-ui/components';

@Component({
  selector: 'app-learning-plan',
  templateUrl: './learning-plan.component.html',
  styleUrls: ['./learning-plan.component.scss']
})
export class LearningPlanComponent implements OnInit, OnDestroy {

  @Input() learningPlanType: number;
  @Input() learningTypesList = [];
  @Input() parts: LearningPlanPart[];
  @Input() isPreviewMode: boolean = false;
  @Output() finishCallback: EventEmitter<any> = new EventEmitter();
  @Output() removeItemCallback: EventEmitter<any> = new EventEmitter();
  @Output() restoreItemCallback: EventEmitter<any> = new EventEmitter();
  @Output() editCallback: EventEmitter<any> = new EventEmitter();
  @Output() updateLearninPlanCallback: EventEmitter<any> = new EventEmitter();
  @Output() editInLibraryCallback: EventEmitter<LearningItem> = new EventEmitter();
  
  @ViewChild('learningPlanContent') learningPlanContent: ElementRef;
  
  public sortedParts: LearningPlanPart[];
  public showLibrary = false;
  public showCopyPartOfPlan = false;
  public partId: number;
  public selectedPlan: LearningItem;
  public isEditLibraryMode: boolean = false;
  public bsModalRef?: BsModalRef
  public isCollapsed = false;

  constructor(
    private modalService: BsModalService,
    private translateService: AmdocsTranslateService,
    private utilsService: UtilsService,
  ) {
  }

  ngOnInit(): void {
    this.sortedParts = JSON.parse(JSON.stringify(this.parts))

    this.utilsService.viewLocalItemsLibrary = true
    this.utilsService.itemsLibraryShowEvent.subscribe(() => {
      if(this.parts.length) {
        this.openLibrary(this.parts[0].order || 1)
      }
    })
  }

  ngOnDestroy(): void {
    this.utilsService.viewLocalItemsLibrary = false
  }

  public LearningPlanType = LearningPlanType;

  public sortablejsOptions: any = {
    group: 'Parts',
    handle: '.handle',
    onEnd: (end) => {
      this.onMove(end);
    }
  };

  onMove(evt = null): void {
    this.editCallback.emit();
  }

  onEditItem(): void {
    this.editCallback.emit();
  }

  onPartRename(evt = null): void {
    this.editCallback.emit();
  }

  removeItem($event, partId): void {
    this.removeItemCallback.emit({item: $event.item, forceRemove: $event.forceRemove, itemIndex: $event.itemIndex, partId });
  }

  restoreItem($event, partId): void {
    this.restoreItemCallback.emit({item: $event.item, itemIndex: $event.itemIndex, partId });
  }

  openLibrary(partId): void {
    this.isEditLibraryMode = false; 
    this.showLibrary = true;
    this.partId = partId;
  }

  editInLibrary(item: LearningItem): void {
    this.isEditLibraryMode = true; 
    this.selectedPlan = item;
    this.showLibrary = true;
  }

  closeLibrary(): void {
    this.showLibrary = false;
    this.selectedPlan = null;
    this.isEditLibraryMode = false;
  }

  closeCopyPart(){
    this.showCopyPartOfPlan = false;
  }

  removePart(partId: number): void {
    this.parts.splice(partId, 1)
    this.editCallback.emit();
  }

  openCopyPartsFromOtherPlan() {
    this.showCopyPartOfPlan = true;
  }
  openModalRemovePart(partIndex, part) {
    if(!part.items.length) {
      this.removePart(partIndex);
      return;
    }

    this.bsModalRef = this.modalService.show(AmdocsActionPopupComponent, {
      initialState: {
        onAction: (result) => {
          if (result == 'yes'){
            this.removePart(partIndex);
            this.bsModalRef.hide()
          }
        }
      }
    });
    this.bsModalRef.content.title = this.translateService.getText('templateLearning.deletePartPopup.title');
    this.bsModalRef.content.closeBtnName = this.translateService.getText('templateLearning.deletePartPopup.closeBtnName');
    this.bsModalRef.content.cancelBtnText = this.translateService.getText('templateLearning.deletePartPopup.cancelBtnText');
    this.bsModalRef.content.actionBtnText = this.translateService.getText('templateLearning.deletePartPopup.actionBtnText');
    this.bsModalRef.content.actionValue = 'yes';
    this.bsModalRef.content.header = `${this.translateService.getText('templateLearning.deletePartPopup.header')} "${part.name}"?`;
    this.bsModalRef.content.text = this.translateService.getText('templateLearning.deletePartPopup.text');
    this.bsModalRef.content.isLargeButton = false;
  }

  addPart(scroll = false): void {
    this.parts.push({
      name: `New part`,
      items: [],
    })
    // Now, Lets trick Angular optimization to let SortableJS do its job. Also fixing ngx-sortable annoying bug.
    this.parts.forEach(item => item.order = uuidv4())

    this.editCallback.emit();
    if(scroll) this.scrollingAfterAddingPart();
  }

  addCopyPartsToPlan(copyPartsArray): void{
    for (const part of copyPartsArray) {
      this.parts.push(part);
    }

    this.updateLearninPlanCallback.emit();
    this.showCopyPartOfPlan = false;
    this.scrollingAfterAddingPart();
  }

  public partTracking(index, item): string {
    return item.order
  }

  private scrollingAfterAddingPart(){
    if(this.learningPlanContent){
      const planContent = this.learningPlanContent?.nativeElement;
      const lastPartElement = planContent?.lastElementChild
      lastPartElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getPlantTotalDuration(): number {
    return this.parts.reduce((partCounter, part) =>
      partCounter + part.items.reduce((count, item) =>
        item.activated ? count + item.duration : count, 0),
      0)
  }

  addItemsToLearningPlan($event): void {
    this.showLibrary = false;
    const part = this.parts.find(partItem => partItem.order === $event.partId);
    part.items = [
      ...part.items,
      ...$event.items,
    ];
    this.updateLearninPlanCallback.emit();
  }

  itemEdited($event): void {
    this.parts.forEach((part, index) => {
      this.parts[index].items = part.items.map(partItem => {
        if(partItem.code === $event.code) {
          return $event
        }
        return partItem
      })
    })
  }

  itemActivationChanged($event): void {
    this.parts.forEach((part, index) => {
      this.parts[index].items = part.items.map(partItem => {
        if(partItem.code === $event.itemCode) {
          return {
            ...partItem,
            activated: $event.activated,
          }
        }
        return partItem
      })
    })
  }
}
