<div class="paginator d-flex" *ngIf="lastPage">
    <div class="d-flex">
      <div class="current-page-wrapper">
        <input (focus)="onFocus()" (blur)="onBlur()" (input)="valueChange($event)" type="number" [min]="1" [max]="lastPage" class="current-page text-small-bold" [(ngModel)]="pageNumber">
        <span class="text-small-normal gray">/ </span><span class="last-page text-small-normal gray">{{lastPage}}</span>
      </div>
      <div class="next-prev-wrapper d-flex align-items-center">
        <div [disabled]="!allowPrev" class="prev" href="javascript:void(0)" (click)="prev()">
          <div class="icon icon-right" [ngClass]="{'disable': pageNumber === 1}"></div>
        </div>
      </div>
      <div class="next-prev-wrapper d-flex align-items-center">
        <div [disabled]="!allowNext" class="next" href="javascript:void(0)" (click)="next()">
          <div class="icon icon-left" [ngClass]="{'disable': pageNumber === lastPage}"></div>
        </div>
      </div>
    </div>
</div>
