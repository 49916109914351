import { NgModule } from '@angular/core';
import { AmdocsAppPreloadingStrategy } from './amdocs-app-preloading-strategy/amdocs-app-preloading-strategy';
import { AmdocsEventBusService } from './amdocs-event-bus/amdocs-event-bus.service';
import { AmdocsTranslateService } from './amdocs-translate/amdocs-translate.service';
import { AmdocsFunctionsService } from './amdocs-functions/amdocs-functions.service';
import { AmdocsAuthService } from './amdocs-auth/amdocs-auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AmdocsCookieService } from './amdocs-cookie/amdocs-cookie.service';
import { AmdocsApiUtilsService } from './amdocs-api/amdocs-api-utils.service';
import { AmdocsAppsyncClientService } from './amdocs-appsync-client/amdocs-appsync-client.service';
import { HttpClientModule } from '@angular/common/http';
import { AmdocsTimeService } from './amdocs-time/amdocs-time.service';
import { AmdocsAppInitService } from './amdocs-app-init/amdocs-app-init.service';
import { AmdocsApiService } from './amdocs-api/amdocs-api.service';
import { AmdocsGatewayClientService } from './amdocs-gateway-client/amdocs-gateway-client.service';
import { AmdocsUserService } from './amdocs-user/amdocs-user.service';
import { AmdocsDeviceService } from './amdocs-device/amdocs-device.service';
import { AmdocsPwaService } from './amdocs-pwa/amdocs-pwa.service';


@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    AmdocsApiUtilsService,
    AmdocsApiService,
    AmdocsAppPreloadingStrategy,
    AmdocsAppsyncClientService,
    AmdocsAuthService,
    AmdocsEventBusService,
    AmdocsFunctionsService,
    AmdocsGatewayClientService,
    AmdocsTranslateService,
    AmdocsCookieService,
    AmdocsTimeService,
    AmdocsAppInitService,
    AmdocsUserService,
    AmdocsDeviceService,
    AmdocsPwaService,
    CookieService
  ],
  declarations: []
})
export class AmdocsUtilsModule {
}
