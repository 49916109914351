<div class="learning-plan-part-wrapper">
  <div class="top-part-wrapper">
    <div class="template-left d-flex flex-column">
      <div class="d-flex mb-1">
        <div
          class="template-left-name"
          *ngIf="learningPlans?.title?.length < 40"
        >
          {{ learningPlans.title }}
        </div>
        <lib-amdocs-popover
          *ngIf="learningPlans?.title?.length > 40"
          [trigger]="'hover'"
          [placement]="'right'"
          class="ml-1 name-popup"
        >
          <div
            popoverOpener
            class="template-left-name"
          >
            {{ learningPlans.title | amdocsTruncate : 40 : false }}
          </div>
          <div popoverContent>
            {{ learningPlans.title }}
          </div>
        </lib-amdocs-popover>
      </div>

      <div class="template-left-info d-flex flex-row align-items-center">
        <div class="d-flex">
          <img
            lib-amdocs-image-version
            src="/assets/icons/course-time.svg"
          />
          <div
            class="details-text"
            *ngIf="learningPlans.itemsDuration !== null"
          >
            {{ learningPlans.itemsDuration | amdocsMinutesToHours }}
          </div>
          <div
            class="details-text"
            *ngIf="
            learningPlans.itemsDuration === null ||
              learningPlans.itemsDuration === undefined
            "
          >
            {{ "templateLearning.noData" | amdocsTranslate }}
          </div>
        </div>
        <div class="d-flex">
          <img
            lib-amdocs-image-version
            src="/assets/icons/course-clock.svg"
          />
          <div
            class="details-text"
            *ngIf="learningPlans.itemsCount"
          >
            <ng-container *ngIf="learningPlans.itemsCount === 1">
              {{
                "templateLearning.item"
                  | amdocsTranslate : [learningPlans.itemsCount]
              }}
            </ng-container>
            <ng-container *ngIf="learningPlans.itemsCount > 1">
              {{
                "templateLearning.items"
                  | amdocsTranslate : [learningPlans.itemsCount]
              }}
            </ng-container>
          </div>
          <div
            class="details-text"
            *ngIf="!learningPlans.itemsCount"
          >
            {{ "templateLearning.noData" | amdocsTranslate }}
          </div>
        </div>
        <lib-amdocs-popover
          [trigger]="'hover'"
          [placement]="'right'"
          class="ml-1 plan-details"
          (mouseenter)="showPlanDetails(planInfo)"
          *ngIf="showPlanDetailsTooltip"
        >
          <div popoverOpener>
            {{ "templateLearning.moreDetails" | amdocsTranslate }}
          </div>
          <div popoverContent>
            <div class="plan-details-wrapper d-flex flex-wrap">
              <div class="plan-item-zone d-flex flex-column w-100">
                <div
                  class="plan-info-line d-flex flex-column mb-2"
                  *ngIf="planInfo?.description"
                >
                  <span class="plan-label">{{
                    "templateLearning.descriptionLabel" | amdocsTranslate
                  }}</span>
                  <span class="plan-value">{{ planInfo.description }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.prerequisites"
                >
                  <span class="plan-label">{{
                    "templateLearning.prerequisites" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{
                    planInfo?.prerequisites
                  }}</span>
                </div>
              </div>
              <div class="plan-item-zone two-column">
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.expectedTime"
                >
                  <span class="plan-label">{{
                    "templateLearning.expectedToFinish" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">
                    {{
                      "templateLearning.expectedToFinishMonth"
                        | amdocsTranslate : [planInfo?.expectedTime]
                    }}
                  </span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.seniority"
                >
                  <span class="plan-label">{{
                    "templateLearning.seniority" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo?.seniority }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.role"
                >
                  <span class="plan-label">{{
                    "templateLearning.role" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo?.role }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.product"
                >
                  <span class="plan-label">{{
                    "templateLearning.product" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo?.product }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.locationLabel"
                >
                  <span class="plan-label">{{
                    "templateLearning.location" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{
                    planInfo.locationLabel
                  }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.account"
                >
                  <span class="plan-label">{{
                    "templateLearning.account" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo.account }}</span>
                </div>
              </div>
              <div class="plan-item-zone two-column">
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.org2"
                >
                  <span class="plan-label">{{
                    "templateLearning.org2" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo.org2 }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.org3"
                >
                  <span class="plan-label">{{
                    "templateLearning.org3" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo?.org3 }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.org4"
                >
                  <span class="plan-label">{{
                    "templateLearning.org4" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo?.org4 }}</span>
                </div>
                <div
                  class="plan-info-line mb-2"
                  *ngIf="planInfo?.org5"
                >
                  <span class="plan-label">{{
                    "templateLearning.org5" | amdocsTranslate
                  }}</span>
                  <span class="ml-1 plan-value">{{ planInfo.org5 }}</span>
                </div>
              </div>
            </div>
          </div>
        </lib-amdocs-popover>
      </div>
    </div>

    <div class="right-side">
      <button
        class="collapse-part-button"
        (click)="rowIsCollapsed(learningPlans.code)"
        [attr.aria-expanded]="!isCollapsed"
      >
        <img
          *ngIf="!isCollapsed"
          lib-amdocs-image-version
          src="/assets/icons/collapsable-up.svg"
        />
        <img
          *ngIf="isCollapsed"
          lib-amdocs-image-version
          src="/assets/icons/collapsable-down.svg"
        />
      </button>
    </div>
  </div>

  <div
    [collapse]="isCollapsed"
    [isAnimated]="true"
  >
    <div class="items-wrapper">
      <lib-amdocs-app-loader [loaderClass]="'copy-part-loader'" *ngIf="isLoading"></lib-amdocs-app-loader>
        <ng-container *ngIf="!learningPlans.parts?.length && !isLoading">
          <div class="no-items">
            {{ "learningPlan.planEmpty" | amdocsTranslate }}
          </div>
        </ng-container>
        <div *ngFor="let part of learningPlans.parts; let i = index; trackBy: partTracking">
          <app-copy-part-of-plan-part-list
            [part]="part"
            [learningTypesList]="learningTypesList"
            [isCollapsedPlan]="isCollapsed"
            (selectedPartsCallBack)="selectedPartsCallBack($event)"
          >
          </app-copy-part-of-plan-part-list>
        </div>
    </div>
  </div>
</div>
