import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'amdocs-action-popup',
  templateUrl: './amdocs-action-popup.component.html',
  styleUrls: ['./amdocs-action-popup.component.scss'],
})
export class AmdocsActionPopupComponent {

  @Output() closeCallback: EventEmitter<any> = new EventEmitter();

  public title: string;
  public closeBtnName: string;
  public cancelBtnText: string;
  public actionBtnText: string;
  public actionValue: string;
  public header: string;
  public text: string;
  public icon: string;
  public isLargeButton: boolean;
  public isDarkStyleButton: boolean;
  public isSecondaryStyleButton: boolean = true;
  public onActionCallback: Function;

  constructor(public bsModalRef: BsModalRef) { }

  // ToDo: Will be needed for other use cases, welcome
  /**
   * on popup hide
   */
  // onHide(): void {
  //   if (this.closeCallback.observers.length) {
  //     this.closeCallback.emit();
  //   }
  // }

  onAction(actionValue = 'no'): void {
    if (this.onActionCallback) {
      this.onActionCallback(actionValue);
    }
    this.bsModalRef.hide();
  }
}
