<ng-content *ngIf="!editMode; then itemView else itemEditForm"></ng-content>

<ng-template #itemView>
  <div class="learning-plan-item-wrapper" [ngClass]="{'is-checked': isChecked, 'deactivated': !item.activated, 'removed': item.removed}">
    <div *ngIf="showHandle && !isPreviewMode && !isCopyPartOfPlan" class="handle">
      <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
        <div popoverOpener>
          <div class="handle-icon"></div>
        </div>
        <div popoverContent class="handle-text">
          {{'learningPlan.item.drag' | amdocsTranslate }}
        </div>
      </lib-amdocs-popover>
    </div>
    <div *ngIf="showAddItem" class="item-add-checkbox-wrapper">
      <lib-amdocs-checkbox [elementId]="item.code" [control]="checkboxCtrl" [disabled]="!item.activated"></lib-amdocs-checkbox>
    </div>
    <div class="learning-plan-content-wrapper" *ngIf="!imageView">
      <div class="learning-plan-content">
        <div class="learning-plan-content-left">
          <div class="label-h4 plan-item-title" *ngIf="item.resourceURL.length"><a href="{{item.resourceURL}}" target="_blank">{{item.title}}</a></div>
          <div class="label-h4 plan-item-title" *ngIf="!item.resourceURL.length">{{item.title}}</div>
          <div class="course-details" *ngIf="!compactTitle">
            <ng-container
              [ngTemplateOutlet]="courseDetails"
            ></ng-container>
          </div>
        </div>
        <div class="d-flex" (click)="toggleMoreDetailsFlag(item)">
          <div class="more-details-text" *ngIf="!item.moreDetailsFlag">{{'learningPlan.item.moreDetails' | amdocsTranslate }}</div>
          <div class="more-details-text" *ngIf="item.moreDetailsFlag">{{'learningPlan.item.lessDetails' | amdocsTranslate }}</div>
          <img lib-amdocs-image-version *ngIf="!item.moreDetailsFlag" src="/assets/icons/angle-up.svg"/>
          <img lib-amdocs-image-version *ngIf="item.moreDetailsFlag" src="/assets/icons/angle-down.svg"/>
        </div>
      </div>
      <div *ngIf="item.moreDetailsFlag" class="more-details-wrapper">
        <div class="top-details">
          <ng-container
            [ngTemplateOutlet]="moreDetails"
            [ngTemplateOutletContext]="{item: item}">
          </ng-container>
          <ng-container
            *ngIf="!noActions"
            [ngTemplateOutlet]="itemActions"
            [ngTemplateOutletContext]="{item: item}">
          </ng-container>
        </div>
      </div>
    </div>
    <div
      class="learning-plan-content-wrapper with-image"
      [ngClass]="{'no-image': noImage, 'item-removed': item.removed}"
      *ngIf="imageView"
    >
      <div class="plan-item-image-wrapper" *ngIf="!noImage">
        <img lib-amdocs-image-version [src]="getItemImage(item)"/>
      </div>
      <div class="plan-item-content-wrapper" [ngClass]="{'no-image': noImage}">
        <div class="label-h4 plan-item-title" *ngIf="item.resourceURL.length"><a href="{{item.resourceURL}}" target="_blank">{{item.title}}</a></div>
        <div class="label-h4 plan-item-title" *ngIf="!item.resourceURL.length" >{{item.title}}</div>
        <div class="course-details d-flex align-items-center" *ngIf="!item.removed">
          <div class="d-flex">
            <img lib-amdocs-image-version src="/assets/icons/course-clock.svg"/>
            <div class="details-text">
              <ng-container *ngIf="item?.typeID && learningTypesList">{{learningTypesList[item.typeID]}}</ng-container>
              <ng-container *ngIf="!item?.typeID">{{item.course}}</ng-container>
            </div>
          </div>
          <div class="d-flex" >
            <img lib-amdocs-image-version src="/assets/icons/course-time.svg"/>
            <div class="details-text"> {{item.duration | amdocsMinutesToHours:true}}</div>
          </div>
          <div *ngIf="!isCopyPartOfPlan" class="d-flex library-edit mr-2" (click)="editInLibrary(item)">
            <img lib-amdocs-image-version src="/assets/icons/edit.svg" />
            <span>
              {{ "templateLearning.editLibrary" | amdocsTranslate }}
            </span>
          </div>
          <div class="d-flex more-details-opener" *ngIf="item.technology || item.product || item.domain || item.account">
            <div>
              <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
                <div popoverOpener>
                  <div class="more-details-text">{{'learningPlan.item.moreDetails' | amdocsTranslate }}</div>
                </div>
                <div popoverContent class="learning-plan-item-popover-content">
                  <ng-container
                    [ngTemplateOutlet]="moreDetails"
                    [ngTemplateOutletContext]="{item: item}">
                  </ng-container>
                </div>
              </lib-amdocs-popover>
            </div>
          </div>
        </div>
        <div class="plan-item-description" *ngIf="!item.removed">
          {{item.description}}
        </div>
      </div>
      <div class="plan-item-note-wrapper" *ngIf="!item.activated">
        <span>Inactive item</span>
        <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
          <img popoverOpener class="info-icon" src="assets/icons/info.svg" />
          <div popoverContent>{{'learningPlan.item.inactiveTooltipText' | amdocsTranslate }}</div>
        </lib-amdocs-popover>
      </div>
      <ng-container *ngIf="item.removed">
        <div
          class="trash-delete"
          (click)="removeItem(item, true)"
          [tooltip]="'learningPlan.item.deleteItem' | amdocsTranslate"
        >
          <img
            lib-amdocs-image-version
            src="/assets/icons/trash-delete.svg"
          />
        </div>
      </ng-container>
    </div>
    <div>
      <div
        *ngIf="!isPreviewMode && !isCopyPartOfPlan && showRemoveItem && !item.removed"
        class="add-remove-item"
        (click)="removeItem(item, false)"
      >
        <img
          lib-amdocs-image-version
          src="/assets/icons/remove-course.svg"
        />
        <div class="remove">Remove</div>
      </div>

        <div
          *ngIf="showRemoveItem && item.removed"
          class="restore-item-wrapper"
          (click)="restoreItem(item)"
          [tooltip]="'learningPlan.item.returnItem' | amdocsTranslate "
        >
          <img
            lib-amdocs-image-version
            src="/assets/icons/restore.svg"
          />
        </div>
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #moreDetails>
  <div class="more-details">
    <div class="course-details compact" *ngIf="compactTitle">
      <ng-container
        [ngTemplateOutlet]="courseDetails"
      ></ng-container>
    </div>
    <div class="more-info-text" *ngIf="item.createdByUser">
      <span class="strong">{{ 'learningPlan.item.createdBy'| amdocsTranslate }}</span>
      {{item.createdByUser}}
    </div>
    <div class="description" *ngIf="!imageView">{{item.description}}</div>
    <div class="more-info-text" *ngIf="item.product">
      <span class="font-weight-bold">{{'learningPlan.item.product' | amdocsTranslate }}</span>
      {{item.product}}
    </div>
    <div class="more-info-text" *ngIf="item.domain">
      <span class="font-weight-bold">{{'learningPlan.item.domain' | amdocsTranslate }}</span>
      {{item.domain}}
    </div>
    <div class="more-info-text" *ngIf="item.technology">
      <span class="font-weight-bold">{{'learningPlan.item.technology' | amdocsTranslate }}</span>
      {{item.technology}}
    </div>
    <div class="more-info-text" *ngIf="item.account">
      <span class="font-weight-bold">{{'learningPlan.item.account' | amdocsTranslate }}</span>
      {{item.account}}
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #itemActions>
  <div class="item-actions-wrapper">
    <button class="edit-details-button btn btn-small secondary icon-button" (click)="editDetails()">
      <div class="text">
        <img class="button-icon" lib-amdocs-image-version src="/assets/icons/pencil.svg"/>
        <span>{{'learningPlan.item.editItemDetails'| amdocsTranslate}}</span>
      </div>
    </button>
    <button class="deactivate-button btn btn-small secondary icon-button" (click)="deactivate()" *ngIf="item.activated">
      <div class="text">
        <img class="button-icon" lib-amdocs-image-version src="/assets/icons/trash-can-16.svg"/>
        <span>{{'learningPlan.item.deactivateItem'| amdocsTranslate}}</span>
      </div>
    </button>
    <button class="reactivate-button btn btn-small secondary icon-button" (click)="reactivate()" *ngIf="!item.activated">
      <div class="text">
        <img class="button-icon" lib-amdocs-image-version src="/assets/icons/trash-can-16.svg"/>
        <span>{{'learningPlan.item.reactivateItem'| amdocsTranslate}}</span>
      </div>
    </button>

    <div
      class="actions-note-wrapper"
      *ngIf="item.usageCount > 0"
    >
      <div>
        <div class="note-header"><strong>Item in use in {{item.usageCount}} plans </strong></div>
        <div class="note-body"> This item is in use, please be careful while editing or deactivating.</div>
      </div>
      <!-- <div class="note-header"><strong>{{'learningPlan.item.noteHeader'| amdocsTranslate}}</strong></div>
          <div class="note-body">{{'learningPlan.item.noteBody'| amdocsTranslate}}</div> -->
      <div class="see-plans-btn" (click)="openIncludedItemsModal()">See plans > </div>
    </div>
  </div>
</ng-template>

<ng-template #itemEditForm>
  <app-learning-plan-item-form
    [item]="item"
    (closeAction)="finishEditing()"
    (updateItemAction)="updateItem($event)"
  ></app-learning-plan-item-form>
</ng-template>

<ng-template #courseDetails>
  <div class="d-flex">
    <img lib-amdocs-image-version src="/assets/icons/course-clock.svg"/>
    <div class="details-text">
      <ng-container *ngIf="item?.typeID && learningTypesList">{{getLearningTypeName(item.typeID)}}</ng-container>
      <ng-container *ngIf="!item.typeID">{{item.course}}</ng-container>
    </div>
  </div>
  <div class="d-flex">
    <img lib-amdocs-image-version src="/assets/icons/course-time.svg"/>
    <div class="details-text"> {{item.duration | amdocsMinutesToHours:true}}</div>
  </div>
  <div class="d-flex" *ngIf="item.updatedAt || item.updatedByUser">
    <div class="details-separator"></div>
    <div class="details-text">
      <span class="strong">{{ 'learningPlan.item.lastEdited'| amdocsTranslate }}:</span>
      <span *ngIf="item.updatedAt"> {{item.updatedAt | date:'d MMM y, H:mm'}}</span>
      <span *ngIf="item.updatedByUser"> by {{item.updatedByUser}}</span>
    </div>
  </div>
</ng-template>
