<div class="top-header-main-wrapper">

  <img *ngIf="showBackBtn" (click)="handleBackButtonClicked()" alt="" src="/assets/icons/arrow-left.svg">
  <span *ngIf="!showBackBtn" aria-hidden="true" class="reserved-for-img"></span>
  <div class="text-medium-bold title-text"> {{headerTitle}} </div>

  <span aria-hidden="true" class="reserved-for-img header-action" *ngFor="let headerAction of headerActions">
     <img (click)="onActionClick(headerAction)"
          alt=""
          [src]="headerAction.iconPath"
          [style.width.px]="headerAction.width"
          [style.height.px]="headerAction.height">
      <span *ngIf="headerAction.innerHtml" [innerHTML]="headerAction.innerHtml | amdocsSafeUrl"></span>
  </span>

    <span aria-hidden="true" class="reserved-for-img" [hidden]="!showHomeButton && headerActions.length > 0">
      <img *ngIf="showHomeButton" (click)="backHome()" alt="" src="/assets/icons/home.svg">
  </span>

</div>

