import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IFormControlErrors } from '../amdocs-models';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AmdocsFunctionsService {


  constructor() {
  }

  public createGuid(): string {
    return uuidv4();
  }

  public convertArrayForQueryString(obj, prefix): any[] {
    const arr = [];
    let p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        arr.push((v !== null && typeof v === 'object') ?
          this.convertArrayForQueryString(v, k) :
          encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    }
    return arr;
  }

  public sortArrayByKey(arr: any[], key: string, asc = true): any[] {
    if (asc) {
      arr.sort((a, b) => {
        return ((a[key] > b[key]) ? -1 : ((a[key] < b[key]) ? 1 : 0));
      });
    } else {
      arr.sort((a, b) => {
        return ((a[key] < b[key]) ? -1 : ((a[key] > b[key]) ? 1 : 0));
      });
    }
    return arr;
  }

  public createValueKeyArray(max): any[] {
    const array = [];

    for (let i = 1; i <= max; i++) {
      array.push({ key: i, value: i });
    }

    return array;
  }

  /**
   * clear array from a certain value
   * @param arr - array of any
   * @param clearVal - value to clear
   * @returns any[]
   */
  public clearArray(arr: any[], clearVal: any = null): any[] {
    const tmpArr = [];
    arr.forEach((tmpItem) => {
      if (tmpItem !== clearVal) {
        tmpArr.push(tmpItem);
      }
    });
    return tmpArr;
  }

  public getUrlParamByName(name, url = null): string {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public getErrorMsg(formControl: UntypedFormControl, errors: IFormControlErrors): string {
    if (formControl && formControl.errors && errors) {
      for (let errorKey in formControl.errors) {
        if (errors[errorKey]) {
          return errors[errorKey];
        }
      }
    }
    return null;
  }

  public navigateExternalUrl(path: string, target = '_blank'): void {
    window.open(path, target);
  }

  public cloneObj(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }
}
