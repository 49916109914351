import { Component, Input } from '@angular/core';

@Component({
  selector: 'amdocs-footer',
  templateUrl: './amdocs-footer.component.html',
  styleUrls: ['./amdocs-footer.component.scss']
})

export class AmdocsFooterComponent {
  @Input() improveLink: any;
  public copyYear = (new Date()).getUTCFullYear();

  constructor() {}
}
