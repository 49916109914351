<div class="amdocs-upload-file-wrapper">
  <button class="amdocs-upload-file" (click)="fileInput.click()">
    <img class="image" image-src src="/assets/icons/add.svg"/>
    <div class="text-small-normal label">{{label}}</div>
    <input
      type="file"
      id="{{elementId}}"
      #fileInput
      class="form-control"
      [formControl]="control"
      [attr.accept]="accept"
      readonly
      style="display: none"
      (focus)="onFocus()"
      (blur)="onBlur($event)"
      (change)="handleFileInput($event.target.files)">
  </button>
</div>

