import { Component, Input, OnInit } from '@angular/core';
import { AmdocsControlAbstract } from '../amdocs-control-abstract';

@Component({
  selector: 'lib-amdocs-switcher',
  templateUrl: './amdocs-switcher.component.html',
  styleUrls: ['./amdocs-switcher.component.scss']
})
export class AmdocsSwitcherComponent extends AmdocsControlAbstract implements OnInit {
  /**
   * Switcher label - will be displayed asside the switcher
   */
  @Input() labelText: string;
  @Input() isRequired = false;
  @Input() hideLabel = false;
  @Input() ariaLabel: string;
  @Input() ariaLabeledBy: string;
  @Input() ariaDescribedBy: string;

  ngOnInit(): void {
    super.ngOnInit();
  }

  getAriaDescribedBy(): string {
    return this.ariaDescribedBy ? this.ariaDescribedBy : this.errorElementId;
  }

  get errorElementId(): string {
    return `${this.elementId}-error-label`;
  }

}
