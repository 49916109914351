import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LearningPlanPart, LearningPlanType } from '../../../models/learningPlan';
import { UntypedFormControl } from '@angular/forms';
import { CopyPartSelectionsService } from '../../services/copy-part-selections-service';

@Component({
  selector: 'app-copy-part-of-plan-part-list',
  templateUrl: './copy-part-of-plan-part-list.component.html',
  styleUrls: ['./copy-part-of-plan-part-list.component.scss']
})
export class CopyPartOfPlanPartListComponent implements OnInit {

  @Input() part: LearningPlanPart;
  @Input() planTitle: string;
  @Input() showLoader;
  @Input() isModalSelectedParts;
  @Input() isCollapsedPlan;
  @Input() currentPartWhatIsDeleted;
  @Input() learningTypesList;

  @Output() selectedPartsCallBack= new EventEmitter;

  
  public LearningPlanType = LearningPlanType;
  public isCollapsed = true;
  public checkboxCtrl: UntypedFormControl;
  public totalDuration;
  public toggleCollapse = () => this.isCollapsed = !this.isCollapsed;
  public itemTracking = (index, item) => item.code;

  constructor(private copyPartSelection: CopyPartSelectionsService) {
    this.copyPartSelection.currentPartSelection$.subscribe((part) => {
      if (part === this.part) {
      this.checkboxCtrl.setValue(part.isChecked);
    }
    });
  }

  ngOnInit() {
    this.getPartTotalDuration();

    this.checkboxCtrl = new UntypedFormControl();
    if(this.part.isChecked){
      this.checkboxCtrl.setValue(this.part.isChecked);
    }
    this.checkboxCtrl.valueChanges.subscribe((value) => {
      this.part.isChecked = value;
      this.selectedPartsCallBack.emit({})
    })
  }

  public getPartTotalDuration(): number {
    return this.totalDuration = this.part?.items?.reduce((prev, cur) => prev + ((cur.activated) ? cur.duration : 0), 0)
  }
}
