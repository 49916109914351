import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-amdocs-app-loader',
  templateUrl: './amdocs-app-loader.component.html',
  styleUrls: ['./amdocs-app-loader.component.scss']
})
export class AmdocsAppLoaderComponent {
  @Input() showOverlay: boolean;
  @Input() loaderClass?: string;

  constructor() {
  }
}
