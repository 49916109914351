import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amdocsMinutesToHours'
})

export class AmdocsMinutesToHoursPipe implements PipeTransform {

  transform(value: number, short = false): string {
    if(value === 0) {
      return '0';
    }
    const hours = Math.floor(Number(value) / 60).toFixed(0);
    const minutes = Math.floor(value % 60).toFixed(0);
    let formattedTime = '';

    if (hours !== '0') {
      formattedTime += this.getFormattedTime(hours, short ? 'hr' : 'hour', short);
    }

    if (minutes !== '0') {
      formattedTime += this.getFormattedTime(minutes, short ? 'min' : 'minute', short);
    }

    return formattedTime;
  }

  private getFormattedTime(value, valueName, short) {

    const plural = (Number(value) > 1) ? 's' : '';
    return `${value} ${valueName}${short ? '' : plural} `
  }
}
