import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmdocsFunctionsService } from 'projects/amdocs-core-package/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class UtilsService extends AmdocsFunctionsService {

  public promptEvent: any;
  public itemsLibraryShowEvent = new EventEmitter<any>()
  public viewLocalItemsLibrary = false
  public dropdownData = {
    roles: [],
    learningDomains: [],
    learningTypes: [],
    managedProducts: [],
    customProducts: [],
    accounts: [],
    domains: [],
    seniorityLevels: [],
    locations: [],
    org2Units: [],
    org3Units: [],
    org4Units: [],
    org5Units: [],
  };

  constructor(
  ) {
    super();
  }

  isMobileSize(): boolean {
    return window.matchMedia('(max-width: 600px)').matches;
  }

  showItemsLibrary(): void {
    this.itemsLibraryShowEvent.next()
  }

  onlyNumbers(e): void {

    if ((e.key === '.') || (e.key === 'Backspace') || (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) || // Cmd+X (Mac)
      (e.key === 'Tab')
    ) {
      return;  // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  getValuesOfItem(item): any {
    const itemCopy = {...item};
    const customProducts = itemCopy.customProductIDs?.length ? itemCopy.customProductIDs : itemCopy.customProducts;
    const products = itemCopy.productIDs?.length ? itemCopy.productIDs : itemCopy.products;
    if (!(itemCopy.accountIDs instanceof Array)) {
      itemCopy.accountIDs = [itemCopy.accountIDs]
    };
    itemCopy.role = itemCopy.roles?.length ? itemCopy.roles : this.getStringValueOfMultipleSelect(itemCopy.roleIDs, this.dropdownData.roles);
    itemCopy.product = itemCopy.products?.length || itemCopy.customProducts?.length  ? [...products, ...customProducts] : this.getStringValueOfMultipleSelect([...products || [], ...customProducts || []], [...this.dropdownData.managedProducts, ...this.dropdownData.customProducts]);
    itemCopy.account = itemCopy.accounts?.length ? itemCopy.accounts : this.getStringValueOfMultipleSelect(itemCopy.accountIDs, this.dropdownData.accounts);
    itemCopy.locationLabel = itemCopy.locations?.length ? itemCopy.locations : this.getStringValueOfMultipleSelect(itemCopy.locationIDs, this.dropdownData.locations);
    itemCopy.course = this.getStringValueOfSelect(itemCopy.typeID, this.dropdownData.learningTypes);
    itemCopy.domain = this.getStringValueOfSelect(itemCopy.domainID, this.dropdownData.domains);
    itemCopy.seniority = itemCopy.empSeniority || this.getStringValueOfSelect(itemCopy.empSeniorityID, this.dropdownData.seniorityLevels);
    itemCopy.org2 = itemCopy.org2 || this.getStringValueOfSelect(itemCopy.org2ID, this.dropdownData.org2Units);
    return itemCopy;
  }

  getStringValueOfMultipleSelect(items, list) {
    return items?.map(item => this.getStringValueOfSelect(item, list)).join(', ') || '';
  }

  getStringValueOfSelect(item, list) {
    return (list?.find(n => n.code === item)?.name) || '';
  }

  trimSpacesInControl(event?: KeyboardEvent, control?: UntypedFormControl): void {
    if (event?.key === ' ') {
      event.preventDefault();
    }
  
    const urlValue = control?.value;
    if (urlValue && urlValue.includes(' ')) {
      const trimmedUrl = urlValue.trim();
      control?.setValue(trimmedUrl);
    }
  }
}
