import { Component, Input, OnInit } from '@angular/core';
import { AmdocsControlAbstract } from '../amdocs-control-abstract';

@Component({
  selector: 'lib-amdocs-radio',
  templateUrl: './amdocs-radio.component.html',
  styleUrls: ['./amdocs-radio.component.scss']
})
export class AmdocsRadioComponent extends AmdocsControlAbstract implements OnInit {

  @Input() options: IRadio[] = [];

  ngOnInit(): void {
    super.ngOnInit();
  }
}

export interface IRadio {
  id: any;
  desc: string;
}
