import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {AmdocsAuthService} from 'projects/amdocs-core-package/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public promptEvent: any;
  public roleToken: string;
  public empId: string;

  constructor(private authService: AmdocsAuthService) {
  }

  private name = '';

  public setUserDetails(): void {
    const formattedToken: any = jwt_decode(this.authService.getToken());
    this.name = formattedToken.name ? formattedToken.name : '';
    this.empId = formattedToken.iat ? formattedToken.iat : '';
  }

  public getName(): string {
    return this.name;
  }

  public getEmpId(): string {
    return this.empId;
  }

}
