export const AMDOCS_CONSTANTS = {
  PKCE_EXPIRATION: 'pkce_expiration',
  TOKEN_KEY: 'open_id_token',
  TOKEN_EXPIRATION_KEY: 'open_id_token_expiration',
  CAS_TOKEN_KEY: 'cas_token',
  CAS_TOKEN_EXPIRATION_KEY: 'cas_token_expiration',
  ACCESS_KEY_ID: 'AccessKeyId',
  SECRET_ACCESS_KEY: 'SecretAccessKey',
  SESSION_TOKEN: 'SessionToken',
  EXPIRATION: 'expiration',
  SHOW_NEW_VERSION_ALERT_EVENT: 'show-new-version-alert-event',
  API_ERROR_EVENT: 'global-api-error-event'
};
