import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class AmdocsDeviceService {

  public screenHeight: number;
  // public portraitMode: boolean;

  constructor(private deviceService: DeviceDetectorService) {

  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isIos(): boolean {
    const windowObj = window as any;
    const userAgent = navigator.userAgent || navigator.vendor;
    return (/iPad|iPhone|iPod/.test(userAgent) && !windowObj.MSStream);
  }

  isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  isChromeIOS(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return this.isSafari() && ua.indexOf('crios') > -1;
  }

  isAndroid(): boolean {
    return (this.isMobile() && !this.isIos());
  }
}
