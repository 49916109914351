import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AmdocsControlAbstract } from '../amdocs-control-abstract';

@Component({
  selector: 'lib-amdocs-upload-file',
  templateUrl: './amdocs-upload-file.component.html',
  styleUrls: ['./amdocs-upload-file.component.scss']
})
export class AmdocsUploadFileComponent extends AmdocsControlAbstract implements OnInit {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Input() label = 'Add attachment';

  /**
   * max file size in MB
   */
  @Input() maxSize: number;
  @Input() fileTypes: string[] = [];
  @Input() accept: string;
  @Output() onFileChange: EventEmitter<any> = new EventEmitter();
  @Output() onFileValidationError: EventEmitter<any> = new EventEmitter();

  public openFileInput() {
    this.fileInput.nativeElement.click();
  }

  public handleFileInput(files) {
    if (files.length) {
      const file: File = files.item(0);
      if (this.maxSize) {
        if (file.size > (this.maxSize * 1000000)) {
          return this.handleValidationError(file);
        }
      }
      if (this.fileTypes.length) {
        const parts = file.name.split('.');
        const ext = parts[parts.length - 1];
        if (this.fileTypes.indexOf(ext.toLowerCase()) === -1) {
          return this.handleValidationError(file);
        }
      }
      this.readFile(file).subscribe((base64File: string) => {
        this.onFileChange.emit({ file: file, base64File: base64File });
      });
    }
  }

  handleValidationError(file) {
    this.control.setValue(null);
    this.onFileValidationError.emit(file);
  }

  readFile(fileToRead: File): Observable<any> {
    const base64Observable = new ReplaySubject<any>();

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);

    return base64Observable;
  }


}
