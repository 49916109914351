import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amdocsHighlightText' })
export class AmdocsHighlightTextPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param text initial text
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(text: string, searchText: string): string {
    if (searchText !== '') {
      const re = new RegExp(`(${searchText})`, 'gi');
      return text.replace(re, `<strong>$1</strong>`);
    }
    return text
  }
}
