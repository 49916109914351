<div class="learning-headers d-flex justify-content-between align-items-center">
  <h2 class="title label-h1 m-0" *ngIf="!searchText || searchText.length < 3">{{ title }}</h2>
  <h2 class="title label-h1 m-0" *ngIf="searchText && searchText.length >= 3">
    {{ "planLibrary.topBanner.search" | amdocsTranslate }}: {{ searchText }}
  </h2>

  <div class="headers d-flex justify-content-between"
    *ngIf="(!searchText || searchText.length < 3) && isLoading ? true : (sortedTemplates && sortedTemplates.length)">
    <span class="d-flex column-header created-by">
      <lib-amdocs-dropdown [elementId]="'sortByPlanUser'" [newDesign]="true" [items]="sortList" [control]="sortFC"
        [label]="'templateLearning.createdBy' | amdocsTranslate"></lib-amdocs-dropdown>
    </span>

    <span class="column-header">{{
      "templateLearning.lastEdited" | amdocsTranslate
      }}</span>
    <span class="column-header">{{
      "templateLearning.status" | amdocsTranslate
      }}</span>
  </div>
</div>

<div class="templates-wrapper" *ngIf="!isLoading">
  <div class="card mt-2 mb-3" *ngFor="let template of sortedTemplates">
    <app-learning-template [template]="template" (refreshTemplates)="isRefreshTemplates($event)"
      (removeTemplate)="removeTemplateHandler($event)"></app-learning-template>
  </div>

  <ng-container *ngIf="(!searchText || searchText.length < 3) && !sortedTemplates?.length && !isLoading && errorTypes[errorType] === errorTypes.plansEmpty">
    <div class="card mt-2 mb-3">
      <div class="card-body d-flex justify-content-center">
        <span class="learning-plans-placeholder">{{
          "templateLearning.plansEmpty" | amdocsTranslate
          }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="searchText && searchText.length >= 3 && !sortedTemplates?.length && !isLoading && errorTypes[errorType] === errorTypes.emptyResults">
    <div class="alternative-actions-wrapper d-flex align-items-center text-center justify-content-center">
      <div class="empty-search-wrapper">
        <img lib-amdocs-image-version class="pointer" src="/assets/icons/items-search/no_results.svg" />
        <div class="empty-search">{{'library.emptyResults' | amdocsTranslate}}</div>
      </div>
    </div>
  </ng-container>
</div>