import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LANGUAGE } from '../amdocs-models';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmdocsTranslateService {

  private textTranslation: any;

  constructor(private httpClient: HttpClient) {

  }

  public loadTranslate(lang: LANGUAGE = null): Observable<boolean> {
    const fileName = lang === LANGUAGE.hebrew ? 'he.json' : 'en.json';
    return this.httpClient.get(`/assets/translate/${fileName}`).pipe(
      mergeMap((json) => {
        this.setTranslation(json);
        return of(true);
      })
    );
  }

  /**
   * Set resource object
   * @param obj - translation object
   */
  setTranslation(obj: any): void {
    this.textTranslation = obj;
  }

  /**
   * Get resource value
   * @param key - translation key
   * @param placeholdersReplace - translation placeholder
   * @returns string
   */
  getText(key: string, placeholdersReplace: any = []): string {
    if (!key) {
      return '';
    }

    let value = key.split('.')
      .reduce((previous, current) => previous[current], this.textTranslation) || '';

    if (value.length > 0) {
      if (placeholdersReplace && Array.isArray(placeholdersReplace) && placeholdersReplace.length > 0) {
        value = this.replacePlaceholders(value, placeholdersReplace);
      }
      return value;
    }
    return key;
  }

  /**
   * @param text - replacement text
   * @param placeholders - replacement placeholder
   * @returns string
   */
  private replacePlaceholders(text: string, placeholders: any[]): string {
    if (!text) {
      return '';
    }

    let counter = 0;

    placeholders.forEach((placeholder) => {
      if (placeholder) {
        placeholder = placeholder.toString();
        text = text.replace(`{${counter}}`, placeholder);
      }
      counter++;
    });
    return text;
  }

  mergeDeep(target: any, source: any): any {
    if (typeof (target) === 'object' && typeof (source) === 'object') {
      for (const key in source) {
        if (typeof (source[key]) === 'object') {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return target;
  }
}



