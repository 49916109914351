<div class="desktop-popup-wrapper">
  <div class="header-wrapper">
    <ng-content select="[popupHeader]"></ng-content>
  </div>
  <div class="content-wrapper">
    <ng-content select="[popupContent]"></ng-content>
  </div>
  <div class="buttons-wrapper">
    <ng-content select="[popupFooter]"></ng-content>
  </div>
</div>
