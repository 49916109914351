import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  CreateNewItemWizard,
  LearningItem,
  InsertLearningItemInput,
  UpsertLearningItemInput,
  LearningPlanType,
  ProductPractice,
} from '../../../models/learningPlan'
import { UtilsService } from '../../../core/utils.service'
import { CONSTANTS } from '../../../constants'
import { finalize } from 'rxjs/operators'
import { ApiService } from '../../../core/api.service'
import { LibraryItemsSelectionsService } from '../../services/libraryItemsSelections.service';
import { AmdocsTranslateService, IFormControlErrors } from 'projects/amdocs-core-package/src/public-api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductPracticePopupComponent } from '../product-practice-popup/product-practice-popup.component';
import { CreateLearningPlanService } from '../../../feature/create-learning-plan/create-learning-plan.service';

@Component({
  selector: 'app-learning-plan-item-form',
  templateUrl: './learning-plan-item-form.component.html',
  styleUrls: ['./learning-plan-item-form.component.scss']
})
export class LearningPlanItemFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: LearningItem
  @Input() selectableItems = true;

  @Output() closeAction: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() addToPlanAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() createItemAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() updateItemAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() itemsVisibility: EventEmitter<any> = new EventEmitter<any>()
  @Output() checkCallback: EventEmitter<any> = new EventEmitter()

  public CreateNewItemWizard = CreateNewItemWizard
  public LearningPlanType = LearningPlanType
  public currentStep = CreateNewItemWizard.WebsiteLink

  public editMode = false

  public urlControl: UntypedFormControl
  public existingItem: LearningItem
  public existingItems: LearningItem[]

  public titleControl: UntypedFormControl
  public learningTypeControl: UntypedFormControl
  public domainControl: UntypedFormControl
  public descriptionControl: UntypedFormControl
  public durationControl: UntypedFormControl
  public durationTypeControl: UntypedFormControl
  public technologyControl: UntypedFormControl
  public roleControl: UntypedFormControl
  public productControl: UntypedFormControl
  public accountControl: UntypedFormControl
  public bsModalRef?: BsModalRef;

  public canCreate: false;

  public existingItemsPageSize = 5;
  public existingItemsPageNumber = 1;

  public learningTypes = [];
  public domains = [];
  public durationTypes = [
    {
      key: CONSTANTS.DURATION_TYPE.MIN, value: 'min'
    },
    {
      key: CONSTANTS.DURATION_TYPE.HOURS, value: 'hours'
    }
  ];
  public learningTypesForItemWithoutLink = [
    { code: 'e1e50a49-619b-4d13-a3f9-af5968948c88', name: 'Assessment' },
    { code: 'bbcc8c8b-71f5-4a3a-a14c-fc436a60123f', name: 'Lab\\Exercise\\Practice Environment' },
    { code: '1803a9b9-7db7-4def-a42c-60c967e42836', name: 'Live Session' },
    { code: 'b8cc459f-b903-40cf-a9e1-0582e91ddf71', name: 'Meeting' },
    { code: 'c5fd3f87-5627-4e0a-a5e7-0b9e00948484', name: 'Work Task' }
  ]
  public roles = [];
  public products = [];
  public accounts = [];
  // public locations = [];
  public showLoader = false;
  public learningTypeError = '';
  public domainError = '';

  // Product/practice
  public managedProducts: ProductPractice[]
  public customProducts: ProductPractice[]
  public selectedManagedProducts: string[] = []
  public selectedCustomProducts: string[] = []

  public urlErrors: IFormControlErrors = {
    pattern: 'The URL you entered is not allowed'
  };

  private urlBackup: string
  public learningTypesWithLink: any[];

  constructor(
    private createPlanService: CreateLearningPlanService,
    private utilsService: UtilsService,
    private apiService: ApiService,
    private libraryItemsSelectionsService: LibraryItemsSelectionsService,
    private modalService: BsModalService,
    private translateService: AmdocsTranslateService,
  ) {
  }

  public existingItemsOnPageChange(page) {
    this.existingItemsPageNumber = page;
  }

  public paginatedExistingItems(items) {
    const pos = this.existingItemsPageNumber * this.existingItemsPageSize - this.existingItemsPageSize
    return items.slice(pos, pos + this.existingItemsPageSize)
  }

  ngOnDestroy(): void {
    if (!this.editMode && this.itemsVisibility.observers.length) {
      this.itemsVisibility.emit(false);
    }
  }

  ngOnInit(): void {
    this.roles = this.utilsService.dropdownData.roles;
    this.accounts = this.utilsService.dropdownData.accounts;
    this.domains = this.utilsService.dropdownData.learningDomains;
    this.learningTypesWithLink = this.utilsService.dropdownData.learningTypes;

    this.init();

    this.productControl.valueChanges.subscribe((productIDs) => {
      if(!productIDs.length) {
        this.selectedManagedProducts = []
        this.selectedCustomProducts = []
      }
    })

    if(this.item) {
      this.initEditMode()
    } else {
      this.createPlanService.getProducts().subscribe((res) => {
        this.managedProducts = res.products.managed
        this.customProducts = res.products.custom

        this.products = [...this.managedProducts, ...this.customProducts]
      });
    }

    // MHL3052 may apeal in future
    // this.durationTypeControl.valueChanges.pipe(distinctUntilChanged()).subscribe(type => {
    //   switch(type) {
    //     case CONSTANTS.DURATION_TYPE.MIN:
    //       this.durationControl.setValue(Math.round(this.durationControl.value * 60))
    //     break;
    //     case CONSTANTS.DURATION_TYPE.HOURS:
    //       this.durationControl.setValue(Math.round(this.durationControl.value / 60 * 1000) / 1000)
    //     break;
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  get utils(): UtilsService {
    return this.utilsService;
  }

  init(): void {
    this.currentStep = CreateNewItemWizard.WebsiteLink;
    this.titleControl = new UntypedFormControl(null, Validators.required);
    this.learningTypeControl = new UntypedFormControl(null, Validators.required);
    this.domainControl = new UntypedFormControl(null, Validators.required);
    this.descriptionControl = new UntypedFormControl(null, Validators.required);
    this.durationTypeControl = new UntypedFormControl(CONSTANTS.DURATION_TYPE.MIN);
    this.durationControl = new UntypedFormControl(null, [Validators.required, this.greaterThanZeroValidator]);
    this.technologyControl = new UntypedFormControl();
    this.roleControl = new UntypedFormControl();
    this.productControl = new UntypedFormControl();
    this.accountControl = new UntypedFormControl();
    this.existingItem = null;

    this.urlControl = new UntypedFormControl(null);
    this.urlControl.setValidators([Validators.pattern('(ftp|http|https):\/\/[^ "]+')])
  }

  private greaterThanZeroValidator(control: UntypedFormControl) {
    const value = control.value;
    if (value?.length && value <= 0) {
      return { greaterThanZero: true };
    }
    return null;
  }

  close(): void {
    if (this.closeAction.observers.length) {
      this.closeAction.emit();
    }
  }

  initEditMode(): void {
    this.editMode = true

    this.createPlanService.getProducts().subscribe((res) => {
      this.managedProducts = res.products.managed
      this.customProducts = res.products.custom

      this.products = [...this.managedProducts, ...this.customProducts]

      setTimeout(() => {
        this.productControl.setValue([...this.item.productIDs || [], ...this.item.customProductIDs || []]);
      }, 0)
    });

    this.urlControl.disable();

    if(this.item.duration > 60 || this.item.duration === 60) {
      if(! (this.item.duration % 3)) {
        this.durationTypeControl.setValue(CONSTANTS.DURATION_TYPE.HOURS);
        this.durationControl.setValue(this.item.duration / 60 || null);
      }
    } else {
      this.durationTypeControl.setValue(CONSTANTS.DURATION_TYPE.MIN);
      this.durationControl.setValue(this.item.duration || null);
    }

    this.titleControl.setValue(this.item.title || null);
    this.learningTypeControl.setValue(this.item.typeID || null);
    this.domainControl.setValue(this.item.domainID || null);
    this.descriptionControl.setValue(this.item.description || null);
    this.urlControl.setValue(this.item.resourceURL || null);
    this.roleControl.setValue(this.item.role || null);

    this.accountControl.setValue(this.item.accountIDs ? this.item.accountIDs[0] : null);
    this.technologyControl.setValue(this.item.technology || null);

    this.selectedManagedProducts = this.item.productIDs || []
    this.selectedCustomProducts = this.item.customProductIDs || []

    this.currentStep = CreateNewItemWizard.ItemDetailsSkip;
    this.learningTypes = this.getLearningTypes();
  }

  private getLearningTypes() {
    return this.urlControl.value ? [...this.learningTypesWithLink] : [...this.learningTypesForItemWithoutLink]
  }

  skip(): void {
    this.urlControl.setValue(null);
    this.urlControl.disable();
    this.currentStep = CreateNewItemWizard.ItemDetailsSkip;
    this.learningTypes = this.getLearningTypes();
  }

  skipForWhitelist(): void {
    this.urlControl.disable();
    this.currentStep = CreateNewItemWizard.ItemDetailsSkip;
    this.learningTypes = this.getLearningTypes();
  }

  next(): void {
    this.urlControl.markAsDirty();
    if (this.urlControl.invalid) {
      return;
    }

    const resourceURL = {
      resourceURL: this.urlControl.value
    };
    this.showLoader = true;
    this.urlControl.disable();
    this.apiService.examineURLForNewItem(resourceURL).pipe(finalize(() => {
        this.showLoader = false;
      })
    ).subscribe((res: any) => {
      const learningItems = res.examineURLForNewItem.learningItems
      this.canCreate = res.examineURLForNewItem.canCreate

      if(!this.canCreate) {
        this.urlControl.enable();
        this.urlControl.setErrors({'pattern': true});
      }

      if (learningItems?.length) {
        this.existingItems = learningItems;
        this.currentStep = CreateNewItemWizard.ItemDetailsUrlExists;
        this.learningTypes = this.getLearningTypes();
        if (!this.editMode && this.itemsVisibility.observers.length) {
          this.itemsVisibility.emit(true);
        }
      } else if (this.canCreate) {
        this.currentStep = CreateNewItemWizard.ItemDetailsSkip;
        this.learningTypes = this.getLearningTypes();
        if (!this.editMode && this.itemsVisibility.observers.length) {
          this.itemsVisibility.emit(false);
        }
      }
    });
  }

  addToPlan(): void {
    this.existingItem.selected = false;
    if (this.addToPlanAction.observers.length) {
      this.addToPlanAction.emit(this.existingItem);
    }
  }

  backToEdit(): void {
    this.urlControl.setValue(this.urlBackup)
    this.urlControl.disable();
    this.currentStep = CreateNewItemWizard.ItemDetailsSkip;
    this.learningTypes = this.getLearningTypes();
  }

  addUrl(): void {
    this.urlBackup = this.urlControl.value
    this.urlControl.enable();
    this.currentStep = CreateNewItemWizard.WebsiteLink;
    this.learningTypes = this.getLearningTypes();
  }

  editExistingUrl(): void {
    this.urlControl.enable();
    this.currentStep = CreateNewItemWizard.WebsiteLink;
    this.learningTypes = this.getLearningTypes();
    if (!this.editMode && this.itemsVisibility.observers.length) {
      this.itemsVisibility.emit(false);
    }
  }

  validFields(): boolean {
    this.titleControl.markAsDirty();
    this.learningTypeControl.markAsDirty();
    this.learningTypeError = this.learningTypeControl.invalid ? 'Required field' : '';
    this.domainControl.markAsDirty();
    this.domainError = this.domainControl.invalid ? 'Required field' : '';
    this.descriptionControl.markAsDirty();
    this.durationControl.markAsDirty();

    // ToDo: Check form instead each field individually
    if (
      this.titleControl.invalid
      ||
      this.learningTypeControl.invalid
      ||
      this.domainControl.invalid
      ||
      this.descriptionControl.invalid
      ||
      this.durationControl.invalid
    ) return false

    return true
  }

  createItem(): void {
    const params = this.fillRequestParams()

    if (params && this.createItemAction.observers.length) {
      this.createItemAction.emit(params);
    }
  }

  updateItem(): void {
    const params = this.fillRequestParams()

    if (params && this.updateItemAction.observers.length) {
      this.updateItemAction.emit(params);
    }
  }

  fillRequestParams(): { input: UpsertLearningItemInput | InsertLearningItemInput } {
    if (!this.validFields()) {
      return;
    }
    return {
      input: {
        ...{code: this.item?.code},
        ...{activated: this.item?.activated},
        title: this.titleControl.value || '',
        typeID: this.learningTypeControl.value || '',
        domainID: this.domainControl.value || '',
        description: this.descriptionControl.value || '',
        resourceURL: this.urlControl.value || '',
        duration: this.durationTypeControl.value === CONSTANTS.DURATION_TYPE.HOURS ? Math.round(this.durationControl.value * 60) : parseInt(this.durationControl.value),
        // location: this.locationControl.value || '',
        roleIDs: this.roleControl.value || [],
        productIDs: this.selectedManagedProducts || [],
        customProductIDs: this.selectedCustomProducts || [],
        accountIDs: this.accountControl.value || [],
        technology: this.technologyControl.value || '',
        expectedTime: 10,
        creationDate: '2022-03-09' // ToDo: Must be implemented in API then removed here
      }
    };
  }

  onChangeDropDown($event, control): void {
    this.learningTypeError = '';
    this.domainError = '';
    control.setValue($event?.code || null);
  }


  onChangeDropDownMultiple($event, control): void {
    const value = $event.length ? $event : [];
    control.setValue(value);
  }

  checkItem($event): void {
    if (!this.editMode && this.checkCallback.observers.length) {
      this.checkCallback.emit($event);
    }
  }

  isItemChecked(itemCode: LearningItem['code']): boolean {
    if (this.editMode) {
      return
    }
    return this.libraryItemsSelectionsService.checkSelection(itemCode)
  }

  updateCustomProducts = (newList: ProductPractice[]): void => {
    this.customProducts = newList
  }

  updateProductsSelection = (selectedManaged: string[], selectedCustom: string[]): void => {
    this.selectedManagedProducts = selectedManaged
    this.selectedCustomProducts = selectedCustom

    this.productControl.setValue([...this.selectedManagedProducts, ...this.selectedCustomProducts])
  }


  openModalProductPractice() {
    this.bsModalRef = this.modalService.show(ProductPracticePopupComponent, {
      initialState: {
        managed: this.managedProducts,
        custom: this.customProducts,
        selectedManaged: this.selectedManagedProducts,
        selectedCustom: this.selectedCustomProducts,
        updateCustomProducts: this.updateCustomProducts,
        updateProductsSelection: this.updateProductsSelection,
      },
      class: 'popup-960',
    })
    this.bsModalRef.content.title = this.translateService.getText('productPracticePopup.title')
    this.bsModalRef.content.closeBtnName = this.translateService.getText('productPracticePopup.closeBtnName')
    this.bsModalRef.content.cancelBtnText = this.translateService.getText('productPracticePopup.cancelBtnText')
  }
}
