<div class="amdocs-form-radio {{customClass}}">

  <ng-container *ngFor="let item of options">
    <label class="container">
      <span [ngClass]="{'text-small-bold': control.value === item.id, 'text-small-normal': control.value !== item.id, 'disabled': isDisabled()}">{{item.desc}}</span>
      <input class="hidden-input" type="radio"  [attr.disabled]="isDisabled() ? isDisabled() : null" [value]="item.id" [formControl]="control" (change)="onChange()">
      <span class="custom-control-indicator" [ngClass]="{'selected': control.value === item.id}"></span>
    </label>
  </ng-container>

</div>
