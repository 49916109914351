import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

/**
 * Amdocs Event Bus service
 * manage custom events
 * Each route change, unsubscribe all events listeners unless the listener was defined as global
 */
@Injectable({
    providedIn: 'root'
})
export class AmdocsEventBusService {

    public events: any[] = [];
    public globalEvents: any[] = [];
    public subscriptions: any[] = [];
    public globalSubscriptions: any[] = [];

    constructor(protected router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.clearEvents();
            }
        });
    }

    /**
     * emit an event with optional params
     * @param eventName
     * @param params
     * @param byPrefix
     */
    emit(eventName: string, params?: any, byPrefix = false): void {
        if (!byPrefix) {
            if (this.events[eventName]) {
                this.events[eventName].emit(params);
            }
            if (this.globalEvents[eventName]) {
                this.globalEvents[eventName].emit(params);
            }
        } else {
            Object.keys(this.events).forEach((fullEventName) => {
                if (fullEventName.indexOf(eventName) === 0) {
                    this.events[fullEventName].emit(params);
                }
            });
            Object.keys(this.globalEvents).forEach((fullGlobalEventName) => {
                if (fullGlobalEventName.indexOf(eventName) === 0) {
                    this.globalEvents[fullGlobalEventName].emit(params);
                }
            });
        }
    }

    /**
     * start listen to an event, if global set to true, the event listener will no unsubscribe on route change
     * @param eventName
     * @param callback
     * @param  isGlobal
     * @returns any
     */
    on(eventName, callback, isGlobal = false) {
        if (!isGlobal) {
            if (!this.events[eventName]) {
                this.events[eventName] = new EventEmitter();
            }
            if (!this.subscriptions[eventName]) {
                this.subscriptions[eventName] = [];
            }
            const globalSub = this.events[eventName].subscribe(callback);
            this.subscriptions[eventName].push(globalSub);
            return globalSub;
        } else {
            if (!this.globalEvents[eventName]) {
                this.globalEvents[eventName] = new EventEmitter();
            }
            if (!this.globalSubscriptions[eventName]) {
                this.globalSubscriptions[eventName] = [];
            }
            const sub = this.globalEvents[eventName].subscribe(callback);
            this.globalSubscriptions[eventName].push(sub);
            return sub;
        }
    }

    /**
     * unsubscribe all subscriber from a specific event
     * @param eventName
     * @param  isGlobal
     */
    off(eventName, isGlobal = false): void {
        if (!isGlobal) {
            if (this.events[eventName]) {
                delete this.events[eventName];
            }
            if (this.subscriptions[eventName]) {
                this.subscriptions[eventName].forEach((tmpSub: Subscription) => {
                    tmpSub.unsubscribe();
                });
            }
        } else {
            if (this.globalEvents[eventName]) {
                delete this.globalEvents[eventName];
            }
            if (this.globalSubscriptions[eventName]) {
                this.globalSubscriptions[eventName].forEach((tmpSub: Subscription) => {
                    tmpSub.unsubscribe();
                });
            }
        }
    }

    /**
     * clear all events and subscriptions
     */
    clearEvents(): void {
        this.subscriptions.forEach((eventSubs) => {
            eventSubs.forEach((eventSub) => {
                eventSub.unsubscribe();
            });
        });
        this.subscriptions = [];
        this.events = [];
    }
}
