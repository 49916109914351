import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';


/**
 * @description
 * Amdocs click outside directive - emit a callback function once the user click outside the element
 *
 * @example
 * <div lib-amdocs-click-outside (clickOutside)="myFunc()">Some content</div>
 *
 */
@Directive({
  selector: '[lib-amdocs-click-outside]'
})
export class AmdocsClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {
  }
  @Output() clickOutside = new EventEmitter();

  @HostListener('document:touchend', ['$event.target'])
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement, ) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
