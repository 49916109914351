import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';


@Injectable()
export class PreviewPlanService {

  constructor(private apiService: ApiService) {
  }

  getLearningTemplate(code: string, editView = false): Observable<any> {
    return this.apiService.getLearningTemplate(code, editView);
  }

  publishLearningTemplate(code: string): Observable<any> {
    return this.apiService.publishLearningTemplate(code);
  }

  deleteLearningTemplate(code: string): Observable<any> {
    return this.apiService.deleteLearningTemplate(code);
  }

  moveTemplateToDraft(code: string): Observable<any> {
    return this.apiService.moveTemplateToDraft(code);
  }
}


