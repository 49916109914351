import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-amdocs-paginator',
  templateUrl: './amdocs-paginator.component.html',
  styleUrls: ['./amdocs-paginator.component.scss']
})
export class AmdocsPaginatorComponent implements OnInit {

  @Input() pageSize: number = 10;
  @Input() pageNumber: number = 1;
  @Input() totalRows: number = 0;

  @Output() onPageChange: EventEmitter<any> = new EventEmitter();

  private origValue: number;
  constructor() {
  }

  ngOnInit() {
    this.origValue = this.pageNumber;
  }

  get allowNext(): boolean {
    return this.pageNumber < this.lastPage;
  }

  get allowPrev(): boolean {
    return this.pageNumber > 1;
  }

  get lastPage(): number {
    return Math.ceil(this.totalRows / this.pageSize);
  }

  next() {
    if (this.allowNext) {
      this.pageNumber++;
      this.pageChange();
    }
  }

  prev() {
    if (this.allowPrev) {
      this.pageNumber--;
      this.pageChange();
    }
  }

  valueChange($event) {
    this.pageNumber = $event.target.value ? parseInt($event.target.value, 10) : null;
    setTimeout(() => {
      if (this.pageNumber) {
        if (this.pageNumber > this.lastPage) {
          this.pageNumber = this.lastPage;
        } else if (this.pageNumber < 1) {
          this.pageNumber = 1;
        }
      }
    }, 0);
  }

  onBlur() {
    if (!this.pageNumber) {
      this.pageNumber = this.origValue;
    }
    if (this.origValue !== this.pageNumber) {
      this.pageChange();
    }
  }

  onFocus() {
    this.origValue = this.pageNumber;
  }

  pageChange() {
    this.onPageChange.emit(this.pageNumber);
  }
}

