<div class="amdocs-form-checkbox {{customClass}}">
  <label class="custom-control custom-checkbox" [attr.for]="elementId">
    <input (change)="onChange()"
           type="checkbox"
           class="custom-control-input" [formControl]="control"
           [id]="elementId"
           [attr.disabled]="isDisabled() ? isDisabled() : null"
    >
    <span class="custom-control-indicator d-flex align-items-center justify-content-center"></span>
    <span class="custom-control-description d-flex align-items-center justify-content-center" [ngClass]="{'text-small-bold': control.value, 'text-small-normal': !control.value, 'disabled': isDisabled()}">
      <ng-content></ng-content>
    </span>
  </label>
</div>
