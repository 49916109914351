<div class="modal fade"
     bsModal #modal="bs-modal"
     [config]="config"
     (onHidden)="onHide()"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content {{customClass}}">
      <div *ngIf="showCloseButton" class="close-popup-button" role="button" (click)="close()" >
        <img src="/assets/icons/popup-close.svg" />
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
