import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ILoginCredentials, IRedirectResponse } from "../amdocs-models";
import { AmdocsGatewayClientService } from "../amdocs-gateway-client/amdocs-gateway-client.service";
import { mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AmdocsApiService {
  constructor(private gatewayAPI: AmdocsGatewayClientService) {}

  public getLoginUrl(): Observable<IRedirectResponse> {
    return this.gatewayAPI
      .getLoginApiGatewayClient()
      .call("/oidc/login", "get")
      .pipe(
        mergeMap((response: IRedirectResponse) => {
          return of(response);
        })
      );
  }

  public refreshToken(): Observable<ILoginCredentials> {
    return this.gatewayAPI
      .getLoginApiGatewayClient()
      .call("/oidc/refresh", "get")
      .pipe(
        mergeMap((response: ILoginCredentials) => {
          return of(response);
        })
      );
  }

  public getLogoutUrl(): Observable<IRedirectResponse> {
    return this.gatewayAPI
      .getLoginApiGatewayClient()
      .call("/oidc/logout", "post")
      .pipe(
        mergeMap((response: IRedirectResponse) => {
          return of(response);
        })
      );
  }

  public login(code: string): Observable<ILoginCredentials> {
    const params = {
      code: code,
    };
    return this.gatewayAPI
      .getLoginApiGatewayClient()
      .call("/oidc/token", "get", params)
      .pipe(
        mergeMap((response) => {
          return of(response);
        })
      );
  }
}
