<div class="d-flex align-items-center">
  <lib-amdocs-checkbox *ngIf="!isModalSelectedParts"  [control]="checkboxCtrl"
  ></lib-amdocs-checkbox>

  <div class="learning-plan-part-wrapper">
    <div class="top-part-wrapper" [ngClass]="{'is-checked': part.isChecked && !isModalSelectedParts}">
      <div class="sub-title-wrapper">
        <div class="sub-title">
          {{ part.name }}
        </div>
        <span class="plan-name">{{planTitle}}</span>
      </div>

      <div class="right-side">
        <div
          class="total-part-duration"
          *ngIf="!totalDuration"
        >
          <span>0 hr</span>
        </div>
        <div
          class="total-part-duration"
          *ngIf="totalDuration"
        >
          <span>{{
            totalDuration | amdocsMinutesToHours : true
          }}</span>
        </div>
        <button
          class="collapse-part-button"
          (click)="toggleCollapse()"
          [attr.aria-expanded]="!isCollapsed"
        >
          <img
            *ngIf="!isCollapsed"
            lib-amdocs-image-version
            src="/assets/icons/collapsable-up.svg"
          />
          <img
            *ngIf="isCollapsed"
            lib-amdocs-image-version
            src="/assets/icons/collapsable-down.svg"
          />
        </button>
      </div>
    </div>

    <div
      [collapse]="isCollapsed"
      [isAnimated]="true"
    >
      <div class="items-wrapper">
        <div>
          <ng-container *ngIf="!part?.items?.length">
            <div class="no-items">
              {{ "learningPlan.emptyPart" | amdocsTranslate }}
            </div>
          </ng-container>
          <ng-container *ngIf="part?.items?.length">
            <div *ngFor="let item of part?.items; let i = index; trackBy: itemTracking">
              <app-learning-plan-item
                [isCopyPartOfPlan]="true"
                [item]="item"
                [learningPlanType]="LearningPlanType.Create"
                [learningTypesList]="learningTypesList"
              ></app-learning-plan-item>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
