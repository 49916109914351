import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-amdocs-landscape-view',
  templateUrl: './amdocs-landscape-view.component.html',
  styleUrls: ['./amdocs-landscape-view.component.scss']
})
export class AmdocsLandscapeViewComponent {

  @Input() title = 'Please rotate your Phone';
  @Input() description = 'We support portrait mode only';

  constructor() {
  }

}
