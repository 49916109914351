import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'lib-amdocs-accordion',
  templateUrl: './amdocs-accordion.component.html',
  styleUrls: ['./amdocs-accordion.component.scss']
})
export class AmdocsAccordionComponent implements OnInit, AfterViewInit {

  @Input() isAnimated = true;
  @Input() openPanelNumber: number;
  @Input() openOnlyOne = true;
  @Input() withCloseOpenIcon = true;
  @ContentChildren('accordionBody') accordionTemplates: QueryList<TemplateRef<ElementRef>>;
  @ContentChildren('accordionHeader') accordionTemplatesHeader: QueryList<TemplateRef<ElementRef>>;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  findHeaderTemplate(index: number): any {
    return this.accordionTemplatesHeader.find((_, i) => i === index);
  }

}
