import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../core/user.service';

import { UtilsService } from '../../../core/utils.service';
import { CONSTANTS } from '../../../constants';
import { finalize } from 'rxjs/operators';
import { IMenuItem } from '../../../models/menu';

import { Router } from '@angular/router';
import {
  AmdocsAuthService,
  AmdocsEventBusService,
  AmdocsPopoverComponent,
  AmdocsTranslateService,
  AmdocsUserService
} from 'projects/amdocs-core-package/src/public-api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CopyExistingPlanComponent } from '../copy-existing-plan-popup/copy-existing-plan-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private user: UserService, private amdocsUserService: AmdocsUserService,
    private eventBus: AmdocsEventBusService, private amdocsAuthService: AmdocsAuthService,
    private utilsService: UtilsService, private translateService: AmdocsTranslateService,
    private router: Router,
    private modalService: BsModalService
  ) {
  }

  public showItemsLibrary = false
  public showOpenPlanPopup: boolean;
  public showCopyExistingModal: boolean = false;
  public bsModalRef?: BsModalRef;
  public menuItems: IMenuItem[] = [
    {
      id: this.translateService.getText('header.menuItems.home'),
      name: this.translateService.getText('header.menuItems.home'),
      roles: [],
      action: () => {
        this.router.navigate([`/${CONSTANTS.ROUTES.HOME}`]);
      },
    },
    {
      id: this.translateService.getText('header.menuItems.create'),
      name: this.translateService.getText('header.menuItems.create'),
      roles: []
    },
    {
      id: this.translateService.getText('header.menuItems.planLibrary'),
      name: this.translateService.getText('header.menuItems.planLibrary'),
      roles: [],
      action: () => {
        this.router.navigate([`/${CONSTANTS.ROUTES.LIBRARY}`]);
      },
    },
    // {
    //   id: this.translateService.getText('header.menuItems.open'),
    //   name: this.translateService.getText('header.menuItems.open'),
    //   roles: [],
    //   action: () => {
    //     this.showOpenPlanPopup = true;
    //   }
    // },
    {
      id: this.translateService.getText('header.menuItems.itemLibrary'),
      name: this.translateService.getText('header.menuItems.itemLibrary'),
      roles: [],
      action: () => {
        this.onShowItemsLibrary()
      }
    },
    {
      id: this.translateService.getText('header.menuItems.tutorial'),
      name: this.translateService.getText('header.menuItems.tutorial'),
      roles: [],
      action: () => {
        this.onClickTutorial()
      }
    },
  ];

  @ViewChild('popOver') popOver: AmdocsPopoverComponent;

  onShowItemsLibrary(): void {
    if (this.utilsService.viewLocalItemsLibrary) {
      this.utilsService.showItemsLibrary()
      return
    }

    this.showItemsLibrary = true
  }

  onClickTutorial() {
    const url = 'https://mislmscontent.corp.amdocs.com/content/LandingPages/tutorial/';
    window.open(url, '_blank').focus();
  }

  onActionClick(menuItem: IMenuItem): void {
    if (menuItem.action) {
      menuItem.action();
    }
  }

  isMobileSize(): boolean {
    return this.utilsService.isMobileSize();
  }

  getUserShortName(name): string {
    return this.amdocsUserService.getUserShortName(name);
  }

  ngOnInit(): void {
  }

  onLogout(): void {
    sessionStorage.clear();
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.amdocsAuthService.logout()
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      ).subscribe();
  }

  showCopyModal(): void {
    this.bsModalRef = this.modalService.show(CopyExistingPlanComponent);
  }

  get name(): string {
    return this.user.getName();
  }
  get empId(): string {
    return this.user.getEmpId();
  }

  hidePopOver() {
    this.popOver.hide()
  }

  goHome(): void {
    this.router.navigate(['']);
  }
}
