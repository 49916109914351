import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AmdocsControlAbstract } from "../amdocs-control-abstract";

@Component({
  selector: "lib-amdocs-input-field",
  templateUrl: "./amdocs-input-field.component.html",
  styleUrls: ["./amdocs-input-field.component.scss"],
})
export class AmdocsInputFieldComponent
  extends AmdocsControlAbstract
  implements OnInit
{
  @Input() timeInput: boolean;
  @Input() preventPaste: boolean;
  @Input() inputType: string = "text";
  @Input() suffixValue = null;
  @Input() tooltipContent: string;
  @Input() hasTooltip: boolean;
  @Input() hideLabel: boolean = false;
  @Input() showMaxLength: boolean = false;
  @Input() isRequired: boolean = false;

  @ViewChild("inputRef") inputRef: ElementRef;

  ngOnInit(): void {
    super.ngOnInit();
  }

  setFocus(): void {
    this.inputRef.nativeElement.focus();
  }

  get value(): any {
    if (!this.timeInput) {
      return this.control.value;
    }
    if (!this.control.value) {
      return "";
    }
    if (this.control.value.toString().length >= 3) {
      return (
        this.control.value.toString().slice(0, 2) +
        ":" +
        this.control.value.toString().slice(2)
      );
    }
    return this.control.value;
  }

  set value(val) {
    if (this.timeInput) {
      this.control.setValue(val.toString().replace(":", ""));
      return;
    }
    this.control.setValue(val);
  }

  public handlePaste(event): void {
    if (this.preventPaste) {
      event.preventDefault();
    }
  }
}
