import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AmdocsCookieService {

  constructor(private cookieService: CookieService) {

  }

  public get(key: string): string {
    return this.cookieService.get(key);
  }

  public check(key: string): boolean {
    return this.cookieService.check(key);
  }

  public set(name: string, value: string, options?: {
    expires?: number | Date;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: 'Lax' | 'None' | 'Strict';
  }): void {
    return this.cookieService.set(name, value, options);
  }

  public delete(name: string, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'None' | 'Strict'): void {
    return this.cookieService.delete(name, path, domain, secure, sameSite);
  }

}
