import { Component, Input, OnInit } from '@angular/core';
import { AmdocsDeviceService } from '../../../amdocs-utils/amdocs-device/amdocs-device.service';
import { AmdocsPwaService } from '../../../amdocs-utils/amdocs-pwa/amdocs-pwa.service';

@Component({
  selector: 'lib-amdocs-pwa-ios-notification',
  templateUrl: './amdocs-pwa-ios-notification.component.html',
  styleUrls: ['./amdocs-pwa-ios-notification.component.scss']
})
export class AmdocsPwaIosNotificationComponent implements OnInit {

  @Input() text1 = 'Install this webapp on your iPhone:';
  @Input() text2 = 'and then add to home screen';
  @Input() pwaLocalStorageMaxDisplay = 20;
  @Input() disableOnClose = true;
  public showProgressiveWebAppNotification: boolean;
  private pwaLocalStorageCounterKey = 'pwa-ios-notification';

  constructor(private pwaService: AmdocsPwaService, private deviceService: AmdocsDeviceService) { }

  ngOnInit(): void {
    this.showProgressiveWebAppNotification = this.displayProgressiveWebAppNotification();
  }

  displayProgressiveWebAppNotification(): boolean {
    if (this.pwaService.isPwa()) {
      return false;
    }
    if ((this.deviceService.isIos() && this.deviceService.isSafari()) && !this.deviceService.isChromeIOS()) {
      const counter = parseInt(localStorage.getItem(this.pwaLocalStorageCounterKey), 10) || 0;
      if (counter < this.pwaLocalStorageMaxDisplay) {
        localStorage.setItem(this.pwaLocalStorageCounterKey, (counter + 1).toString());
        return true;
      }
    }
    return false;
  }

  closeProgressiveWebAppNotification(): void {
    this.showProgressiveWebAppNotification = false;
  }

  closeButtonClicked(): void {
    if (this.disableOnClose) {
      localStorage.setItem(this.pwaLocalStorageCounterKey, (this.pwaLocalStorageMaxDisplay + 1).toString());
    }
    this.closeProgressiveWebAppNotification();
  }

}
