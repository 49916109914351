import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amdocsSearch' })
export class AmdocsSearchPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string, targetField?: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      let target = it
      if (targetField) {
        target = it[targetField]
      }
      return target.toLocaleLowerCase().includes(searchText);
    });
  }
}