import {
  Component, EventEmitter,
  Input,
  OnInit, Output
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AmdocsFunctionsService } from '../../../amdocs-utils/amdocs-functions/amdocs-functions.service';
import { AmdocsPwaService } from '../../../amdocs-utils/amdocs-pwa/amdocs-pwa.service';

@Component({
  selector: 'lib-amdocs-top-header',
  templateUrl: './amdocs-top-header.component.html',
  styleUrls: ['./amdocs-top-header.component.scss']
})
export class AmdocsTopHeaderComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() showBackBtn = true;
  @Input() showHomeButton = false;
  @Input() headerActions: IHeaderAction[] = [];
  @Input() urlRedirectHome = '';
  @Output() onBackButtonClickCb: EventEmitter<any> = new EventEmitter();

  constructor(private location: Location,
    private router: Router,
    private functionsService: AmdocsFunctionsService,
    private pwaService: AmdocsPwaService) { }

  ngOnInit(): void {
  }

  handleBackButtonClicked(): void {
    if (this.onBackButtonClickCb.observers.length) {
      this.onBackButtonClickCb.emit();
    } else {
      this.location.back();
    }
  }

  backHome(): void {
    if (!!this.urlRedirectHome) {
      if (this.pwaService.isPwa()) {
        window.close();
      } else {
        this.functionsService.navigateExternalUrl(this.urlRedirectHome, '_self');
      }
    } else {
      this.router.navigate(['']);
    }
  }

  onActionClick(headerAction: IHeaderAction): void {
    if (headerAction.action) {
      headerAction.action();
    }
  }

}

export interface IHeaderAction {
  iconPath: string;
  innerHtml?: string;
  action: () => void;
  width?: number;
  height?: number;
}

